import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './ChecklistScreen.module.css';

// Add a ChecklistIcon component for consistency with ListBuildingScreen
const ChecklistIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M9 11l3 3L22 4"></path>
    <path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"></path>
  </svg>
);

const DetailViewIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M14 2H6a2 2 0 00-2 2v16a2 2 0 002 2h12a2 2 0 002-2V8z"></path>
    <polyline points="14 2 14 8 20 8"></polyline>
    <line x1="16" y1="13" x2="8" y2="13"></line>
    <line x1="16" y1="17" x2="8" y2="17"></line>
    <polyline points="10 9 9 9 8 9"></polyline>
  </svg>
);

const PlusIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <line x1="5" y1="12" x2="19" y2="12"></line>
  </svg>
);

const TrialIcon = () => (
  <svg className={styles.trialIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
  </svg>
);

const GiftIcon = () => (
  <svg className={styles.perkIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polyline points="20 12 20 22 4 22 4 12"></polyline>
    <rect x="2" y="7" width="20" height="5"></rect>
    <line x1="12" y1="22" x2="12" y2="7"></line>
    <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
    <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
  </svg>
);

const ChecklistScreen = () => {
  const { listId } = useParams();
  const history = useHistory();
  
  // Sample data for demonstration
  const sampleList = {
    id: "list-2",
    title: "Hospital Bag Checklist",
    type: "hospital",
    colorTheme: "blue",
    items: [
      {
        id: "item-1",
        name: "Extra-long phone charger",
        priority: "must-have",
        isAcquired: true,
        trialAvailable: false
      },
      {
        id: "item-2",
        name: "Water bottle w/ straw",
        brand: "Ello Glass Tumbler",
        category: "Essentials",
        priority: "must-have",
        isAcquired: true,
        trialAvailable: true,
        trialDetails: "14-day risk-free trial through BabyGear rental service."
      },
      {
        id: "item-3",
        name: "Shower shoes",
        brand: "Birkenstock Arizona Eva Sandals",
        category: "Clothing",
        priority: "must-have",
        isAcquired: true,
        trialAvailable: false
      },
      {
        id: "item-4",
        name: "Grippy socks",
        brand: "Sticky Grippers",
        category: "Clothing", 
        priority: "nice-to-have",
        isAcquired: true,
        trialAvailable: false
      },
      {
        id: "item-5",
        name: "Electrolytes",
        brand: "Coconut Water Powder",
        category: "Essentials",
        priority: "must-have", 
        isAcquired: true,
        trialAvailable: false
      },
      {
        id: "item-6",
        name: "Eye mask",
        brand: "Manta Sleep Mask",
        category: "Comfort",
        priority: "nice-to-have",
        isAcquired: false,
        trialAvailable: true,
        trialDetails: "30-day money-back guarantee"
      },
      {
        id: "item-7",
        name: "Ear plugs",
        brand: "Mack's Ultra Soft",
        category: "Comfort",
        priority: "nice-to-have",
        isAcquired: false,
        trialAvailable: false
      }
    ]
  };

  // Sample perks for this specific checklist
  const relatedPerks = [
    {
      id: "perk-1",
      title: "Tubby Todd Bath Essentials Mini Set",
      description: "Free samples for sensitive skin products",
      badge: "FREE",
      relatedCategory: "Comfort"
    },
    {
      id: "perk-2",
      title: "Dr. Brown's Anti-Colic Bottle Starter Set",
      description: "60% off your first purchase",
      badge: "60% OFF",
      relatedCategory: "Feeding"
    }
  ];

  // State for list and items
  const [list, setList] = useState(sampleList);
  const [checkedItems, setCheckedItems] = useState({});
  const [progress, setProgress] = useState(0);
  const [showCompleted, setShowCompleted] = useState(true);
  const [showOnlyMustHaves, setShowOnlyMustHaves] = useState(false);
  const [showOnlyTrialAvailable, setShowOnlyTrialAvailable] = useState(false);
  const [showPerks, setShowPerks] = useState(false);

  // Initialize checkedItems from the list data
  useEffect(() => {
    const initialCheckedState = {};
    list.items.forEach(item => {
      initialCheckedState[item.id] = item.isAcquired;
    });
    setCheckedItems(initialCheckedState);
  }, [list]);

  // Calculate progress whenever checkedItems changes
  useEffect(() => {
    const checkedCount = Object.values(checkedItems).filter(Boolean).length;
    const totalCount = list.items.length;
    setProgress(totalCount > 0 ? (checkedCount / totalCount) : 0);
  }, [checkedItems, list.items]);

  // Handle item check/uncheck
  const handleCheckItem = (itemId) => {
    setCheckedItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));

    // In a real app, you would update this in your database
    console.log(`Item ${itemId} checked status changed to: ${!checkedItems[itemId]}`);
  };

  // Filter items based on current filters
  const getFilteredItems = () => {
    return list.items.filter(item => {
      // Skip completed items if showCompleted is false
      if (!showCompleted && checkedItems[item.id]) {
        return false;
      }
      
      // Filter to only must-haves if that filter is active
      if (showOnlyMustHaves && item.priority !== 'must-have') {
        return false;
      }
      
      // Filter to only items with Trial before you buy if that filter is active
      if (showOnlyTrialAvailable && !item.trialAvailable) {
        return false;
      }
      
      return true;
    });
  };

  // Navigate back to list detail
  const handleBack = () => {
    history.push(`/list/${listId}`);
  };

  // Navigate to add item screen
  const handleAddItem = () => {
    history.push(`/list/${listId}/add-item`);
  };

  // Handle claiming a perk
  const handleClaimPerk = (perkId) => {
    console.log(`Claiming perk: ${perkId}`);
    // In a real app, this would call an API to claim the perk
    alert(`Perk claimed! Check your email for more details.`);
  };

  // Toggle filters
  const toggleShowCompleted = () => {
    setShowCompleted(!showCompleted);
  };

  const toggleShowOnlyMustHaves = () => {
    setShowOnlyMustHaves(!showOnlyMustHaves);
  };

  const toggleShowOnlyTrialAvailable = () => {
    setShowOnlyTrialAvailable(!showOnlyTrialAvailable);
  };

  const toggleShowPerks = () => {
    setShowPerks(!showPerks);
  };

  // Navigate to detail view
  const handleDetailView = () => {
    history.push(`/list/${listId}`);
  };

  // Navigate to item detail
  const handleItemClick = (itemId) => {
    history.push(`/list/${listId}/item/${itemId}`);
  };

  // Print checklist
  const handlePrintChecklist = () => {
    window.print();
  };

  // Get color class based on list theme
  const getColorClass = () => {
    switch (list.colorTheme) {
      case 'blue': return styles.blueTheme;
      case 'purple': return styles.purpleTheme;
      case 'pink': return styles.pinkTheme;
      case 'green': return styles.greenTheme;
      default: return styles.defaultTheme;
    }
  };

  // Get priority class
  const getPriorityClass = (priority) => {
    return priority === 'must-have' ? styles.mustHave : styles.niceToHave;
  };

  // Count active filters
  const getActiveFilterCount = () => {
    let count = 0;
    if (!showCompleted) count++;
    if (showOnlyMustHaves) count++;
    if (showOnlyTrialAvailable) count++;
    return count;
  };

  // Clear all filters
  const clearAllFilters = () => {
    setShowCompleted(true);
    setShowOnlyMustHaves(false);
    setShowOnlyTrialAvailable(false);
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <button 
          className={styles.backButton} 
          onClick={handleBack}
          aria-label="Go back"
        >
          &larr;
        </button>
        <h1 className={styles.title}>{list.title}</h1>
        <button 
          className={styles.detailViewButton} 
          onClick={handleDetailView}
          aria-label="Detail view"
        >
          <DetailViewIcon />
        </button>
      </header>

      <div className={styles.progressContainer}>
        <div className={styles.progressText}>
          {Math.round(progress * 100)}% Complete
        </div>
        <div className={styles.progressBarContainer}>
          <div 
            className={`${styles.progressBar} ${getColorClass()}`}
            style={{ width: `${progress * 100}%` }}
          ></div>
        </div>
      </div>

      <div className={styles.filtersContainer}>
        <button 
          className={`${styles.filterButton} ${showOnlyMustHaves ? styles.activeFilter : ''}`}
          onClick={toggleShowOnlyMustHaves}
        >
          Must Haves Only
        </button>
        <button 
          className={`${styles.filterButton} ${!showCompleted ? styles.activeFilter : ''}`}
          onClick={toggleShowCompleted}
        >
          {showCompleted ? 'Hide Completed' : 'Show Completed'}
        </button>
        <button 
          className={`${styles.filterButton} ${showOnlyTrialAvailable ? styles.activeFilter : ''}`}
          onClick={toggleShowOnlyTrialAvailable}
        >
          Trial before you buy
        </button>
        <button 
          className={`${styles.filterButton} ${showPerks ? styles.activeFilter : ''}`}
          onClick={toggleShowPerks}
        >
          <GiftIcon />
          <span>Perks</span>
        </button>
      </div>

      {getActiveFilterCount() > 0 && (
        <div className={styles.activeFiltersBar}>
          <span className={styles.activeFiltersText}>
            {getActiveFilterCount()} filter{getActiveFilterCount() !== 1 ? 's' : ''} active
          </span>
          <button 
            className={styles.clearFiltersButton}
            onClick={clearAllFilters}
          >
            Clear All
          </button>
        </div>
      )}

      {/* Personal Perks Section - NEW */}
      {showPerks && (
        <div className={styles.personalPerks}>
          <h3 className={styles.perksHeader}>Your Personal Perks</h3>
          <p className={styles.perksDescription}>Based on your selections and preferences</p>
          <div className={styles.perksList}>
            {relatedPerks.map(perk => (
              <div key={perk.id} className={styles.perkItem}>
                <div className={styles.perkInfo}>
                  <div className={styles.perkTitle}>
                    <span className={styles.perkBadge}>{perk.badge}</span>
                    <h4>{perk.title}</h4>
                  </div>
                  <p>{perk.description}</p>
                </div>
                <button 
                  className={styles.claimButton}
                  onClick={() => handleClaimPerk(perk.id)}
                >
                  Claim
                </button>
              </div>
            ))}
          </div>
        </div>
      )}

      <div className={styles.checklistContainer}>
        {getFilteredItems().length === 0 ? (
          <div className={styles.emptyState}>
            <p>No items match your current filters.</p>
          </div>
        ) : (
          getFilteredItems().map(item => (
            <div 
              key={item.id} 
              className={`${styles.checklistItem} ${checkedItems[item.id] ? styles.checkedItem : ''}`}
              onClick={() => handleItemClick(item.id)}
            >
              <label 
                className={styles.checkboxContainer}
                onClick={(e) => e.stopPropagation()}
              >
                <input
                  type="checkbox"
                  checked={checkedItems[item.id] || false}
                  onChange={() => handleCheckItem(item.id)}
                  className={styles.checkbox}
                />
                <span className={styles.checkmark}></span>
              </label>
              <div className={styles.itemDetails}>
                <div className={styles.itemName}>{item.name}</div>
                <div className={styles.itemBadges}>
                  <div className={`${styles.priorityBadge} ${getPriorityClass(item.priority)}`}>
                    {item.priority === 'must-have' ? 'Must Have' : 'Nice to Have'}
                  </div>
                  {item.trialAvailable && (
                    <div className={styles.trialBadge}>
                      <TrialIcon />
                      <span>Trial before you buy</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </div>

      <button 
        className={styles.addButton}
        onClick={handleAddItem}
      >
        <PlusIcon />
        <span className={styles.buttonText}>Add Missing Item</span>
      </button>

      <div className={styles.actionsContainer}>
        <button 
          className={styles.printButton}
          onClick={handlePrintChecklist}
        >
          Print Checklist
        </button>
      </div>
    </div>
  );
};

export default ChecklistScreen;