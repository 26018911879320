import initialState from './initialState';
import * as types from './actionTypes';

const categoriesReducer = (state = initialState, action) => {
  if (typeof action !== 'undefined') {
    switch (action.type) {
      case types.ADD_CATEGORY_ERROR:
        return {
          ...state,
          addCategoryError: action.addCategoryError
        }
      case types.ADD_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          addCategoryInProgress: action.addCategoryInProgress
        }
      case types.UPDATE_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          updateCategoryInProgress: action.updateCategoryInProgress
        }
      case types.UPDATE_CATEGORY_SUCCESS:
        return {
          ...state,
          updateCategorySuccess: action.updateCategorySuccess
        }
      case types.UPDATE_CATEGORY_ERROR:
        return {
          ...state,
          updateCategoryError: action.updateCategoryError
        }
      case types.UPDATE_CATEGORY_DATA:
        return {
          ...state,
          userData: action.userData
        }
      case types.READ_ALL_CATEGORIES_IN_PROGRESS:
        return {
          ...state,
          readAllCategoriesInProgress: action.readAllCategoriesInProgress
        }
      case types.READ_ALL_CATEGORIES_SUCCESS:
        return {
          ...state,
          readAllCategoriesSuccess: action.readAllCategoriesSuccess,
          allCategories: action.allCategories
        }
      case types.READ_ALL_CATEGORIES_ERROR:
        return {
          ...state,
          readAllCategoriesError: action.readAllCategoriesError
        }
      case types.READ_USER_CATEGORIES_IN_PROGRESS:
        return {
          ...state,
          readUserCategoriesInProgress: action.readUserCategoriesInProgress
        }
      case types.READ_USER_CATEGORIES_SUCCESS:
        console.log('READ_USER_CATEGORIES_SUCCESS - Previous state:', {
          userSelectedCategories: state.userSelectedCategories
        });
        console.log('READ_USER_CATEGORIES_SUCCESS - Action payload:', action.userSelectedCategories);
        
        if (JSON.stringify(state.userSelectedCategories) !== JSON.stringify(action.userSelectedCategories)) {
          const updatedState = {
            ...state,
            readUserCategoriesSuccess: action.readUserCategoriesSuccess,
            userSelectedCategories: action.userSelectedCategories
          };
          
          console.log('READ_USER_CATEGORIES_SUCCESS - New state:', {
            userSelectedCategories: updatedState.userSelectedCategories
          });
          
          return updatedState;
        }
        
        console.log('READ_USER_CATEGORIES_SUCCESS - No state change needed');
        return state;
  
      case types.READ_USER_CATEGORIES_ERROR:
        return {
          ...state,
          readUserCategoriesError: action.readUserCategoriesError
        }
      case types.SELECT_USER_CATEGORY_ERROR:
        return {
          ...state,
          updateUserSelectedCategoriesError: action.updateUserSelectedCategoriesError
        }
      case types.SELECT_USER_CATEGORY_SUCCESS:
        return {
          ...state,
          updateUserSelectedCategoriesSuccess: action.updateUserSelectedCategoriesSuccess
        }
      case types.SELECT_USER_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          updateUserSelectedCategoriesInProgress: action.updateUserSelectedCategoriesInProgress
        }
      case types.CREATE_USER_LINK_CATEGORY_ERROR:
        return {
          ...state,
          createUserLinkCategoryError: action.createUserLinkCategoryError
        }
      case types.CREATE_USER_LINK_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          createUserLinkCategoryInProgress: action.createUserLinkCategoryInProgress
        }
      case types.CREATE_USER_LINK_CATEGORY_SUCCESS:
        return {
          ...state,
          createUserLinkCategorySuccess: action.createUserLinkCategorySuccess
        }
      case types.UPDATE_USER_LINK_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          updateUserLinkCategoryInProgress: action.updateUserLinkCategoryInProgress
        }
      case types.UPDATE_USER_LINK_CATEGORY_SUCCESS:
        return {
          ...state,
          updateUserLinkCategorySuccess: action.updateUserLinkCategorySuccess
        }
      case types.UPDATE_USER_LINK_CATEGORY_ERROR:
        return {
          ...state,
          updateUserLinkCategoryError: action.updateUserLinkCategoryError
        }
      case types.DELETE_USER_LINK_CATEGORY_IN_PROGRESS:
        return {
          ...state,
          deleteUserLinkCategoryInProgress: action.deleteUserLinkCategoryInProgress
        }
      case types.DELETE_USER_LINK_CATEGORY_SUCCESS:
        return {
          ...state,
          deleteUserLinkCategorySuccess: action.deleteUserLinkCategorySuccess
        }
      case types.DELETE_USER_LINK_CATEGORY_ERROR:
        return {
          ...state,
          deleteUserLinkCategoryError: action.deleteUserLinkCategoryError
        }
      case types.SAVE_CATEGORY_STATE: {
        console.log('SAVE_CATEGORY_STATE - Previous state:', {
          checkedCategories: state.checkedCategories,
          userSelectedCategories: state.userSelectedCategories
        });
        console.log('SAVE_CATEGORY_STATE - Action payload:', action.payload);
      
        // Only update if the incoming update is newer than existing state
        const existingTimestamp = state.lastUpdateTimestamp?.[action.payload.categoryId] || 0;
        if (action.payload.timestamp < existingTimestamp) {
          console.log('Skipping outdated update');
          return state;
        }
      
        const newState = {
          ...state,
          checkedCategories: {
            ...state.checkedCategories,
            [action.payload.categoryId]: action.payload.isChecked
          },
          userSelectedCategories: action.payload.isFavorited
            ? [...new Set([...state.userSelectedCategories, action.payload.categoryId])]
            : state.userSelectedCategories.filter(id => id !== action.payload.categoryId),
          lastUpdateTimestamp: {
            ...state.lastUpdateTimestamp,
            [action.payload.categoryId]: action.payload.timestamp
          }
        };
      
        console.log('SAVE_CATEGORY_STATE - New state:', {
          checkedCategories: newState.checkedCategories,
          userSelectedCategories: newState.userSelectedCategories
        });
      
        return newState;
      }        
        
      case types.SAVE_PRODUCT_STATE:
        return {
          ...state,
          selectedProducts: {
            ...state.selectedProducts,
            [action.payload.categoryId]: action.payload.productId 
          }
        }
      
        case types.LOAD_SAVED_CATEGORY_STATES: {
          console.log('LOAD_SAVED_CATEGORY_STATES - Previous state:', {
            checkedCategories: state.checkedCategories,
            userSelectedCategories: state.userSelectedCategories,
            selectedProducts: state.selectedProducts
          });
          console.log('LOAD_SAVED_CATEGORY_STATES - Action payload:', action.payload);
        
          // Merge states, preferring newer timestamps
          const mergedCheckedCategories = {};
          const timestamps = { ...state.lastUpdateTimestamp };
          
          Object.entries(action.payload.checkedCategories || {}).forEach(([categoryId, isChecked]) => {
            const payloadTimestamp = action.payload.timestamps?.[categoryId] || Date.now();
            const existingTimestamp = state.lastUpdateTimestamp?.[categoryId] || 0;
            
            if (payloadTimestamp >= existingTimestamp) {
              mergedCheckedCategories[categoryId] = isChecked;
              timestamps[categoryId] = payloadTimestamp;
            } else {
              mergedCheckedCategories[categoryId] = state.checkedCategories[categoryId];
            }
          });
        
          const newState = {
            ...state,
            checkedCategories: {
              ...state.checkedCategories,
              ...mergedCheckedCategories
            },
            userSelectedCategories: [
              ...(action.payload.userSelectedCategories || [])
            ],
            selectedProducts: {
              ...state.selectedProducts,
              ...(action.payload.selectedProducts || {})
            },
            lastUpdateTimestamp: timestamps
          };
        
          console.log('LOAD_SAVED_CATEGORY_STATES - New state:', {
            checkedCategories: newState.checkedCategories,
            userSelectedCategories: newState.userSelectedCategories,
            selectedProducts: newState.selectedProducts
          });
        
                // Merge incoming states with current state, preferring incoming states
          return {
            ...state,
            checkedCategories: {
              ...state.checkedCategories,
              ...(action.payload.checkedCategories || {})
            },
            userSelectedCategories: [
              ...(action.payload.userSelectedCategories || [])
            ],
            selectedProducts: {
              ...state.selectedProducts,
              ...(action.payload.selectedProducts || {})
            }
          };
        }
        
          
      case types.LOAD_SAVED_PRODUCT_STATES:
        return {
          ...state,
          userSelectedProducts: action.payload
        }
      case types.ADD_CATEGORY_TO_USER_LIST_SUCCESS:
        return {
          ...state,
          userSelectedCategories: [...state.userSelectedCategories, action.payload]
        }
      case types.REMOVE_CATEGORY_FROM_USER_LIST_SUCCESS:
        return {
          ...state,
          userSelectedCategories: state.userSelectedCategories.filter(id => id !== action.payload)
        }
      case types.SAVE_REMOVED_CATEGORIES:
        return {
          ...state,
          removedCategories: action.payload
        };
      case types.LOAD_REMOVED_CATEGORIES:
        return {
          ...state,
          removedCategories: action.payload
        };
      case types.SET_EXPANDED_CATEGORIES:
        return {
          ...state,
          expandedCategories: action.payload,
        };
      case types.SET_SHOW_SEARCH:
        return {
          ...state,
          showSearch: action.payload,
        };
      case types.SET_SHOW_ADDED_CATEGORIES:
        return {
          ...state,
          showAddedCategories: action.payload,
        };
      case types.SET_SEARCH_LOADING:
        return {
          ...state,
          searchLoading: action.payload,
        };
      case types.SEARCH_CATEGORIES_SUCCESS:
        return {
          ...state,
          searchResults: action.payload,
        };
      case types.SEARCH_CATEGORIES_FAILURE:
        return {
          ...state,
          searchError: action.payload,
        };
      case types.CATEGORY_LOADING:
        return {
          ...state,
          categoryLoading: action.payload
        };
      case types.CATEGORY_ERROR:
        return {
          ...state,
          categoryError: action.payload
        };
      case types.CATEGORY_SUCCESS:
        return {
          ...state,
          categorySuccess: action.payload
        };
      case types.TOGGLE_CATEGORY_CHECK:
        return {
          ...state,
          checkedCategories: {
            ...state.checkedCategories,
            [action.payload.categoryId]: action.payload.isChecked
          }
        };
      case types.READ_ALL_CATEGORIES_IN_ALL_TOPICS_SUCCESS:
        return {
          ...state,
          checkedCategories: {
            ...state.checkedCategories,
            ...(action.allCategoriesInTopics || []).reduce((acc, category) => ({
              ...acc,
              [category.uuid]: state.checkedCategories[category.uuid] || false
            }), {})
          }
        };
      default:
        return state;
    }
  } else {
    console.log('Reducer called with undefined action');
    return state
  }
}

export default categoriesReducer;
