import React from 'react';
import { Card, Form, Button, Input, Divider } from "antd";
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../redux/authentication/actions';

const LoginModal = ({ 
  open, 
  onClose, 
  toggleRegistrationModalVisibility,
  toggleRequestPasswordResetModalVisibility 
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const loginInProcess = useSelector(state => state.auth.loginInProcess);

  const handleSubmit = (values) => {
    document.activeElement?.blur();

    console.log('Form submitted with:', values); 
    console.log('Dispatching login action');
    dispatch(login(values.email, values.password));
  };

  if (!open) return null;

  return (
    <div 
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000
      }}
      onClick={onClose}
    >
      <Card 
        className="loginCard"
        style={{ maxWidth: 400, width: '100%', margin: '20px' }}
        onClick={e => e.stopPropagation()}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
        >
          <p style={{fontSize: 24, marginTop: 10, marginBottom: 10, textAlign: 'center' }}>Sign in</p>
          <Form.Item
            name="email"
            hasFeedback
            rules={[
              {
                required: true,
                pattern: new RegExp("^.+@[^.].*.[a-z]{2,}$"),
                message: "Please enter a valid email address.",
              },
            ]}
          >
            <Input className="loginForm" placeholder="Email" />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[
              { required: true, message: "Please enter your password" },
            ]}
            hasFeedback
          >
            <Input.Password className="loginForm" placeholder="Password" />
          </Form.Item>
          <Form.Item style={{ height: "30px", textAlign: "center" }}>
            <Button
              type="secondary"
              className="forgotPasswordButton"
              onClick={toggleRequestPasswordResetModalVisibility}
            >
              Forgot password?
            </Button>
          </Form.Item>
          <Form.Item style={{ marginLeft: "none", marginBottom: "none", textAlign: "center" }}>
            <Button
              className="signInButtonBody"
              type="primary"
              htmlType="submit"
              disabled={loginInProcess}
            >
              {loginInProcess ? 'Signing in...' : 'Sign in'}
            </Button>
          </Form.Item>
          <Form.Item style={{width: "100%", textAlign: "center"}}>
            <Divider style={{width: "100%"}}>or</Divider>
            <Button
              onClick={toggleRegistrationModalVisibility}
              className="standardButtonLogIn"
            >
              Sign up
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default LoginModal;