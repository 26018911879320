import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './FilterScreen.module.css';

const FilterScreen = ({ initialFilters, onApplyFilters }) => {
  const { listId } = useParams();
  const history = useHistory();
  
  // Filter options
  const filterOptions = {
    priorities: [
      { id: "must-have", label: "Must Have" },
      { id: "nice-to-have", label: "Nice to Have" }
    ],
    ageRanges: [
      { id: "n-plus", label: "N+" },
      { id: "0-3m", label: "0-3m" },
      { id: "3-6m", label: "3-6m" },
      { id: "6-12m", label: "6-12m" },
      { id: "12m-plus", label: "12m+" }
    ],
    priceRanges: [
      { id: "low", label: "$" },
      { id: "medium", label: "$$" },
      { id: "high", label: "$$$" }
    ],
    categories: [
      "Essentials", "Comfort", "Tech", "Clothing", "Feeding"
    ],
    status: [
      { id: "acquired", label: "Acquired" },
      { id: "not-acquired", label: "Not Acquired" }
    ],
    trialOptions: [
      { id: "trial-available", label: "Trial before you buy" },
      { id: "no-trial", label: "No Trial before you buy" }
    ]
  };

  // State for active filters
  const [activeFilters, setActiveFilters] = useState({
    priorities: [],
    ageRanges: [],
    priceRanges: [],
    categories: [],
    status: [],
    trialOptions: []
  });

  // Track total number of active filters
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  // Initialize with any existing filters
  useEffect(() => {
    if (initialFilters) {
      setActiveFilters(initialFilters);
    }
  }, [initialFilters]);

  // Update active filter count whenever activeFilters changes
  useEffect(() => {
    const count = Object.values(activeFilters).reduce(
      (total, filterGroup) => total + filterGroup.length, 
      0
    );
    setActiveFilterCount(count);
  }, [activeFilters]);

  // Handle toggling single-select filters (like priority)
  const handleSingleSelectFilter = (filterType, value) => {
    setActiveFilters(prev => {
      const isActive = prev[filterType].includes(value);
      
      return {
        ...prev,
        [filterType]: isActive ? [] : [value]
      };
    });
  };

  // Handle toggling multi-select filters (like categories)
  const handleMultiSelectFilter = (filterType, value) => {
    setActiveFilters(prev => {
      const isActive = prev[filterType].includes(value);
      
      return {
        ...prev,
        [filterType]: isActive
          ? prev[filterType].filter(item => item !== value)
          : [...prev[filterType], value]
      };
    });
  };

  // Check if a filter is active
  const isFilterActive = (filterType, value) => {
    return activeFilters[filterType].includes(value);
  };

  // Clear all filters
  const clearAllFilters = () => {
    setActiveFilters({
      priorities: [],
      ageRanges: [],
      priceRanges: [],
      categories: [],
      status: [],
      trialOptions: []
    });
  };

  // Apply filters and navigate back
  const handleApplyFilters = () => {
    if (onApplyFilters) {
      onApplyFilters(activeFilters);
    }
    
    // In a real app, you might want to pass the filters as URL parameters
    // For now, we'll just navigate back to the list detail
    history.push(`/list/${listId}`);
  };

  // Handle cancel
  const handleCancel = () => {
    history.push(`/list/${listId}`);
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <button 
          className={styles.backButton} 
          onClick={handleCancel}
          aria-label="Go back"
        >
          &larr;
        </button>
        <h1 className={styles.title}>Filter Items</h1>
        {activeFilterCount > 0 && (
          <span className={styles.filterCount}>{activeFilterCount}</span>
        )}
      </header>

      <div className={styles.filterPanel}>
        {/* Priority Filter Section */}
        <section className={styles.filterSection}>
          <h2 className={styles.sectionTitle}>Priority</h2>
          <div className={styles.toggleButtons}>
            {filterOptions.priorities.map(priority => (
              <button
                key={priority.id}
                className={`${styles.toggleButton} ${
                  isFilterActive('priorities', priority.id) ? styles.active : ''
                }`}
                onClick={() => handleSingleSelectFilter('priorities', priority.id)}
              >
                {priority.label}
              </button>
            ))}
          </div>
        </section>

        {/* Age Range Filter Section */}
        <section className={styles.filterSection}>
          <h2 className={styles.sectionTitle}>Age Range</h2>
          <div className={styles.chipContainer}>
            {filterOptions.ageRanges.map(ageRange => (
              <button
                key={ageRange.id}
                className={`${styles.chipButton} ${
                  isFilterActive('ageRanges', ageRange.id) ? styles.active : ''
                }`}
                onClick={() => handleMultiSelectFilter('ageRanges', ageRange.id)}
              >
                {ageRange.label}
              </button>
            ))}
          </div>
        </section>

        {/* Price Range Filter Section */}
        <section className={styles.filterSection}>
          <h2 className={styles.sectionTitle}>Price Range</h2>
          <div className={styles.chipContainer}>
            {filterOptions.priceRanges.map(priceRange => (
              <button
                key={priceRange.id}
                className={`${styles.chipButton} ${
                  isFilterActive('priceRanges', priceRange.id) ? styles.active : ''
                }`}
                onClick={() => handleMultiSelectFilter('priceRanges', priceRange.id)}
              >
                {priceRange.label}
              </button>
            ))}
          </div>
        </section>

        {/* Category Filter Section */}
        <section className={styles.filterSection}>
          <h2 className={styles.sectionTitle}>Category</h2>
          <div className={styles.checkboxContainer}>
            {filterOptions.categories.map(category => (
              <label key={category} className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  checked={isFilterActive('categories', category)}
                  onChange={() => handleMultiSelectFilter('categories', category)}
                  className={styles.checkbox}
                />
                <span className={styles.checkboxText}>{category}</span>
              </label>
            ))}
          </div>
        </section>

        {/* Status Filter Section */}
        <section className={styles.filterSection}>
          <h2 className={styles.sectionTitle}>Status</h2>
          <div className={styles.toggleButtons}>
            {filterOptions.status.map(status => (
              <button
                key={status.id}
                className={`${styles.toggleButton} ${
                  isFilterActive('status', status.id) ? styles.active : ''
                }`}
                onClick={() => handleSingleSelectFilter('status', status.id)}
              >
                {status.label}
              </button>
            ))}
          </div>
        </section>
        
        {/* Trial Before Buying Filter Section */}
        <section className={styles.filterSection}>
          <h2 className={styles.sectionTitle}>Trial Before Buying</h2>
          <div className={styles.toggleButtons}>
            {filterOptions.trialOptions.map(option => (
              <button
                key={option.id}
                className={`${styles.toggleButton} ${
                  isFilterActive('trialOptions', option.id) ? styles.active : ''
                }`}
                onClick={() => handleSingleSelectFilter('trialOptions', option.id)}
              >
                {option.label}
              </button>
            ))}
          </div>
          <p className={styles.filterDescription}>
            Filter for products that offer trial programs or money-back guarantees before purchasing.
          </p>
        </section>
      </div>

      <div className={styles.filterActions}>
        {activeFilterCount > 0 && (
          <button 
            className={styles.clearButton}
            onClick={clearAllFilters}
          >
            Clear All
          </button>
        )}
        <button 
          className={styles.applyButton}
          onClick={handleApplyFilters}
        >
          Apply Filters {activeFilterCount > 0 && `(${activeFilterCount})`}
        </button>
      </div>
    </div>
  );
};

export default FilterScreen;