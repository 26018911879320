/**
 * Generates dynamic shortcut suggestions based on conversation context
 * @param {Object} params - Function parameters
 * @param {Array} params.messages - Previous messages in the conversation
 * @param {string} params.currentState - Current conversation state (e.g. INITIAL, USER_TYPE)
 * @param {Object} params.userInfo - Collected user information so far
 * @param {function} params.sendMessage - Function to send message to ChatGPT
 * @returns {Promise<Array>} - Array of shortcut options with text and value properties
 */
const generateDynamicShortcuts = async ({ 
    messages, 
    currentState, 
    userInfo, 
    sendMessage 
  }) => {
    // Get the last bot message - this is what the user is responding to
    const lastBotMessage = messages
      .filter(msg => msg.type === 'bot')
      .pop();
      
    // Get several recent messages for context
    const recentMessages = messages.slice(-6).map(msg => ({
      role: msg.type === 'user' ? 'user' : 'assistant',
      content: msg.content
    }));
  
    // Define options we need based on current state
    const numOptions = getOptionsCountForState(currentState);
    
    // Updated system prompt with more guidance for inclusive, general options
    const systemPrompt = `
      You are generating shortcut button options for a baby preparation app conversation.
      
      The user just received this message from the assistant:
      "${lastBotMessage?.content || "Introduction to baby preparation app"}"
      
      Current conversation state: ${currentState}
      User Info: ${JSON.stringify(userInfo)}
      
      Based on this specific message and the conversation context, generate ${numOptions} APPROPRIATE button options.
      
      Your responses should:
      1. Be general and inclusive, avoiding specific assumptions about the user's situation
      2. Directly respond to the assistant's last message 
      3. Be concise (under 5 words if possible)
      4. Represent different possible user choices
      5. NEVER assume family relationships (like "sister" or "brother")
      6. NEVER assume birth order (like "first baby" unless explicitly mentioned before)
      7. Use generic terms like "expecting" vs "helping someone"
      
      Format your response as JSON: 
      [{"text": "Button text", "value": "corresponding_intent"}, ...]
      
      Valid intent values: learn, start, more, expecting, helping, born, notBorn, firstBaby, notFirstBaby, privacy_question, trust_question, app_purpose, simplify
    `;
    
    try {
      // Ask ChatGPT to predict appropriate button options
      const response = await sendMessage("Generate appropriate button options", {
        systemPrompt,
        temperature: 0.4, // Reduced temperature for more consistent, less creative responses
        previousMessages: recentMessages
      });
  
      // Parse the JSON response
      let options = [];
      try {
        // Find JSON in the response (in case ChatGPT adds explanatory text)
        const jsonMatch = response.match(/\[.*\]/s);
        if (jsonMatch) {
          options = JSON.parse(jsonMatch[0]);
        } else {
          // Try parsing the whole response if no JSON array detected
          options = JSON.parse(response);
        }
      } catch (parseError) {
        console.error('Error parsing shortcuts JSON:', parseError);
        // Fall back to default options
        return getDefaultShortcuts(currentState);
      }
  
      // Validate the response format
      if (!Array.isArray(options) || options.length === 0) {
        return getDefaultShortcuts(currentState);
      }
      
      // Filter out options with prohibited specific terms
      const prohibitedTerms = [
        'sister', 'brother', 'mom', 'dad', 'wife', 'husband', 
        'first', 'second', 'third', // ordinal assumptions 
        'my', 'your' // possessive assumptions
      ];
      
      // Only apply term filtering if these weren't explicitly mentioned in the conversation
      const conversationText = messages.map(msg => msg.content).join(' ').toLowerCase();
      const relevantProhibitedTerms = prohibitedTerms.filter(term => 
        !conversationText.includes(term)
      );
      
      // Filter out overly specific options
      options = options.map(option => {
        const text = option.text || "";
        
        // Check if any prohibited terms are in the text
        const hasProhibitedTerm = relevantProhibitedTerms.some(term => 
          text.toLowerCase().includes(term)
        );
        
        if (hasProhibitedTerm) {
          // Replace with more general version
          if (option.value === "expecting") {
            return { text: "I'm expecting", value: option.value };
          } else if (option.value === "helping") {
            return { text: "Helping someone", value: option.value };
          } else if (option.value === "firstBaby") {
            return { text: "First baby", value: option.value };
          } else if (option.value === "notFirstBaby") {
            return { text: "Have children", value: option.value };
          }
        }
        
        return option;
      });
      
      // Validate intent-text consistency
      const intentTextMap = {
        expecting: ["expect", "pregnant", "having a baby"],
        helping: ["help", "someone", "assisting", "friend"],
        firstBaby: ["first", "new parent", "new to this"],
        notFirstBaby: ["other child", "have children", "not first", "again"]
      };
      
      // Validate intent-text consistency
      options = options.map(option => {
        // Get the expected patterns for this intent
        const expectedPatterns = intentTextMap[option.value];
        
        // Skip validation if no patterns defined for this intent
        if (!expectedPatterns) return option;
        
        // Check if the text aligns with any expected pattern
        const isConsistent = expectedPatterns.some(pattern => 
          (option.text || "").toLowerCase().includes(pattern)
        );
        
        // If not consistent, use a default text for this intent
        if (!isConsistent) {
          const defaultOptions = getDefaultShortcuts(currentState);
          const matchingDefault = defaultOptions.find(def => def.value === option.value);
          if (matchingDefault) {
            return matchingDefault;
          }
        }
        
        return option;
      });
      
      // Use a maximum length for button text
      options = options.map(option => ({
        ...option,
        text: option.text
      }));
      
      // If we end up with empty or invalid options after filtering
      if (!options.length) {
        // Get the defaults as a last resort
        return getDefaultShortcuts(currentState);
      }
      
      return options;
    } catch (error) {
      console.error('Error generating shortcuts:', error);
      return getDefaultShortcuts(currentState);
    }
  };
  
  /**
   * Determines how many shortcut options should be generated for each state
   * @param {string} state - Current conversation state
   * @returns {number} - Number of options to generate
   */
  const getOptionsCountForState = (state) => {
    // First normalize the state to lowercase if it's a string
    const normalizedState = typeof state === 'string' ? state.toLowerCase() : state;
    
    switch (normalizedState) {
      case 'initial':
      case 'value_explanation':
      case 'user_type':
      case 'pregnancy_status':
      case 'first_baby':
        return 2; // These states typically need binary choices
      case 'questions':
        return 3; // More options for question states
      case 'completed':
        return 1; // Just one option at completion
      default:
        return 2; // Default to 2 options
    }
  };
  
  
  /**
   * Provides fallback shortcut options when dynamic generation fails
   * @param {string} state - Current conversation state
   * @returns {Array} - Array of default shortcut options
   */
  const getDefaultShortcuts = (state) => {
    // First normalize the state to lowercase if it's a string
    const normalizedState = typeof state === 'string' ? state.toLowerCase() : state;
    
    switch (normalizedState) {
      case 'initial':
        return [
          { text: "Learn more", value: "learn" },
          { text: "Get started", value: "start" }
        ];
      case 'value_explanation':
        return [
          { text: "Tell me more", value: "more" },
          { text: "Start now", value: "start" }
        ];
      case 'user_type':
        return [
          { text: "I'm expecting", value: "expecting" },
          { text: "Helping someone", value: "helping" }
        ];
      case 'pregnancy_status':
        return [
          { text: "Baby is born", value: "born" },
          { text: "Still expecting", value: "notBorn" }
        ];
      case 'first_baby':
        return [
          { text: "First baby", value: "firstBaby" },
          { text: "Have children", value: "notFirstBaby" }
        ];
      case 'questions':
        return [
          { text: "What does this app do?", value: "app_purpose" },
          { text: "Is my data private?", value: "privacy_question" },
          { text: "How does it work?", value: "more" }
        ];
      case 'completed':
        return [
          { text: "Explore the app", value: "start" }
        ];
      default:
        console.warn(`No default shortcuts for state: ${state}, using generic options`);
        return [
          { text: "Tell me more", value: "more" },
          { text: "Let's get started", value: "start" }
        ];
    }
  };
  
  
  export default generateDynamicShortcuts;