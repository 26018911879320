const state = {
  friends: null,
  requestsReceived: [],  // Initialize as empty array instead of null
  requestsSent: [],      // Initialize as empty array instead of null
  addFriendError: null,
  addFriendSuccess: false,
  addFriendInProgress: false,
  removeFriendError: null,
  removeFriendSuccess: false,
  removeFriendInProgress: false,
  readAllFriendsError: null,
  readReceivedFriendRequestsError: null,
  readSentFriendRequestsError: null,
  inviteFriendError: null,
  inviteFriendSuccess: null,
  rejectEmailInProcess: false,
  rejectEmailError: "",
  selectedFriends: [],
  friendsNotes: {},
  friendsNotesError: null,
  friendLists: [],
  loading: false,
  error: null,
  selectedFriendUuid: null,
};

export default state;