import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './SettingsScreen.module.css';

// Icons (simple SVG implementations)
const ChevronRightIcon = () => (
  <svg className={styles.chevronIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polyline points="9 18 15 12 9 6"></polyline>
  </svg>
);

const BellIcon = () => (
  <svg className={styles.sectionIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
    <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
  </svg>
);

const UserIcon = () => (
  <svg className={styles.sectionIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </svg>
);

const LockIcon = () => (
  <svg className={styles.sectionIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
    <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
  </svg>
);

const BabyIcon = () => (
  <svg className={styles.sectionIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="12" r="10"></circle>
    <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
    <line x1="9" y1="9" x2="9.01" y2="9"></line>
    <line x1="15" y1="9" x2="15.01" y2="9"></line>
  </svg>
);

const HelpIcon = () => (
  <svg className={styles.sectionIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="12" r="10"></circle>
    <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
    <line x1="12" y1="17" x2="12.01" y2="17"></line>
  </svg>
);

const InfoIcon = () => (
  <svg className={styles.sectionIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="12" r="10"></circle>
    <line x1="12" y1="16" x2="12" y2="12"></line>
    <line x1="12" y1="8" x2="12.01" y2="8"></line>
  </svg>
);

const LogoutIcon = () => (
  <svg className={styles.logoutIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
    <polyline points="16 17 21 12 16 7"></polyline>
    <line x1="21" y1="12" x2="9" y2="12"></line>
  </svg>
);

const SettingsScreen = () => {
  const history = useHistory();
  
  // Mock data for user settings
  const [userSettings, setUserSettings] = useState({
    account: {
      name: "Emily Johnson",
      email: "emily@example.com",
      password: "••••••••" // Just for display
    },
    notifications: {
      pushEnabled: true,
      emailDigest: false,
      listReminders: true,
      communityUpdates: false
    },
    privacy: {
      profileVisible: true,
      allowListSharing: true,
      showActivityStatus: false
    },
    babyProfiles: [
      {
        id: "baby-1",
        name: "Olivia",
        birthdate: new Date('2023-12-10').getTime(),
        gender: "female"
      }
    ],
    appInfo: {
      version: "1.0.0",
      buildNumber: "2023.03.15",
      termsUrl: "https://example.com/terms",
      privacyUrl: "https://example.com/privacy",
      helpUrl: "https://example.com/help"
    }
  });

  // Handle toggle changes
  const handleToggle = (section, setting) => {
    setUserSettings(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [setting]: !prev[section][setting]
      }
    }));
    
    console.log(`Toggling ${section}.${setting} to ${!userSettings[section][setting]}`);
  };

  // Handle logout
  const handleLogout = () => {
    showConfirmDialog(
      "Are you sure you want to log out?", 
      () => {
        console.log("Logging out");
        // Would navigate to login screen in a real app
        history.push('/'); // Redirect to home for now
      }
    );
  };

  // Handle navigation to edit screens
  const navigateToEditAccount = () => {
    console.log("Navigate to edit account");
    // In a real app, would navigate to account edit screen
    // history.push('/edit-account');
  };

  const navigateToEditBaby = (babyId) => {
    console.log(`Navigate to edit baby profile: ${babyId}`);
    // In a real app, would navigate to baby profile edit screen
    // history.push(`/edit-baby/${babyId}`);
  };

  const navigateToAddBaby = () => {
    console.log("Navigate to add new baby profile");
    // In a real app, would navigate to add baby profile screen
    // history.push('/add-baby');
  };

  // Open URLs
  const openURL = (url) => {
    window.open(url, '_blank');
  };

  // Confirmation dialog
  const showConfirmDialog = (message, onConfirm) => {
    const confirmed = window.confirm(message);
    if (confirmed) onConfirm();
  };

  // Format date
  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric' 
    });
  };

  // Calculate age in months
  const calculateAge = (birthdate) => {
    const today = new Date();
    const birth = new Date(birthdate);
    
    let months = (today.getFullYear() - birth.getFullYear()) * 12;
    months -= birth.getMonth();
    months += today.getMonth();
    
    if (months === 1) return "1 month old";
    if (months < 24) return `${months} months old`;
    
    const years = Math.floor(months / 12);
    const remainingMonths = months % 12;
    
    if (remainingMonths === 0) return `${years} year${years > 1 ? 's' : ''} old`;
    return `${years} year${years > 1 ? 's' : ''}, ${remainingMonths} month${remainingMonths > 1 ? 's' : ''} old`;
  };

  // Handle back navigation
  const handleBack = () => {
    history.goBack();
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <button 
          className={styles.backButton} 
          onClick={handleBack}
          aria-label="Go back"
        >
          &larr;
        </button>
        <h1 className={styles.title}>Settings</h1>
      </header>

      <div className={styles.settingsContainer}>
        {/* Account Settings */}
        <section className={styles.settingsSection}>
          <div className={styles.sectionHeader}>
            <UserIcon />
            <h2 className={styles.sectionTitle}>Account</h2>
          </div>
          
          <div className={styles.settingItem} onClick={navigateToEditAccount}>
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Name</span>
              <span className={styles.settingValue}>{userSettings.account.name}</span>
            </div>
            <ChevronRightIcon />
          </div>
          
          <div className={styles.settingItem} onClick={navigateToEditAccount}>
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Email</span>
              <span className={styles.settingValue}>{userSettings.account.email}</span>
            </div>
            <ChevronRightIcon />
          </div>
          
          <div className={styles.settingItem} onClick={navigateToEditAccount}>
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Password</span>
              <span className={styles.settingValue}>{userSettings.account.password}</span>
            </div>
            <ChevronRightIcon />
          </div>
        </section>

        {/* Notification Settings */}
        <section className={styles.settingsSection}>
          <div className={styles.sectionHeader}>
            <BellIcon />
            <h2 className={styles.sectionTitle}>Notifications</h2>
          </div>
          
          <div className={styles.settingItem}>
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Push Notifications</span>
              <span className={styles.settingDescription}>Receive alerts on your device</span>
            </div>
            <label className={styles.toggle}>
              <input 
                type="checkbox" 
                checked={userSettings.notifications.pushEnabled} 
                onChange={() => handleToggle('notifications', 'pushEnabled')}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
          
          <div className={styles.settingItem}>
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Email Digest</span>
              <span className={styles.settingDescription}>Weekly summary of activity</span>
            </div>
            <label className={styles.toggle}>
              <input 
                type="checkbox" 
                checked={userSettings.notifications.emailDigest} 
                onChange={() => handleToggle('notifications', 'emailDigest')}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
          
          <div className={styles.settingItem}>
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>List Reminders</span>
              <span className={styles.settingDescription}>Reminders for incomplete lists</span>
            </div>
            <label className={styles.toggle}>
              <input 
                type="checkbox" 
                checked={userSettings.notifications.listReminders} 
                onChange={() => handleToggle('notifications', 'listReminders')}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
          
          <div className={styles.settingItem}>
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Community Updates</span>
              <span className={styles.settingDescription}>New community recommendations</span>
            </div>
            <label className={styles.toggle}>
              <input 
                type="checkbox" 
                checked={userSettings.notifications.communityUpdates} 
                onChange={() => handleToggle('notifications', 'communityUpdates')}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        </section>

        {/* Privacy Settings */}
        <section className={styles.settingsSection}>
          <div className={styles.sectionHeader}>
            <LockIcon />
            <h2 className={styles.sectionTitle}>Privacy</h2>
          </div>
          
          <div className={styles.settingItem}>
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Profile Visibility</span>
              <span className={styles.settingDescription}>Allow others to see your profile</span>
            </div>
            <label className={styles.toggle}>
              <input 
                type="checkbox" 
                checked={userSettings.privacy.profileVisible} 
                onChange={() => handleToggle('privacy', 'profileVisible')}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
          
          <div className={styles.settingItem}>
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>List Sharing</span>
              <span className={styles.settingDescription}>Allow others to share your lists</span>
            </div>
            <label className={styles.toggle}>
              <input 
                type="checkbox" 
                checked={userSettings.privacy.allowListSharing} 
                onChange={() => handleToggle('privacy', 'allowListSharing')}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
          
          <div className={styles.settingItem}>
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Activity Status</span>
              <span className={styles.settingDescription}>Show when you're active</span>
            </div>
            <label className={styles.toggle}>
              <input 
                type="checkbox" 
                checked={userSettings.privacy.showActivityStatus} 
                onChange={() => handleToggle('privacy', 'showActivityStatus')}
              />
              <span className={styles.slider}></span>
            </label>
          </div>
        </section>

        {/* Baby Profiles */}
        <section className={styles.settingsSection}>
          <div className={styles.sectionHeader}>
            <BabyIcon />
            <h2 className={styles.sectionTitle}>Baby Profiles</h2>
          </div>
          
          {userSettings.babyProfiles.map(baby => (
            <div 
              key={baby.id} 
              className={styles.settingItem} 
              onClick={() => navigateToEditBaby(baby.id)}
            >
              <div className={styles.settingInfo}>
                <span className={styles.settingLabel}>{baby.name}</span>
                <span className={styles.settingDescription}>
                  {calculateAge(baby.birthdate)} • {baby.gender === 'female' ? 'Girl' : 'Boy'}
                </span>
              </div>
              <ChevronRightIcon />
            </div>
          ))}
          
          <button 
            className={styles.addButton}
            onClick={navigateToAddBaby}
          >
            Add Another Baby
          </button>
        </section>

        {/* Help & Support */}
        <section className={styles.settingsSection}>
          <div className={styles.sectionHeader}>
            <HelpIcon />
            <h2 className={styles.sectionTitle}>Help & Support</h2>
          </div>
          
          <div 
            className={styles.settingItem} 
            onClick={() => openURL(userSettings.appInfo.helpUrl)}
          >
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Help Center</span>
            </div>
            <ChevronRightIcon />
          </div>
          
          <div 
            className={styles.settingItem}
            onClick={() => openURL('mailto:support@example.com')}
          >
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Contact Support</span>
            </div>
            <ChevronRightIcon />
          </div>
          
          <div 
            className={styles.settingItem}
            onClick={() => openURL('https://example.com/feedback')}
          >
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Give Feedback</span>
            </div>
            <ChevronRightIcon />
          </div>
        </section>

        {/* About */}
        <section className={styles.settingsSection}>
          <div className={styles.sectionHeader}>
            <InfoIcon />
            <h2 className={styles.sectionTitle}>About</h2>
          </div>
          
          <div 
            className={styles.settingItem}
            onClick={() => openURL(userSettings.appInfo.termsUrl)}
          >
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Terms of Service</span>
            </div>
            <ChevronRightIcon />
          </div>
          
          <div 
            className={styles.settingItem}
            onClick={() => openURL(userSettings.appInfo.privacyUrl)}
          >
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Privacy Policy</span>
            </div>
            <ChevronRightIcon />
          </div>
          
          <div className={styles.settingItem}>
            <div className={styles.settingInfo}>
              <span className={styles.settingLabel}>Version</span>
              <span className={styles.settingValue}>
                {userSettings.appInfo.version} ({userSettings.appInfo.buildNumber})
              </span>
            </div>
          </div>
        </section>

        {/* Logout */}
        <section className={styles.settingsSection}>
          <button 
            className={styles.logoutButton}
            onClick={handleLogout}
          >
            <LogoutIcon />
            <span>Log Out</span>
          </button>
        </section>
      </div>
    </div>
  );
};

export default SettingsScreen;