import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './HomeScreen.module.css';

// Trial icon component
const TrialIcon = () => (
  <svg className={styles.trialIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
  </svg>
);

const HomeScreen = () => {
  const history = useHistory();
  
  // Sample lists for demonstration
  const sampleLists = [
    {
      id: "list-1",
      title: "Pregnancy Essentials",
      type: "pregnancy",
      createdAt: new Date('2023-01-15').getTime(),
      itemCount: 15,
      colorTheme: "purple", // #f0e6fa
      trialItemsCount: 3,
      hasTrialItems: true
    },
    {
      id: "list-2",
      title: "Hospital Bag",
      type: "hospital",
      createdAt: new Date('2023-02-20').getTime(),
      itemCount: 12,
      colorTheme: "blue", // #e6f5fa
      trialItemsCount: 4,
      hasTrialItems: true
    },
    {
      id: "list-3",
      title: "Nursery Setup",
      type: "nursery",
      createdAt: new Date('2023-03-05').getTime(),
      itemCount: 8,
      colorTheme: "pink", // #fae6f0
      trialItemsCount: 0,
      hasTrialItems: false
    }
  ];

  // Function to navigate to list detail
  const handleListClick = (listId) => {
    history.push(`/list/${listId}`);
  };

  // Function to navigate to list creation
  const handleCreateList = () => {
    history.push('/create');
  };

  // Function to format the date
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return date.toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric' 
    });
  };

  // Get color class based on list type
  const getColorClass = (colorTheme) => {
    switch (colorTheme) {
      case 'purple': return styles.purpleTheme;
      case 'blue': return styles.blueTheme;
      case 'pink': return styles.pinkTheme;
      case 'green': return styles.greenTheme;
      case 'orange': return styles.orangeTheme;
      default: return styles.defaultTheme;
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.title}>My Lists</h1>
      </header>

      <main className={styles.content}>
        {/* Create New List Button */}
        <button 
          className={styles.createButton} 
          onClick={handleCreateList}
        >
          <span className={styles.plusIcon}>+</span>
          Create New List
        </button>

        {/* Perks Container */}
        <section className={styles.perksContainer}>
          <h2 className={styles.perksTitle}>Your Personalized Perks</h2>
          <p className={styles.perksDescription}>
            Special offers based on your preferences and baby's stage
          </p>
          <div className={styles.perksScroller}>
            {/* Sample perks from your list */}
            <div className={styles.perkCard}>
              <div className={styles.perkBadge}>FREE</div>
              <h3>Honest Company Diaper Sample Box</h3>
              <p>10 diapers + travel wipes</p>
              <button className={styles.claimButton}>Claim Now</button>
            </div>
            <div className={styles.perkCard}>
              <div className={styles.perkBadge}>70% OFF</div>
              <h3>Huggies Special Delivery Supply</h3>
              <p>Premium eco-friendly diapers</p>
              <button className={styles.claimButton}>Get Code</button>
            </div>
            <div className={styles.perkCard}>
              <div className={styles.perkBadge}>FREE</div>
              <h3>Enfamil Formula Sampler Box</h3>
              <p>4 formula types to try</p>
              <button className={styles.claimButton}>Request Box</button>
            </div>
          </div>
        </section>

        {/* Lists Container */}
        <div className={styles.listsContainer}>
          {sampleLists.map((list) => (
            <div 
              key={list.id} 
              className={`${styles.listCard} ${getColorClass(list.colorTheme)}`}
              onClick={() => handleListClick(list.id)}
            >
              <h2 className={styles.listTitle}>{list.title}</h2>
              <div className={styles.listDetails}>
                <span className={styles.listType}>{list.type}</span>
                <span className={styles.listItems}>{list.itemCount} items</span>
              </div>
              {list.hasTrialItems && (
                <div className={styles.trialBadge}>
                  <TrialIcon />
                  <span>{list.trialItemsCount} items with trials</span>
                </div>
              )}
              <div className={styles.listDate}>
                Created {formatDate(list.createdAt)}
              </div>
            </div>
          ))}
        </div>
      </main>

      {/* Bottom Navigation */}
      <nav className={styles.bottomNav}>
        <div className={`${styles.navItem} ${styles.active}`}>
          <svg className={styles.navIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path>
            <polyline points="9 22 9 12 15 12 15 22"></polyline>
          </svg>
          <span>Home</span>
        </div>
        <div 
          className={styles.navItem}
          onClick={() => history.push('/community')}
        >
          <svg className={styles.navIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
            <circle cx="9" cy="7" r="4"></circle>
            <path d="M23 21v-2a4 4 0 00-3-3.87"></path>
            <path d="M16 3.13a4 4 0 010 7.75"></path>
          </svg>
          <span>Community</span>
        </div>
        <div 
          className={styles.navItem}
          onClick={() => history.push('/profile')}
        >
          <svg className={styles.navIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
            <circle cx="12" cy="7" r="4"></circle>
          </svg>
          <span>Profile</span>
        </div>
      </nav>
    </div>
  );
};

export default HomeScreen;