import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './SharedListViewScreen.module.css';

// Star rating component
const StarRating = ({ rating }) => {
  const fullStars = Math.floor(rating);
  const hasHalfStar = rating % 1 >= 0.5;
  const emptyStars = 5 - fullStars - (hasHalfStar ? 1 : 0);
  
  return (
    <div className={styles.starRating}>
      {[...Array(fullStars)].map((_, i) => (
        <span key={`full-${i}`} className={styles.star}>★</span>
      ))}
      {hasHalfStar && <span className={styles.star}>⯐</span>}
      {[...Array(emptyStars)].map((_, i) => (
        <span key={`empty-${i}`} className={styles.star}>☆</span>
      ))}
    </div>
  );
};

// Avatar component
const Avatar = ({ name, initial }) => {
  return (
    <div className={styles.avatar}>
      <span className={styles.initial}>{initial}</span>
    </div>
  );
};

// Trial icon component
const TrialIcon = () => (
  <svg className={styles.trialIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
  </svg>
);

const SharedListViewScreen = () => {
  const { sharedListId } = useParams();
  const history = useHistory();
  const [list, setList] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [thanked, setThanked] = useState(false);
  const [savedItems, setSavedItems] = useState({});
  const [showOnlyTrialItems, setShowOnlyTrialItems] = useState(false);
  
  // Fetch the shared list data
  useEffect(() => {
    // In a real app, you would fetch from an API
    // For now, we'll use mock data
    const sharedList = {
      id: "shared-list-1",
      title: "Sarah's Hospital Bag",
      creator: {
        id: "user-1",
        name: "Sarah Johnson",
        initial: "S"
      },
      dateShared: new Date('2023-03-15').getTime(),
      stats: {
        totalItems: 12,
        mustHaveCount: 8,
        trialItemsCount: 4,
        rating: 3.5
      },
      note: "These are all the things that saved me during my hospital stay. Don't forget the extra long charger - it's a lifesaver! I've marked items that have trial options, which saved me a lot of money.",
      colorTheme: "blue",
      items: [
        {
          id: "item-1",
          name: "Extra-long phone charger",
          brand: "Amazon Basics Cable",
          priority: "must-have",
          emoji: "📱",
          trialAvailable: false
        },
        {
          id: "item-2",
          name: "Water bottle w/ straw",
          brand: "Ello Glass Tumbler",
          priority: "must-have",
          emoji: "🥤",
          trialAvailable: true,
          trialDetails: "14-day risk-free trial through BabyGear rental service."
        },
        {
          id: "item-3",
          name: "Shower shoes",
          brand: "Birkenstock Arizona Eva Sandals",
          priority: "must-have",
          emoji: "👞",
          trialAvailable: false
        },
        {
          id: "item-4",
          name: "White noise machine",
          brand: "Hatch Rest",
          priority: "nice-to-have",
          emoji: "🔊",
          trialAvailable: true,
          trialDetails: "30-day trial through manufacturer website"
        },
        {
          id: "item-5",
          name: "Nursing pillow",
          brand: "Boppy Original",
          priority: "must-have",
          emoji: "🛌",
          trialAvailable: true,
          trialDetails: "Try before you buy through local parent resource center"
        },
        {
          id: "item-6",
          name: "Baby carrier",
          brand: "Ergobaby Embrace",
          priority: "must-have",
          emoji: "👶",
          trialAvailable: true,
          trialDetails: "Rent for 2 weeks through CarrierLibrary.com"
        }
      ]
    };
    
    setList(sharedList);
    
    // Check if user is logged in - in a real app, this would use your auth system
    // For mockup purposes, we'll just set it to false
    setIsLoggedIn(false);
  }, [sharedListId]);

  // Mock functions
  const saveEntireList = () => {
    console.log("Saving entire list");
    if (!isLoggedIn) {
      setShowLoginPrompt(true);
      return;
    }
    
    // In a real app, this would call an API
    alert("List saved to your account!");
  };
  
  const thankCreator = () => {
    console.log("Thanking creator");
    if (!isLoggedIn) {
      setShowLoginPrompt(true);
      return;
    }
    
    setThanked(true);
    // In a real app, this would call an API
  };
  
  const addItemToMyList = (itemId) => {
    console.log("Adding item to my list:", itemId);
    if (!isLoggedIn) {
      setShowLoginPrompt(true);
      return;
    }
    
    setSavedItems(prev => ({
      ...prev,
      [itemId]: !prev[itemId]
    }));
    
    // In a real app, this would call an API
  };
  
  const handlePrintView = () => {
    // In a real app, this would navigate to a print-friendly view
    window.print();
  };
  
  const handleBack = () => {
    history.goBack();
  };
  
  const handleSignupLogin = () => {
    // In a real app, redirect to login page
    setShowLoginPrompt(false);
    alert("This would redirect to login/signup page");
  };
  
  const toggleTrialItemsFilter = () => {
    setShowOnlyTrialItems(!showOnlyTrialItems);
  };
  
  // Get filtered items
  const getFilteredItems = () => {
    if (!list) return [];
    
    if (showOnlyTrialItems) {
      return list.items.filter(item => item.trialAvailable);
    }
    
    return list.items;
  };
  
  // Format date
  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };
  
  // Get color class based on theme
  const getColorClass = (theme) => {
    switch (theme) {
      case 'blue': return styles.blueTheme;
      case 'purple': return styles.purpleTheme;
      case 'pink': return styles.pinkTheme;
      case 'green': return styles.greenTheme;
      default: return styles.defaultTheme;
    }
  };
  
  // Show trial details
  const showTrialDetails = (details) => {
    alert(details);
  };
  
  if (!list) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingText}>Loading shared list...</div>
      </div>
    );
  }
  
  return (
    <div className={styles.container}>
      <header className={`${styles.header} ${getColorClass(list.colorTheme)}`}>
        <button 
          className={styles.backButton} 
          onClick={handleBack}
          aria-label="Go back"
        >
          &larr;
        </button>
        <h1 className={styles.title}>{list.title}</h1>
        <div className={styles.headerActions}>
          <button 
            className={styles.printButton} 
            onClick={handlePrintView}
            aria-label="Print view"
          >
            <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
              <path d="M6 9V2h12v7"></path>
              <path d="M6 18H4a2 2 0 01-2-2v-5a2 2 0 012-2h16a2 2 0 012 2v5a2 2 0 01-2 2h-2"></path>
              <path d="M6 14h12v8H6z"></path>
            </svg>
          </button>
        </div>
      </header>
      
      <div className={styles.creatorContainer}>
        <div className={styles.creatorInfo}>
          <Avatar name={list.creator.name} initial={list.creator.initial} />
          <div className={styles.creatorDetails}>
            <div className={styles.creatorName}>{list.creator.name}</div>
            <div className={styles.sharedDate}>Shared on {formatDate(list.dateShared)}</div>
          </div>
        </div>
        <button 
          className={`${styles.thankButton} ${thanked ? styles.thanked : ''}`} 
          onClick={thankCreator}
          disabled={thanked}
        >
          {thanked ? 'Thanked!' : 'Thank Creator'}
        </button>
      </div>
      
      <div className={styles.listStats}>
        <div className={styles.statItem}>
          <span className={styles.statValue}>{list.stats.totalItems}</span>
          <span className={styles.statLabel}>Items</span>
        </div>
        <div className={styles.statItem}>
          <span className={styles.statValue}>{list.stats.mustHaveCount}</span>
          <span className={styles.statLabel}>Must-Haves</span>
        </div>
        <div className={styles.statItem}>
          <span className={styles.statValue}>{list.stats.trialItemsCount}</span>
          <span className={styles.statLabel}>Trial Items</span>
        </div>
        <div className={styles.statItem}>
          <span className={styles.statValue}>
            <StarRating rating={list.stats.rating} />
          </span>
          <span className={styles.statLabel}>Rating</span>
        </div>
      </div>
      
      {list.note && (
        <div className={styles.noteContainer}>
          <h2 className={styles.noteTitle}>Note from {list.creator.name}</h2>
          <p className={styles.noteText}>{list.note}</p>
        </div>
      )}
      
      <div className={styles.filterContainer}>
        <button 
          className={`${styles.filterButton} ${showOnlyTrialItems ? styles.activeFilter : ''}`}
          onClick={toggleTrialItemsFilter}
        >
          {showOnlyTrialItems ? 'Show All Items' : 'Show Trial Items Only'}
        </button>
      </div>
      
      <div className={styles.itemsContainer}>
        <h2 className={styles.sectionTitle}>Recommended Items</h2>
        
        {getFilteredItems().length === 0 ? (
          <div className={styles.emptyState}>
            <p>No items match your current filter.</p>
            <button 
              className={styles.clearFilterButton}
              onClick={() => setShowOnlyTrialItems(false)}
            >
              Clear Filter
            </button>
          </div>
        ) : (
          getFilteredItems().map(item => (
            <div key={item.id} className={styles.itemCard}>
              <div className={styles.itemContent}>
                <div className={styles.itemEmoji}>{item.emoji}</div>
                <div className={styles.itemDetails}>
                  <h3 className={styles.itemName}>{item.name}</h3>
                  <p className={styles.itemBrand}>{item.brand}</p>
                  <div className={styles.itemBadges}>
                    <div className={styles.priorityBadge} data-priority={item.priority}>
                      {item.priority === 'must-have' ? 'Must Have' : 'Nice to Have'}
                    </div>
                    {item.trialAvailable && (
                      <div 
                        className={styles.trialBadge}
                        onClick={(e) => {
                          e.stopPropagation();
                          showTrialDetails(item.trialDetails);
                        }}
                      >
                        <TrialIcon />
                        <span>Trial before you buy</span>
                      </div>
                    )}
                  </div>
                </div>
                <button 
                  className={`${styles.addItemButton} ${savedItems[item.id] ? styles.itemAdded : ''}`}
                  onClick={() => addItemToMyList(item.id)}
                >
                  {savedItems[item.id] ? 'Added' : 'Add to My List'}
                </button>
              </div>
              
              {item.trialAvailable && (
                <div className={styles.trialDetailsContainer}>
                  <h4 className={styles.trialDetailsTitle}>Trial Information:</h4>
                  <p className={styles.trialDetailsText}>{item.trialDetails}</p>
                </div>
              )}
            </div>
          ))
        )}
      </div>
      
      <div className={styles.actionsContainer}>
        <button 
          className={styles.saveListButton}
          onClick={saveEntireList}
        >
          Save Entire List
        </button>
      </div>
      
      {showLoginPrompt && (
        <div className={styles.modalOverlay}>
          <div className={styles.loginPrompt}>
            <h2 className={styles.promptTitle}>Sign Up or Log In</h2>
            <p className={styles.promptText}>
              You need to be logged in to save items and lists to your account.
            </p>
            <div className={styles.promptActions}>
              <button 
                className={styles.cancelButton}
                onClick={() => setShowLoginPrompt(false)}
              >
                Cancel
              </button>
              <button 
                className={styles.loginButton}
                onClick={handleSignupLogin}
              >
                Sign Up / Log In
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Non-intrusive signup prompt for logged out users */}
      {!isLoggedIn && !showLoginPrompt && (
        <div className={styles.signupPrompt}>
          <p className={styles.promptInfo}>
            Create an account to save lists and get personalized recommendations
          </p>
          <button 
            className={styles.signupButton}
            onClick={handleSignupLogin}
          >
            Sign Up
          </button>
        </div>
      )}
    </div>
  );
};

export default SharedListViewScreen;