import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './SharingList.module.css';

// Icons for sharing platforms
const WhatsAppIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
  </svg>
);

const FacebookIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
  </svg>
);

const EmailIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
    <polyline points="22,6 12,13 2,6"></polyline>
  </svg>
);

const LinkIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path>
    <path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path>
  </svg>
);

const ViewIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
    <circle cx="12" cy="12" r="3"></circle>
  </svg>
);

const FileIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
    <polyline points="14 2 14 8 20 8"></polyline>
    <line x1="16" y1="13" x2="8" y2="13"></line>
    <line x1="16" y1="17" x2="8" y2="17"></line>
    <polyline points="10 9 9 9 8 9"></polyline>
  </svg>
);

const SharingList = () => {
  const { listId } = useParams();
  const history = useHistory();
  
  // Sample list data
  const sampleList = {
    id: "list-2",
    title: "Hospital Bag",
    type: "hospital",
    colorTheme: "blue",
    itemCount: 12
  };
  
  // State for personal message
  const [personalMessage, setPersonalMessage] = useState('');
  const [privacyOptions, setPrivacyOptions] = useState({
    includeNotes: true,
    includePrices: true,
    includeAlternatives: true
  });
  const [linkGenerated, setLinkGenerated] = useState(false);
  const [sharedUrl, setSharedUrl] = useState('');
  
  // Mock sharing functions
  const shareViaWhatsApp = () => console.log("Sharing via WhatsApp");
  const shareViaFacebook = () => console.log("Sharing via Facebook");
  const shareViaEmail = () => console.log("Sharing via Email");
  
  const copyLinkToClipboard = () => {
    // Format the URL correctly with hash and proper ID format
    const url = `${window.location.origin}/#/shared-list/shared-list-${listId.replace('list-', '')}`;
    setSharedUrl(url);
    
    navigator.clipboard.writeText(url);
    alert("Link copied to clipboard!");
    
    // Set a state to show the view button
    setLinkGenerated(true);
  };
  
  const viewSharedList = () => {
    history.push(`/shared-list/shared-list-${listId.replace('list-', '')}`);
  };
  
  const generatePDF = () => console.log("Generating PDF");
  
  // Handle going back
  const handleBack = () => {
    history.push(`/list/${listId}`);
  };
  
  // Handle message change
  const handleMessageChange = (e) => {
    setPersonalMessage(e.target.value);
  };
  
  // Handle privacy option toggle
  const togglePrivacyOption = (option) => {
    setPrivacyOptions(prev => ({
      ...prev,
      [option]: !prev[option]
    }));
  };
  
  // Get color class based on theme
  const getColorClass = () => {
    switch (sampleList.colorTheme) {
      case 'blue': return styles.blueTheme;
      case 'purple': return styles.purpleTheme;
      case 'pink': return styles.pinkTheme;
      case 'green': return styles.greenTheme;
      default: return styles.defaultTheme;
    }
  };
  
  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <button 
          className={styles.backButton} 
          onClick={handleBack}
          aria-label="Go back"
        >
          &larr;
        </button>
        <h1 className={styles.title}>Share List</h1>
      </header>
      
      <div className={styles.content}>
        <section className={styles.previewSection}>
          <h2 className={styles.sectionTitle}>Preview</h2>
          <div className={`${styles.previewCard} ${getColorClass()}`}>
            <div className={styles.previewHeader}>
              <h3 className={styles.previewTitle}>{sampleList.title}</h3>
              <span className={styles.previewType}>{sampleList.type}</span>
            </div>
            <div className={styles.previewDetails}>
              <p>{sampleList.itemCount} items</p>
              {personalMessage && (
                <div className={styles.previewMessage}>
                  <h4>Personal Note:</h4>
                  <p>{personalMessage}</p>
                </div>
              )}
            </div>
            <div className={styles.previewPrivacy}>
              <p className={styles.previewPrivacyText}>
                {privacyOptions.includeNotes ? 'Including' : 'Excluding'} notes
                {' • '}
                {privacyOptions.includePrices ? 'Including' : 'Excluding'} prices
                {' • '}
                {privacyOptions.includeAlternatives ? 'Including' : 'Excluding'} alternatives
              </p>
            </div>
          </div>
        </section>
        
        <section className={styles.messageSection}>
          <h2 className={styles.sectionTitle}>Add a Personal Message</h2>
          <textarea
            className={styles.messageInput}
            placeholder="Add a personal message to send with your list..."
            value={personalMessage}
            onChange={handleMessageChange}
            rows={4}
          />
        </section>
        
        <section className={styles.privacySection}>
          <h2 className={styles.sectionTitle}>Privacy Options</h2>
          <div className={styles.privacyOptions}>
            <label className={styles.privacyOption}>
              <input
                type="checkbox"
                checked={privacyOptions.includeNotes}
                onChange={() => togglePrivacyOption('includeNotes')}
                className={styles.checkbox}
              />
              <span className={styles.checkmark}></span>
              <span>Include My Notes</span>
            </label>
            
            <label className={styles.privacyOption}>
              <input
                type="checkbox"
                checked={privacyOptions.includePrices}
                onChange={() => togglePrivacyOption('includePrices')}
                className={styles.checkbox}
              />
              <span className={styles.checkmark}></span>
              <span>Include Prices</span>
            </label>
            
            <label className={styles.privacyOption}>
              <input
                type="checkbox"
                checked={privacyOptions.includeAlternatives}
                onChange={() => togglePrivacyOption('includeAlternatives')}
                className={styles.checkbox}
              />
              <span className={styles.checkmark}></span>
              <span>Include Alternative Products</span>
            </label>
          </div>
        </section>
        
        <section className={styles.sharingSection}>
          <h2 className={styles.sectionTitle}>Share Via</h2>
          <div className={styles.sharingButtons}>
            <button 
              className={`${styles.shareButton} ${styles.whatsappButton}`}
              onClick={shareViaWhatsApp}
            >
              <WhatsAppIcon />
              <span>WhatsApp</span>
            </button>
            
            <button 
              className={`${styles.shareButton} ${styles.facebookButton}`}
              onClick={shareViaFacebook}
            >
              <FacebookIcon />
              <span>Facebook</span>
            </button>
            
            <button 
              className={`${styles.shareButton} ${styles.emailButton}`}
              onClick={shareViaEmail}
            >
              <EmailIcon />
              <span>Email</span>
            </button>
            
            <button 
              className={`${styles.shareButton} ${styles.linkButton}`}
              onClick={copyLinkToClipboard}
            >
              <LinkIcon />
              <span>Copy Link</span>
            </button>
            
            {linkGenerated && (
              <button 
                className={`${styles.shareButton} ${styles.viewButton}`}
                onClick={viewSharedList}
              >
                <ViewIcon />
                <span>View Shared List</span>
              </button>
            )}
          </div>
        </section>
        
        <section className={styles.pdfSection}>
          <button 
            className={styles.pdfButton}
            onClick={generatePDF}
          >
            <FileIcon />
            <span>Generate PDF</span>
          </button>
        </section>
      </div>
    </div>
  );
};

export default SharingList;