import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './ItemDetailScreen.module.css';

const ItemDetailScreen = () => {
  const { listId, itemId } = useParams();
  const history = useHistory();
  const [item, setItem] = useState(null);
  const [isAcquired, setIsAcquired] = useState(false);

  // In a real app, you would fetch the item data from an API
  // For now, we'll use the sample data
  useEffect(() => {
    // Mock API call to get item data
    const fetchItemData = () => {
      // This would be replaced with an actual API call
      // Sample items that could be returned based on itemId
      const itemsDatabase = {
        "item-1": {
          id: "item-1",
          name: "Extra-long phone charger",
          brand: "Amazon Basics Cable",
          description: "Hydration essential for labor and recovery",
          priority: "must-have",
          category: "Tech",
          ageRange: "N+",
          priceRange: "$",
          purchaseLink: "https://example.com/charger",
          notes: "The outlets are far away from the bed! The bed is high and wide and weird! You will be on your phone a lot! Keep it charged and accessible.",
          isAcquired: false,
          trialAvailable: false
        },
        "item-2": {
          id: "item-2",
          name: "Water bottle w/ straw",
          brand: "Ello Glass Tumbler w/ Silicone Sleeve + Straw",
          description: "Hydration essential for labor and recovery",
          priority: "must-have",
          category: "Essentials",
          ageRange: "N+",
          priceRange: "$",
          purchaseLink: "https://example.com/waterbottle",
          notes: "You need to stay hydrated during labor and the positions you'll be in don't lend themselves to an open-mouth water bottle or cup. Straws are helpful. Also useful when you're home and nursing/holding a baby.",
          isAcquired: false,
          trialAvailable: true,
          trialDetails: "Available for 14-day risk-free trial through BabyGear rental service."
        },
        "item-10": {
          id: "item-10",
          name: "Anti-Colic Bottle Set",
          brand: "Dr. Brown's",
          description: "Complete starter set for bottle feeding",
          priority: "nice-to-have",
          category: "Feeding",
          ageRange: "N+",
          priceRange: "$$",
          purchaseLink: "https://example.com/drbrowns",
          notes: "These bottles have a special venting system that helps reduce colic, gas, and spit-up. The set comes with different nipple flow rates for different ages.",
          isAcquired: false,
          trialAvailable: true,
          trialDetails: "Try a single bottle before committing to the set",
          hasPerk: true,
          perkDetails: {
            type: "discount",
            title: "Dr. Brown's Anti-Colic Bottle Starter Set",
            description: "3 bottles in different sizes with anti-colic venting system, cleaning brushes and extra nipples",
            message: "Your search for colic solutions unlocked this 60% discount on a starter set. Tap to claim your exclusive code.",
            discount: "60% OFF"
          }
        },
        "item-11": {
          id: "item-11",
          name: "Silicone Breast Pump",
          brand: "Haakaa",
          description: "Passive milk collection while nursing",
          priority: "nice-to-have",
          category: "Feeding",
          ageRange: "N+",
          priceRange: "$",
          purchaseLink: "https://example.com/haakaa",
          notes: "This silicone pump is great for catching letdown from the opposite breast during nursing sessions. No electricity or batteries needed, and it's easy to clean.",
          isAcquired: false,
          trialAvailable: false,
          hasPerk: true,
          perkDetails: {
            type: "special",
            title: "Haakaa Silicone Breast Pump & Milk Collector",
            description: "One silicone pump with stopper",
            message: "As you prepare for breastfeeding, we've arranged special $9.99 pricing just for you. Tap to get your code now.",
            price: "$9.99"
          }
        },
        "item-13": {
          id: "item-13",
          name: "Bath Essentials",
          brand: "Tubby Todd",
          description: "Gentle bath products for sensitive skin",
          priority: "nice-to-have",
          category: "Essentials",
          ageRange: "N+",
          priceRange: "$$",
          purchaseLink: "https://example.com/tubbytodd",
          notes: "These products are great for babies with sensitive skin. All natural ingredients and very gentle.",
          isAcquired: false,
          trialAvailable: false,
          hasPerk: true,
          perkDetails: {
            type: "sample",
            title: "Tubby Todd Bath Essentials Mini Set",
            description: "Travel sizes of shampoo, body wash, lotion, and all-over ointment",
            message: "Your search for sensitive skin products prompted us to secure this sample set for you. Tap to order your free samples.",
            badge: "FREE"
          }
        },
        "item-14": {
          id: "item-14",
          name: "Baby Carrier",
          brand: "Ergobaby Embrace",
          description: "Soft structured carrier for newborns",
          priority: "must-have",
          category: "Essentials",
          ageRange: "N+",
          priceRange: "$$$",
          purchaseLink: "https://example.com/ergobaby",
          notes: "This carrier is specifically designed for newborns with no infant insert needed. Very comfortable and easy to put on.",
          isAcquired: false,
          trialAvailable: true,
          trialDetails: "Try on at local babywearing group meetings",
          hasPerk: true,
          perkDetails: {
            type: "discount",
            title: "Ergobaby Embrace Newborn Carrier",
            description: "Soft structured carrier specifically designed for newborns",
            message: "Since you're expecting a newborn soon, here's 40% off this specialized carrier. Click to claim your code now.",
            discount: "40% OFF"
          }
        }
      };
      
      // Get the item based on the itemId from the URL
      const selectedItem = itemsDatabase[itemId] || itemsDatabase["item-2"]; // Default to water bottle if item not found
      
      setItem(selectedItem);
      setIsAcquired(selectedItem.isAcquired);
    };

    fetchItemData();
  }, [itemId]);

  // Handle back navigation
  const handleBack = () => {
    history.push(`/list/${listId}`);
  };

  // Handle edit navigation
  const handleEdit = () => {
    history.push(`/list/${listId}/edit-item/${itemId}`);
  };

  // Handle purchase button click
  const handlePurchase = () => {
    if (item?.purchaseLink) {
      window.open(item.purchaseLink, '_blank');
    }
  };

  // Handle trial info button click
  const handleTrialInfo = () => {
    // In a real app, you might navigate to a trial info page or show a modal
    alert(item.trialDetails);
  };

  // Handle perk claim button click
  const handleClaimPerk = () => {
    // In a real app, this would open a modal or navigate to a perk claim screen
    alert(`Claiming perk: ${item.perkDetails.title}`);
  };

  // Handle toggling the acquired status
  const handleToggleAcquired = () => {
    setIsAcquired(!isAcquired);
    
    // In a real app, you would update this in your database
    console.log(`Item ${itemId} acquired status changed to: ${!isAcquired}`);
  };

  // If item is still loading, show a loading state
  if (!item) {
    return (
      <div className={styles.loadingContainer}>
        <div className={styles.loadingText}>Loading item details...</div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <button 
          className={styles.backButton} 
          onClick={handleBack}
          aria-label="Go back"
        >
          &larr;
        </button>
        <h1 className={styles.title}>Item Details</h1>
        <button 
          className={styles.editButton}
          onClick={handleEdit}
          aria-label="Edit item"
        >
          Edit
        </button>
      </header>

      <div className={styles.itemCard}>
        <div className={styles.itemHeader}>
          <h2 className={styles.itemName}>{item.name}</h2>
          <div className={styles.priorityBadge} data-priority={item.priority}>
            {item.priority === 'must-have' ? 'Must Have' : 'Nice to Have'}
          </div>
        </div>

        <div className={styles.brandContainer}>
          <span className={styles.brandLabel}>Brand:</span>
          <span className={styles.brandValue}>{item.brand}</span>
        </div>

        <div className={styles.detailsRow}>
          <div className={styles.detail}>
            <span className={styles.detailLabel}>Category</span>
            <span className={styles.categoryBadge}>{item.category}</span>
          </div>
          
          <div className={styles.detail}>
            <span className={styles.detailLabel}>Age Range</span>
            <span className={styles.ageBadge}>{item.ageRange}</span>
          </div>
          
          <div className={styles.detail}>
            <span className={styles.detailLabel}>Price</span>
            <span className={styles.priceBadge}>{item.priceRange}</span>
          </div>
        </div>

        {/* Trial Badge */}
        {item.trialAvailable && (
          <div className={styles.trialContainer}>
            <div className={styles.trialBadge}>
              <svg className={styles.trialIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
              </svg>
              <span>Trial before you buy</span>
            </div>
            <button 
              className={styles.trialInfoButton}
              onClick={handleTrialInfo}
            >
              Trial Info
            </button>
          </div>
        )}

        {/* Perk Badge - NEW */}
        {item.hasPerk && (
          <div className={styles.perkContainer}>
            <div className={styles.perkBadge}>
              <svg className={styles.perkIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <polyline points="20 12 20 22 4 22 4 12"></polyline>
                <rect x="2" y="7" width="20" height="5"></rect>
                <line x1="12" y1="22" x2="12" y2="7"></line>
                <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
                <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
              </svg>
              <span>Special Offer</span>
              {item.perkDetails.type === 'discount' && (
                <span className={styles.perkHighlight}>{item.perkDetails.discount}</span>
              )}
              {item.perkDetails.type === 'sample' && (
                <span className={styles.perkHighlight}>FREE</span>
              )}
              {item.perkDetails.type === 'special' && (
                <span className={styles.perkHighlight}>{item.perkDetails.price}</span>
              )}
            </div>
            <button 
              className={styles.perkClaimButton}
              onClick={handleClaimPerk}
            >
              Claim Offer
            </button>
          </div>
        )}

        <div className={styles.descriptionContainer}>
          <p className={styles.description}>{item.description}</p>
        </div>

        <div className={styles.notesSection}>
          <h3 className={styles.notesTitle}>Notes</h3>
          <p className={styles.notesContent}>{item.notes}</p>
        </div>

        {/* Trial Details Section */}
        {item.trialAvailable && (
          <div className={styles.trialDetailsSection}>
            <h3 className={styles.trialTitle}>Trial Information</h3>
            <p className={styles.trialContent}>{item.trialDetails}</p>
          </div>
        )}

        {/* Perk Details Section - NEW */}
        {item.hasPerk && (
          <div className={styles.perkDetailsSection}>
            <h3 className={styles.perkTitle}>{item.perkDetails.title}</h3>
            <p className={styles.perkDescription}>{item.perkDetails.description}</p>
            <p className={styles.perkMessage}>{item.perkDetails.message}</p>
          </div>
        )}

        <div className={styles.acquiredContainer}>
          <label className={styles.acquiredLabel}>
            <input
              type="checkbox"
              checked={isAcquired}
              onChange={handleToggleAcquired}
              className={styles.acquiredCheckbox}
            />
            <span className={styles.checkmark}></span>
            <span className={styles.acquiredText}>
              {isAcquired ? "Item acquired" : "Mark as acquired"}
            </span>
          </label>
        </div>

        <div className={styles.actionsContainer}>
          {item.trialAvailable && (
            <button 
              className={styles.trialButton}
              onClick={handleTrialInfo}
            >
              Get Trial
            </button>
          )}
          <button 
            className={styles.purchaseButton}
            onClick={handlePurchase}
          >
            Purchase
          </button>
        </div>
      </div>
    </div>
  );
};

export default ItemDetailScreen;