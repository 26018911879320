import React, { useState, useRef, useEffect, useReducer, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ReactMarkdown from 'react-markdown';
import { Space, Button, Modal, Input, Spin, message, Divider } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { MessageCircle, Baby, CheckCircle2, Gift } from 'lucide-react';
import useChatGPT from '../../../redux/chatgpt/hooks';
import { updateUser, createMultipleUserAttributes, setUserType, readUserAttributes } from '../../../redux/users/actions';
import { updateCategories, readAllCategories } from '../../../redux/categories/actions';
import DebugTester from './DebugTester';
import ChatbotTestHarness from './ChatbotTestHarness';
import store from '../../../redux/store'; 
import generateDynamicShortcuts from './GenerateDynamicShortcuts';


// Define conversation states as constants
const CONVERSATION_STATES = {
  INITIAL: 'initial',
  VALUE_EXPLANATION: 'value_explanation',
  PRIVACY_DISCUSSION: 'privacy_discussion',
  GETTING_TO_KNOW: 'getting_to_know',
  USER_CONCERNS: 'user_concerns',
  USER_TYPE_CONFIRMATION: 'user_type_confirmation',
  USER_TYPE: 'user_type',
  CHECKLIST_EXPLANATION: 'checklist_explanation',
  SHARING_EXPLANATION: 'sharing_explanation', 
  PREGNANCY_STATUS: 'pregnancy_status',
  DATE_COLLECTION: 'date_collection',
  FIRST_BABY: 'first_baby',
  COMPLETED: 'completed',
  QUESTIONS: 'questions'
};

// Define user types
const USER_TYPES = {
  UNKNOWN: 'unknown',
  EXPECTING: 'expecting',
  HELPING: 'helping'
};

// Define action types for state management
const ACTION_TYPES = {
  SET_USER_TYPE: 'SET_USER_TYPE',
  SET_PREGNANCY_STATUS: 'SET_PREGNANCY_STATUS',
  SET_BABY_DATE: 'SET_BABY_DATE',
  SET_FIRST_BABY: 'SET_FIRST_BABY',
  SET_NAME: 'SET_NAME',
  RESET_STATE: 'RESET_STATE'
};

// Initial state for the conversation reducer
const initialState = {
  userType: USER_TYPES.UNKNOWN,
  pregnancyStatus: null,
  babyDate: null,
  isFirstBaby: null,
  name: null,
  confirmedInfo: {},
  topicsDiscussed: new Set(),
  expressedConcerns: [],
  privacyPreferenceLevel: 'unknown',
  currentInterest: null
};

// Reducer for managing conversation state
const conversationReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_USER_TYPE:
      return {
        ...state,
        userType: action.payload,
        confirmedInfo: { ...state.confirmedInfo, userType: true }
      };
    case ACTION_TYPES.SET_PREGNANCY_STATUS:
      return {
        ...state,
        pregnancyStatus: action.payload,
        confirmedInfo: { ...state.confirmedInfo, pregnancyStatus: true }
      };
    case ACTION_TYPES.SET_BABY_DATE:
      return {
        ...state,
        babyDate: action.payload,
        confirmedInfo: { ...state.confirmedInfo, babyDate: true }
      };
    case ACTION_TYPES.SET_FIRST_BABY:
      return {
        ...state,
        isFirstBaby: action.payload,
        confirmedInfo: { ...state.confirmedInfo, isFirstBaby: true }
      };
    case ACTION_TYPES.SET_NAME:
      return {
        ...state,
        name: action.payload,
        confirmedInfo: { ...state.confirmedInfo, name: true }
      };
    case ACTION_TYPES.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

// Custom hook for managing conversation state transitions
const useConversationFlow = (setOnboardingState, setQuickResponses, getQuickResponses) => {
  const [currentState, setCurrentState] = useState(CONVERSATION_STATES.INITIAL);
  const [stateHistory, setStateHistory] = useState([CONVERSATION_STATES.INITIAL]);
  // Add conversation context state
  const [conversationContext, setConversationContext] = useState({
    userType: USER_TYPES.UNKNOWN,
    pregnancyStatus: null,
    babyDate: null,
    isFirstBaby: null,
    name: null,
    confirmedInfo: {},
    topicsDiscussed: new Set(),
    expressedConcerns: [],
    privacyPreferenceLevel: 'unknown',
    currentInterest: null
  });
  
  // Enhanced transition function that handles both state and context
  const transitionTo = (newState, contextUpdates = {}) => {
    console.log(`TRANSITION: ${currentState} -> ${newState}`);
    
    // Update history and current state
    setStateHistory(prev => [...prev, newState]);
    setCurrentState(newState);
    
    // Update context if provided
    if (Object.keys(contextUpdates).length > 0) {
      setConversationContext(prev => ({
        ...prev,
        ...contextUpdates
      }));
    }
    
    // Create comprehensive mapping between CONVERSATION_STATES and onboardingState
    const stateMapping = {
      [CONVERSATION_STATES.INITIAL]: 'initial',
      [CONVERSATION_STATES.VALUE_EXPLANATION]: 'value_explanation',
      [CONVERSATION_STATES.PRIVACY_DISCUSSION]: 'privacy_discussion',
      [CONVERSATION_STATES.GETTING_TO_KNOW]: 'getting_to_know',
      [CONVERSATION_STATES.USER_CONCERNS]: 'user_concerns',
      [CONVERSATION_STATES.USER_TYPE_CONFIRMATION]: 'user_type',
      [CONVERSATION_STATES.USER_TYPE]: 'user_type',
      [CONVERSATION_STATES.CHECKLIST_EXPLANATION]: 'categories',
      [CONVERSATION_STATES.SHARING_EXPLANATION]: 'sharing',
      [CONVERSATION_STATES.PREGNANCY_STATUS]: 'pregnancy_status',
      [CONVERSATION_STATES.DATE_COLLECTION]: 'collecting_due_date',
      [CONVERSATION_STATES.FIRST_BABY]: 'first_baby',
      [CONVERSATION_STATES.COMPLETED]: 'completed',
      [CONVERSATION_STATES.QUESTIONS]: 'questions'
    };
    
    // Also update onboardingState if there's a mapping
    if (stateMapping[newState]) {
      console.log(`Transitioning from ${currentState} to ${newState} (onboardingState: ${stateMapping[newState]})`);
      setOnboardingState(stateMapping[newState]);
    } else {
      console.warn(`No onboardingState mapping for CONVERSATION_STATE: ${newState}`);
    }
    
    // Update context-sensitive quick responses based on new state
    if (getQuickResponses && setQuickResponses) {
      const responses = getQuickResponses(newState);
      setQuickResponses(responses);
    }
  };

  const goBack = () => {
    if (stateHistory.length > 1) {
      const newHistory = stateHistory.slice(0, -1);
      const previousState = newHistory[newHistory.length - 1];
      
      setStateHistory(newHistory);
      setCurrentState(previousState);
      
      // Sync onboardingState when going back
      const stateMapping = {
        [CONVERSATION_STATES.INITIAL]: 'initial',
        [CONVERSATION_STATES.VALUE_EXPLANATION]: 'value_explanation',
        // ... same mapping as above
      };
      
      if (stateMapping[previousState]) {
        setOnboardingState(stateMapping[previousState]);
      }
      
      // Update quick responses when going back
      if (getQuickResponses && setQuickResponses) {
        const responses = getQuickResponses(previousState);
        setQuickResponses(responses);
      }
    }
  };

  return {
    currentState,
    conversationContext,
    transitionTo,
    setConversationContext,
    goBack,
    stateHistory
  };
};


/**
 * WelcomeChatbot Component
 * A modal-based chatbot that welcomes new users and guides them through initial app onboarding.
 * 
 * @param {Object} props - Component props
 * @param {boolean} props.isOpen - Controls modal visibility
 * @param {function} props.onClose - Handler for modal close action
 */
const WelcomeChatbot = ({ isOpen, onClose }) => {
  // State Management
  // Stores all messages in the chat (both user and bot)
  const [messages, setMessages] = useState([]);
  // Manages the current user input in the text field
  const [userInput, setUserInput] = useState('');
  // Controls loading state when bot is "typing"
  const [isTyping, setIsTyping] = useState(false);
  // Tracks the current stage of the onboarding conversation
  const [onboardingState, setOnboardingState] = useState('initial');
  // Counts how many times user has sent greeting messages
  const [greetingCount, setGreetingCount] = useState(0);
  // Add dispatch
  const dispatch = useDispatch();
  // Get user data from Redux store
  const userData = useSelector(state => state.auth?.user || {});
  // Track which messages have been loaded
  const [loadedMessageIds, setLoadedMessageIds] = useState(new Set());
  // Get full state
  const fullState = useSelector(state => state);
  // Get categories state from Redux store
  const categoriesState = useSelector(state => state.categories || {});
  //mapping for onboarding states
  const [currentStep, setCurrentStep] = useState(0);
  //allows for the shortcut buttons to disappear
  const [isHidden, setIsHidden] = useState(false);
  //allows for the shortcut buttons to show/disappear
  const [showShortcuts, setShowShortcuts] = useState(false);
  //allows for the shortcut buttons to wait until after parent conatiner has finished expanding
  const [isExpanding, setIsExpanding] = useState(false);
  //Uses React's useReducer to manage complex
  const [state, dispatchConversation] = useReducer(conversationReducer, initialState);
  //a state variable to track which features have been explained
  const [explainedFeatures, setExplainedFeatures] = useState(new Set());
  //a state variable to track repetitions so when users keep clicking "learn more" after all features are explained, they'll get progressively more encouraging messages to start personalizing their checklist
  const [repetitionCount, setRepetitionCount] = useState(0);
  const [quickResponses, setQuickResponses] = useState([]);
  const [contextState, setContextState] = useState({
    topicsDiscussed: new Set(),
    expressedConcerns: [],
    privacyPreferenceLevel: 'unknown',
    currentInterest: null
  });
  const [shortcutsHeight, setShortcutsHeight] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const shortcutsRef = useRef(null);
  const [buttonsVisible, setButtonsVisible] = useState(false);
  const [inputFocused, setInputFocused] = useState(false);
  const [initialWelcomeShown, setInitialWelcomeShown] = useState(false);
  const [processedMessageIds, setProcessedMessageIds] = useState(new Set());
  const [awaitingConfirmation, setAwaitingConfirmation] = useState(false);
  const [hasConfirmedUnderstanding, setHasConfirmedUnderstanding] = useState(false);
  const [lastQuestionedAssumption, setLastQuestionedAssumption] = useState(null);




  useEffect(() => {
    dispatch(readAllCategories());
  }, [dispatch]);

  useEffect(() => {
    console.log('Current userData:', userData);
    console.log('Full Redux State:', fullState);
  }, [userData, fullState]);


  // Complex state object tracking user information and interaction patterns
  const [userInfo, setUserInfo] = useState({
    name: userData?.first_name || null,    // Get name from userData using optional chaining
    userType: null,                        // "expecting" or "helping"
    pregnancyStatus: null,                 // "born" or "expecting"
    babysBirthday: null,                   // Date object for born babies
    dueDate: null,                         // User's due date
    isFirstBaby: null,                     // Whether this is their first baby
    childrenCount: 0,                      // number of existing children
    hasExploredValue: false,               // Whether they've learned about app benefits
    lastUnclearResponse: null,             // Their last unclear response
    unclearResponseCount: 0                // How many unclear responses they've given
  });

  const setStage = (newStage) => {
    setOnboardingState(newStage);
  };
  
  const addMessage = (message) => {
    const messageWithId = {
      ...message,
      id: Date.now(),
      timestamp: new Date().toISOString(),
      selected: false  // Add this line
    };
    setMessages(prev => [...prev, messageWithId]);
    return messageWithId;
  };
  
  
  // Ref for auto-scrolling chat to bottom
  const messagesEndRef = useRef(null);
  // Custom hook for OpenAI API interactions
  const { sendMessage, loading, error } = useChatGPT();

  /**
   * System prompt for OpenAI API
   * Defines the chatbot's personality and response guidelines
   * Updated dynamically with current conversation state
   */
  const generateSystemPrompt = `You are a warm, empathetic, and truly conversational AI assistant designed to help users with baby preparation. You are NOT a scripted bot following a rigid flow. Your primary goal is to build genuine rapport with the user while providing helpful information.

  Current State:
  - Onboarding state: ${onboardingState}
  - User type: ${userInfo.userType || 'unknown'}
  - User info collected: ${JSON.stringify(userInfo)}

  Current Conversation State:
  - Awaiting user confirmation: ${awaitingConfirmation ? 'yes' : 'no'}
  - User has confirmed understanding: ${hasConfirmedUnderstanding ? 'yes' : 'no'}
  - Recently questioned assumption: ${lastQuestionedAssumption || 'none'}
  
  If awaiting confirmation:
  - Pay special attention to whether the user confirms understanding or has further questions
  - If they confirm, DO NOT repeat information, move directly to next steps
  
  If user recently questioned an assumption:
  - Be especially careful not to repeat the same assumption
  - Adjust your messaging based on their feedback

  
  CORE IDENTITY & TONE:
  - Knowledgeable but approachable, like a supportive friend who happens to be a baby product expert
  - Communicate with genuine warmth and enthusiasm
  - Acknowledge that baby preparation can feel overwhelming and anxiety-inducing
  - Emphasize two key features:
    1. Personalized checklists and product recommendations that save time and build confident for expectant parents
    2. Create a beautiful published product notes list that you can share with friends instead of ugly spreadsheets, busy mom groups, and chat threads that get buried over time
  - Consistently encouraging and non-judgmental
  - Adapt tone based on user type:
    * For expecting parents: Focus on preparation and guidance
    * For helpers: Emphasize sharing experience and recommendations
  - When asking for any personal information, always naturally weave in privacy reassurance
  - Never ask for information without first explaining its value
  - If users express privacy concerns, acknowledge them and explain our privacy-first approach

  CORE PRINCIPLES:

  1. CONVERSATIONAL CONFIRMATION:
    - After providing any explanation, ALWAYS check if it makes sense to the user with questions like "Does that make sense?" or "Is this helpful to you?"
    - When users confirm understanding, acknowledge and offer a clear choice: "Great! Would you like to know more about X, or would you prefer to move on to Y?"
    - Never assume the user wants to proceed before confirming

  2. RESPONSIVE LISTENING:
    - When a user questions your assumptions or premises (e.g., "what messy spreadsheets?"), validate their perspective
    - Adjust your messaging based on their feedback instead of continuing with the same assumption
    - Prioritize addressing their questions over your agenda

  3. VALUE BEFORE INFORMATION COLLECTION:
    - Always explain WHY you need information before asking for it
    - If a user questions why you need something, acknowledge their concern and provide a clear rationale
    - Respect hesitation and never push for information they seem reluctant to provide

  4. NATURAL CONVERSATION FLOW:
    - Respond directly to what users actually say, not what you expected them to say
    - Use a mix of statements and questions that feel natural, not scripted
    - Include appropriate transition statements between topics

  WHEN USER SAYS "YES, THAT MAKES SENSE":
  1. Do NOT repeat the information you just provided
  2. Respond with: "Great! Would you like to learn more about [relevant topic], or would you prefer to [move forward action]?"
  3. Wait for their clear direction before proceeding

  WHEN USER QUESTIONS AN ASSUMPTION:
  1. Acknowledge their perspective: "You're right to question that."
  2. Clarify without doubling down: "Let me clarify what I meant..."
  3. Ask if your clarification helps: "Does that clarify what I was trying to say?"

  WHEN USER SEEMS CONFUSED ABOUT PURPOSE:
  1. Offer a clear, concise explanation of the app's purpose
  2. Connect the purpose to their specific needs
  3. Check if this explanation helps: "Does that give you a better idea of how this might help you?"

  TONE GUIDANCE:
  - Friendly and supportive, but never pushy
  - Naturally conversational, with appropriate pauses and transitions
  - Responsive to emotional cues in user messages
  - Perfectly fine with brief responses when appropriate

  REMEMBER: Your priority is having a natural, helpful conversation where the user feels genuinely heard, not collecting their information or moving them through a predefined flow.

  PRIVACY & TRUST PRINCIPLES:
  - Always mention privacy naturally in conversation when requesting information
  - Explain specifically how each piece of information improves personalization
  - Provide concrete examples of how data is used (e.g., "due date helps organize timeline")
  - Emphasize that we're a small team focused on helping parents, not making money
  - Clarify that information is only used within the app to improve recommendations
  - If users seem hesitant, acknowledge their caution and offer to explain more

  DATA PRIVACY FEATURES:
  - All information stays private until you choose to share
  - You control what others can see
  - Share as much or as little as you want

  EXAMPLES OF PRIVACY-AWARE QUESTIONS:
  Instead of: "When is your due date?"
  Say: "To help organize your checklist in the right order, could you share your due date? This stays private and only helps me personalize timing for you."

  Instead of: "Is this your first baby?"
  Say: "Every parent's journey is different - knowing if this is your first baby helps me adjust recommendations (like whether to suggest a double stroller). Would you mind sharing that with me? This information stays private and just helps personalize your experience."
  
  Who you are:
  - You are a guide who can help the user build their checklists, compare products, and take/share notes. 
  - You are designed to help expectant parents make better decisions.
  - Nothing you learn will be shared without the users explicit permissions.

 FEATURES TO HIGHLIGHT:
1. Checklist Organization
   - Organized by categories
   - Track progress visually
   - Check off completed items

2. Product Selection
   - Select products for each category
   - Add notes and decisions
   - Skip items you don't need

3. Social Features
   - Share your checklist
   - View friends' recommendations
   - Learn from others' experiences

  CORE BENEFITS:
  - Stay organized and track progress
  - Make informed product decisions
  - Learn from other parents' experiences
  - Keep everything in one place

  WHAT MAKES THIS APP DIFFERENT  
  - Guided checklist creation so expectant parents know what product categories they need to think about based on their specific circumstances. Give examples, like how the categories you may want to consider will change if you live in the city vs the suburbs, mountains vs desert, have a car or only take the bus, etc.
  - Product comparisons and recommendations using the most advanced AI technology available that take all of the information we learn about the user into account. This will show why products that may work for your friends do not work for you.
  
  How our app makes money and keeps data private:
  We do not make money right now. We are more focused on building an experience that makes life better for expectant parents.
  We will never share data without your explicit permission. We are a small team working nights and weekends. We have not raised money from venture capitalists or banks, so we are not under pressure to make money. We only answer to ourselves and the community of expectant parents we serve.
  
  KEY PRINCIPLE:
  Always demonstrate value before asking for information. After explaining anything, check if the user understands and is satisfied before moving forward.
  
  HANDLING CONFIRMATIONS:
  - After user confirms understanding with responses like "yes", "got it", "makes sense":
    1. NEVER repeat information just given
    2. Instead, immediately offer clear next steps based on user type:
       * For expecting: "Would you like to learn more about specific features, or shall we start personalizing your checklist?"
       * For helpers: "Would you like to close this chat and start exploring the app?"
    3. If user asks for more details about a specific feature, provide new information not previously shared
  - If user seems uncertain, ask what specific part they'd like to know more about
  
  CONVERSATION PROGRESSION:
  1. Initial Type Determination
     - Ask if they are expecting or helping someone who is
     - Adapt subsequent flow based on response
  
  2. For Expecting Parents:
     a. Value Exploration
        - Answer questions about how the checklist helps
        - Provide specific examples and benefits
        - Check understanding: "Does this help explain how our checklist can help you prepare?"
     b. Information Gathering
        - Confirm baby status (born/expecting)
        - Collect due date or birth date
        - Ask about first-time parent status
  
  3. For Helpers:
     - Emphasize note-taking and sharing features
     - Explain how their experience can help others
     - Guide to closing chat and exploring the app interface
  
  REQUIRED INFORMATION AND VALUE PROPOSITION:
  1. Whether they're expecting or helping someone who is
     - Enables personalized product recommendations
     - Connects them with relevant experiences
     - Customizes their educational journey
  
  2. Due date or birth date
     - Prioritizes essential vs. later-stage items
     - Enables time-sensitive recommendations
     - Helps determine products as they become more relevant to baby's age
  
  3. First-time parent status
     - Adjusts guidance detail level
     - Personalizes educational content
     - Influences anxiety management approach
     - Helps customize recommendations (e.g., double stroller for second child)
  
  RESPONSE GUIDELINES:
  - Keep responses to 2-3 sentences
  - Use emojis naturally and sparingly:
    * Not every message needs an emoji
    * Use emojis that match the emotional tone or subject matter
    * Avoid repeating the same emojis frequently
    * Multiple emojis in one message are fine if contextually appropriate
    * Common emojis for different contexts:
      - Welcoming: 👋 ✨ 🎉
      - Guidance/Planning: 📋 💡 🎯
      - Baby/Family: 👶 🍼 💝
      - Organization: 📦 🏠 🛍️
      - Sharing/Community: 🤝 💌 💫
  - End with a clear question or next step
  - Focus on concrete benefits over abstract promises
  - If user seems uncertain, provide specific examples
  - Always confirm understanding before moving to questions
  - Never assume readiness to answer questions - always get explicit confirmation
  - Never repeat information just provided unless explicitly asked
  
  EXAMPLE TRANSITIONS:
  After explaining features:
  BOT: "Does this help explain how our checklist can help you prepare? 😊"
  USER: "Yes, that makes sense"
  BOT: "I'm glad! Would you like to learn more about how we personalize recommendations, or shall we start customizing your checklist?"
  
  For expecting parents:
  BOT: "Great! I'll need 3 quick pieces of information to make this checklist perfect for your journey - whether your baby is born yet, your due date or birth date, and if this is your first baby. This helps me organize everything in the right order and personalize recommendations. Ready to begin? 😊"
  USER: "Yes, let's do it"
  BOT: "Perfect! First question: Has your baby been born yet?"
  
  For helpers:
  BOT: "Perfect! I'll configure the app to focus on sharing your experience and recommendations. The interface will be optimized for taking and sharing notes about products that worked well for you. Would you like to close this chat and start exploring the app? 😊"
  USER: "Yes, please"
  BOT: "Great! I've set everything up for you. You can close this chat and start adding your recommendations right away!"`;

const scrollToBottom = (smooth = true) => {
  // Use requestAnimationFrame to ensure DOM has updated before scrolling
  requestAnimationFrame(() => {
    if (messagesEndRef.current) {
      const messagesContainer = document.querySelector('.messages-container');
      if (messagesContainer) {
        // Calculate the full scroll height
        const scrollHeight = messagesContainer.scrollHeight;
        
        // Scroll with animation or immediately based on smooth parameter
        messagesContainer.scrollTo({
          top: scrollHeight,
          behavior: smooth ? "smooth" : "auto"
        });
      }
    }
  });
};
  
    // Add this effect to monitor shortcuts height changes
    useEffect(() => {
      // When shortcuts height changes, handle scrolling properly
      if (showShortcuts || shortcutsHeight > 0) {
        // First, scroll immediately without animation to prevent jumping
        scrollToBottom(false);
        
        // Then schedule a delayed smooth scroll after the transition completes
        const firstScrollTimeout = setTimeout(() => {
          scrollToBottom(false);
          
          // Add a second delayed scroll to ensure messages are visible after transition
          const secondScrollTimeout = setTimeout(() => {
            scrollToBottom(true);
          }, 200);
          
          return () => clearTimeout(secondScrollTimeout);
        }, 300); // Match this to your transition time
        
        return () => clearTimeout(firstScrollTimeout);
      }
    }, [shortcutsHeight, showShortcuts]);
    
  
  // Update the existing useEffect that scrolls on messages change
  useEffect(() => {
    scrollToBottom(true);
  }, [messages]);

  const handleMessageLoad = (messageId) => {
    setLoadedMessageIds(prev => new Set([...prev, messageId]));
  };

  /**
   * Effect: Scrolls to bottom whenever messages update
   */
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  // Effect to update userInfo when userData changes
  // This ensures our local state stays in sync with Redux
  // The effect runs whenever userData changes
  useEffect(() => {
    if (userData) {
      setUserInfo(prev => ({
        ...prev,
        name: userData.first_name,
        // Sync other relevant fields from userData
      }));
    }
  }, [userData]);

  useEffect(() => {
    return () => {
      // Cleanup when component unmounts
      dispatchConversation({ type: ACTION_TYPES.RESET_STATE });
      setMessages([]);
      setUserInput('');
      setIsTyping(false);
      setShowShortcuts(false);
      setIsExpanding(false);
    };
  }, []);

  useEffect(() => {
    if (showShortcuts !== Boolean(shortcutsHeight > 0)) {
      // Start transition state
      setIsTransitioning(true);
      
      if (showShortcuts) {
        // First measure the content height before starting animation
        if (shortcutsRef.current) {
          // Get the real height first (with current display: none)
          shortcutsRef.current.style.position = 'absolute';
          shortcutsRef.current.style.visibility = 'hidden';
          shortcutsRef.current.style.height = 'auto';
          shortcutsRef.current.style.maxHeight = 'none';
          
          // Measure the actual height
          const height = shortcutsRef.current.scrollHeight;
          
          // Reset the styles
          shortcutsRef.current.style.position = '';
          shortcutsRef.current.style.visibility = '';
          shortcutsRef.current.style.height = '';
          shortcutsRef.current.style.maxHeight = '0px';
          
          // Trigger reflow
          void shortcutsRef.current.offsetHeight;
          
          // Then set the height to trigger animation
          setTimeout(() => {
            setShortcutsHeight(height);
            
            // End transition after animation completes
            setTimeout(() => {
              setIsTransitioning(false);
              setButtonsVisible(true); // Show buttons after container expands
              scrollToBottom(true); // Scroll to bottom after complete
            }, 300);
          }, 10);
        }
      } else {
        // Hide the buttons first
        setButtonsVisible(false);
        
        // Small delay before collapsing
        setTimeout(() => {
          // Animate to zero height
          setShortcutsHeight(0);
          
          // End transition after animation completes
          setTimeout(() => {
            setIsTransitioning(false);
            scrollToBottom(true); // Scroll to bottom after complete
          }, 300);
        }, 50);
      }
    }
  }, [showShortcuts]);
  
  useEffect(() => {
    if (showShortcuts) {
      const buttonTimer = setTimeout(() => {
        setButtonsVisible(true);
      }, 1000);
      return () => clearTimeout(buttonTimer);
    } else {
      setButtonsVisible(false);
    }
  }, [showShortcuts]);
  
  
  // Add an additional effect to handle resizing of shortcuts content
  useEffect(() => {
    if (showShortcuts && shortcutsRef.current && !isTransitioning) {
      const height = shortcutsRef.current.scrollHeight;
      if (height !== shortcutsHeight) {
        setShortcutsHeight(height);
      }
    }
  }, [quickResponses, showShortcuts, isTransitioning]);
  
  // Add a resize observer to handle content changes
  useEffect(() => {
    if (!shortcutsRef.current) return;
    
    const resizeObserver = new ResizeObserver(entries => {
      if (showShortcuts && !isTransitioning) {
        const height = entries[0].contentRect.height;
        setShortcutsHeight(height);
      }
    });
    
    resizeObserver.observe(shortcutsRef.current);
    
    return () => {
      resizeObserver.disconnect();
    };
  }, [showShortcuts, isTransitioning]);

  const getQuickResponses = useCallback(async (state) => {
    console.log(`Getting quick responses for state: ${state}`);

    // Check if we should use dynamic shortcuts based on conversation progress
    const shouldUseDynamicShortcuts = messages.length > 2;
    
    if (shouldUseDynamicShortcuts) {
      try {
        // Generate dynamic shortcuts based on conversation context
        const dynamicOptions = await generateDynamicShortcuts({
          messages,
          currentState: state,
          userInfo,
          sendMessage
        });
        
        console.log('Generated dynamic shortcuts:', dynamicOptions);
        
        // Return the text values of the generated shortcuts
        return dynamicOptions.map(option => option.text);
      } catch (error) {
        console.error('Error generating dynamic shortcuts:', error);
        // Fall back to predefined options if there's an error
      }
    }
    
    // Fallback to predefined options (existing switch statement)
    switch (state) {
      case CONVERSATION_STATES.INITIAL:
        return [
          "I have questions",
          "Sure, get started",
        ];
        
      case CONVERSATION_STATES.QUESTIONS:
        return [
          "Who/what are you?",
          "What does this app do?",
          "What happens to my data?",
          "Nevermind, let's get started",
        ];
    
      case CONVERSATION_STATES.VALUE_EXPLANATION:
        return [
          "Tell me more",
          "Can I trust you?",
          "Ok, get started"
        ];
    
      case CONVERSATION_STATES.USER_TYPE:
        return [
          "My family",
          "A friend",
        ];
        
      case CONVERSATION_STATES.PREGNANCY_STATUS:
        return [
          "Yes",
          "No"
        ];
      
      case CONVERSATION_STATES.DATE_COLLECTION:
        return []; // Empty array to hide quick responses for date input
      
      case CONVERSATION_STATES.FIRST_BABY:
        return [
          "This is my first baby",
          "I have other children"
        ];
    
      default:
        return [];
    }
  }, [messages, userInfo, sendMessage]);
  

  const isStepActive = (step) => {
    switch (step) {
      case 'welcome':
        return onboardingState === 'initial' || onboardingState === 'value_explanation';
      case 'about':
        return ['user_type', 'pregnancy_status', 'baby_birthday', 'due_date', 'first_baby', 'collecting_due_date'].includes(onboardingState);
      case 'categories':
        return onboardingState === 'categories';
      case 'sharing':
        return onboardingState === 'sharing';
      default:
        return false;
    }
  };

  /**
 * Classifies whether a user message is confirming understanding
 * @param {string} message - User's message
 * @returns {Promise<boolean>} - Whether the message is a confirmation
 */
const isConfirmationResponse = async (message) => {
  // Only attempt classification if we're awaiting confirmation
  if (!awaitingConfirmation) return false;
  
  const intent = await classifyIntent(
    message, 
    'confirmation response', 
    ['confirmation', 'not_confirmation']
  );
  
  return intent === 'confirmation';
};

/**
 * Detects user concerns or questioned assumptions
 * @param {string} message - User's message
 * @returns {Promise<object>} Detected concerns and assumptions
 */
  const detectUserConcerns = async (message) => {
    // First, check if this is questioning an assumption
    const assumptionIntent = await classifyIntent(
      message,
      'questioned assumption',
      ['questioning_spreadsheets', 'questioning_information_collection', 'questioning_purpose', 'no_questioning']
    );
    
    let questionedAssumption = null;
    if (assumptionIntent !== 'no_questioning') {
      questionedAssumption = assumptionIntent.replace('questioning_', '');
    }
    
    // Second, check for privacy concerns
    const privacyIntent = await classifyIntent(
      message,
      'privacy concern',
      ['privacy_concern', 'no_privacy_concern']
    );
    
    const concerns = [];
    if (privacyIntent === 'privacy_concern') {
      concerns.push('privacy');
    }
    
    // Add the questioned assumption as a concern type as well
    if (questionedAssumption) {
      concerns.push('questioning_assumptions');
    }
    
    return { concerns, questionedAssumption };
  };

  /**
   * Detects if a bot response contains a confirmation question
   * @param {string} response - Bot's response
   * @returns {Promise<boolean>} Whether the response asks for confirmation
   */
  const responseContainsConfirmation = async (response) => {
    const intent = await classifyIntent(
      response,
      'confirmation question',
      ['asks_confirmation', 'no_confirmation_question']
    );
    
    return intent === 'asks_confirmation';
  };

  const pastelColors = [
    '#FFD1DC', // Light Pink
    '#B0E0E6', // Light Blue
    '#CCCCFF', // Lavender
    '#AAFFC3', // Mint
    '#FFFACD', // Light Yellow
    '#FFDAB9'  // Peach
  ];

  /**
   * Checks if message is a greeting
   * @param {string} message - User's message
   * @returns {boolean} True if message is a greeting
   */
  const isGreeting = (message) => {
    const greetings = ['hi', 'hello', 'hey', 'hei', 'hola'];
    return greetings.includes(message.toLowerCase().trim());
  };

    /**
   * Enhanced check for yes/no responses and their variations
   * @param {string} message - User's message
   * @returns {object} Object containing isYes and isNo boolean flags
   */
    const checkYesNo = (message) => {
      const input = message.toLowerCase().trim();
      
      // Common variations and misspellings of "yes"
      const yesVariations = [
        'yes', 'yeah', 'yep', 'yup', 'sure', 'ok', 'okay', 'kay',
        'k', 'ya', 'yah', 'ye', 'es', 'ys', 'yess', 'yesh', 'yep',
        'affirmative', 'definitely', 'absolutely', 'indeed'
      ];
  
      // Common variations and misspellings of "no"
      const noVariations = [
        'no', 'nope', 'nah', 'na', 'nay', 'not', 'n', 'nou',
        'negative', 'never', 'noo', 'noup', 'nop'
      ];
  
      // Use fuzzy matching for better recognition
      const isYes = yesVariations.some(variation => {
        // Exact match
        if (input === variation) return true;
        // Levenshtein distance of 1 for minor typos
        if (levenshteinDistance(input, variation) <= 1) return true;
        return false;
      });
  
      const isNo = noVariations.some(variation => {
        if (input === variation) return true;
        if (levenshteinDistance(input, variation) <= 1) return true;
        return false;
      });
  
      return { isYes, isNo };
    };

    /**
     * Enhanced date parsing with support for multiple natural language formats
     * @param {string} input - User's date input
     * @returns {Date|null} Parsed date object or null if invalid
     */
    const parseDate = (input) => {
      try {
        // Try direct date parsing first
        let date = new Date(input);
        if (!isNaN(date.getTime())) {
          return date;
        }
        
        // Try common patterns
        const patterns = [
          // Month and year (January 2024)
          { 
            regex: /\b(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)[a-z]* (?:of )?\s*(\d{4})\b/i, 
            process: (m) => new Date(`${m[1]} 15, ${m[2]}`) 
          },
          
          // Season and year (Spring 2024)
          { 
            regex: /\b(spring|summer|fall|autumn|winter) (?:of )?\s*(\d{4})\b/i, 
            process: (m) => {
              const seasonMonths = {
                spring: 4, summer: 7, fall: 10, autumn: 10, winter: 1
              };
              return new Date(parseInt(m[2]), seasonMonths[m[1].toLowerCase()] - 1, 15);
            }
          },
          
          // Early/mid/late month
          { 
            regex: /\b(early|mid|late) (jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)[a-z]*\b/i, 
            process: (m) => {
              const dayMap = { early: 5, mid: 15, late: 25 };
              const now = new Date();
              return new Date(`${m[2]} ${dayMap[m[1].toLowerCase()]}, ${now.getFullYear()}`);
            }
          },
          
          // Month and day (January 15)
          { 
            regex: /\b(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)[a-z]* (\d{1,2})(?:st|nd|rd|th)?\b/i, 
            process: (m) => {
              const now = new Date();
              return new Date(`${m[1]} ${m[2]}, ${now.getFullYear()}`);
            }
          },
          
          // Just month (January)
          { 
            regex: /\b(jan|feb|mar|apr|may|jun|jul|aug|sep|oct|nov|dec)[a-z]*\b/i, 
            process: (m) => {
              const now = new Date();
              return new Date(`${m[1]} 15, ${now.getFullYear()}`);
            }
          }
        ];
        
        for (const pattern of patterns) {
          const match = input.match(pattern.regex);
          if (match) {
            return pattern.process(match);
          }
        }
        
        return null;
      } catch (error) {
        console.error('Date parsing error:', error);
        return null;
      }
    };
  
    /**
     * Calculate Levenshtein distance between two strings
     * @param {string} a - First string
     * @param {string} b - Second string
     * @returns {number} Edit distance between strings
     */

    const levenshteinDistance = (a, b) => {
      if (a.length === 0) return b.length;
      if (b.length === 0) return a.length;
  
      const matrix = Array(b.length + 1).fill().map(() => Array(a.length + 1).fill(0));
  
      for (let i = 0; i <= a.length; i++) matrix[0][i] = i;
      for (let j = 0; j <= b.length; j++) matrix[j][0] = j;
  
      for (let j = 1; j <= b.length; j++) {
        for (let i = 1; i <= a.length; i++) {
          const cost = a[i - 1] === b[j - 1] ? 0 : 1;
          matrix[j][i] = Math.min(
            matrix[j - 1][i] + 1,
            matrix[j][i - 1] + 1,
            matrix[j - 1][i - 1] + cost
          );
        }
      }
  
      return matrix[b.length][a.length];
    };
  
    /**
     * Enhanced unclear response detection
     * @param {string} message - User's message
     * @returns {boolean} True if response is unclear
     */
    const isUnclearResponse = (message) => {
      const input = message.trim();
      
      // Check for yes/no responses first
      const { isYes, isNo } = checkYesNo(input);
      if (isYes || isNo) return false;
  
      // More sophisticated unclear response criteria
      const isUnclear = (
        // Too short (excluding yes/no variations)
        input.length <= 2 ||
        // Single letter
        input.match(/^[a-z]$/i) ||
        // Only punctuation
        input.match(/^[.,!?]+$/) ||
        // Random characters
        input.match(/^[^a-z0-9\s]+$/i) ||
        // Just spaces or empty
        input.match(/^\s*$/) ||
        // Repeated characters (e.g., "hhhh")
        input.match(/^(.)\1+$/)
      );
  
      return isUnclear;
    };
  
    /**
     * Enhanced unclear response handling with more context-aware responses
     * @returns {string} Appropriate response for the current context
     */
    const handleUnclearResponse = () => {
      const count = userInfo.unclearResponseCount + 1;
      setUserInfo(prev => ({
        ...prev,
        unclearResponseCount: count,
        lastUnclearResponse: new Date().toISOString()
      }));
  
      // Context-aware responses based on onboarding state
      const stateResponses = {
        initial: [
          "I want to make sure I understand you correctly! Would you like to: 1. Learn how the checklist works, or 2. Start personalizing your checklist? 😊",
          "Let me make this simpler! Just type '1' to learn more, or '2' to start personalizing your checklist. Which would you prefer?",
          "I see we're having trouble communicating. Let's start with something simple - would you like me to show you how the checklist works? Just say 'yes' or 'no'."
        ],
        collecting_due_date: [
          "I need your due date to organize everything perfectly. You can give me an approximate date if you're not sure - something like 'July 2025' or 'early August' works too! When is your little one due?",
          "Let's focus on your due date - this helps me arrange everything in the right order. When are you expecting? Even a rough estimate helps!",
          "I just need to know when you're expecting - a simple month and year is fine! When is your baby due?"
        ],
        asking_name: [
          "I'd love to make this more personal! What name should I call you?",
          "Could you share your first name with me? This helps make our conversation more friendly!",
          "Just let me know what to call you - any name you prefer is perfect!"
        ]
      };
  
      // Get responses for current state or use default responses
      const responses = stateResponses[onboardingState] || stateResponses.initial;
      return formatMessage(responses[Math.min(count - 1, responses.length - 1)]);
    };
  

  /**
   * Handles repeated greetings with increasingly direct responses
   * @returns {string|null} Appropriate response or null for first greeting
   */
  const handleGreeting = () => {
    const count = greetingCount + 1;
    setGreetingCount(count);

    if (count === 1) {
      return null; // First greeting uses normal welcome flow
    } else if (count === 2) {
      return "I notice we've said hello - would you like to start personalizing your checklist? I just need your due date to begin! 😊";
    } else {
      return "Let's get started! When is your little one due? This will help me organize everything perfectly for you. 😊";
    }
  };

  /**
   * Handles initial welcome message when chat starts
   * Uses predefined welcome message for consistency
   */
  /**
   * Formats messages for consistent display
   * Handles numbered lists with proper formatting
   * @param {string} message - The message to format
   * @returns {string} Formatted message
   */
  const formatMessage = (message) => {
    // If message is an object (for choice buttons), return as is
    if (typeof message === 'object' && message !== null) {
      return message;
    }
  
    // Handle string messages
    if (typeof message === 'string') {
      // Remove HTML tags first
      message = message.replace(/<[^>]*>/g, '');
      
      // Then handle the existing numbered list formatting
      if (message.match(/\d+\./)) {
        const parts = message.split(/((?:\d+\.)[^.!?]*[.!?])/g);
        let introduction = parts[0];
        let numberedItems = [];
        let closingStatement = '';
        
        for (let i = 1; i < parts.length; i++) {
          const part = parts[i].trim();
          if (part.match(/^\d+\./)) {
            numberedItems.push(part);
          } else if (part && !part.match(/^\s*$/)) {
            closingStatement = part;
          }
        }
        
        let formattedMessage = introduction.trim();
        if (numberedItems.length > 0) {
          formattedMessage += '\n\n' + numberedItems
            .map(item => `    ${item}`)
            .join('\n');
        }
        if (closingStatement) {
          formattedMessage += '\n\n' + closingStatement;
        }
        return formattedMessage.trim();
      }
      return message;
    }
  
    // Return empty string for any other type
    return '';
  };
  
  const ShortcutButtons = ({ onSelect, isSelected }) => {
    const [mounted, setMounted] = useState(false);
    const [opacity, setOpacity] = useState(0);
    
    useEffect(() => {
      if (!mounted) {
        setTimeout(() => {
          setMounted(true);
          setOpacity(1);
        }, 1000);
      }
    }, [mounted]);
  
    const getShortcutOptions = () => {
      switch (currentState) {
        case CONVERSATION_STATES.INITIAL:
          return [
            { text: "Learn more", value: "learn" },
            { text: "Get started", value: "start" }
          ];
        case CONVERSATION_STATES.VALUE_EXPLANATION:
          return [
            { text: "Tell me more", value: "more" },
            { text: "Start now", value: "start" }
          ];
        case CONVERSATION_STATES.USER_TYPE:
          return [
            { text: "I'm expecting", value: "expecting" },
            { text: "Helping someone", value: "helping" }
          ];
        case CONVERSATION_STATES.PREGNANCY_STATUS:
          return [
            { text: "Baby is born", value: "born" },
            { text: "Still expecting", value: "notBorn" }
          ];
        case CONVERSATION_STATES.FIRST_BABY:
          return [
            { text: "First baby", value: "firstBaby" },
            { text: "Have children", value: "notFirstBaby" }
          ];
        default:
          return [];
      }
    };
  
    const options = getShortcutOptions();
    
    if (isSelected || options.length === 0) return null;
  
    return (
      <div style={{
        opacity,
        overflow: 'hidden',
        width: '100%',
        textAlign: 'center',
        position: 'absolute',
        bottom: '50px',
        left: '0',
        padding: '16px',
        backgroundColor: '#ffffff',
        transition: 'opacity 1000ms cubic-bezier(0.4, 0, 0.2, 1)'
      }}>
        <div style={{ fontSize: '12px', color: '#666666', marginBottom: '8px' }}>
          Shortcuts:
        </div>
          <div style={{ 
            display: 'flex', 
            flexWrap: 'wrap', // Enable wrapping to multiple lines
            gap: '8px', 
            justifyContent: 'center',
            maxWidth: '500px', // Constrain max width
            margin: '0 auto',
            padding: '0 16px' // Add padding
          }}>
          {options.map((option, index) => (
            <button
              key={index}
              onClick={() => {
                setOpacity(0);
                setTimeout(() => onSelect(option.value), 300);
              }}
              style={{
                backgroundColor: index === 0 ? '#FFD1DC' : '#B0E0E6',
                border: 'none',
                color: '#333333',
                minWidth: '120px',
                maxWidth: '220px',
                padding: '0 16px',
                height: '36px',
                borderRadius: '18px',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                cursor: 'pointer',
                transition: 'all 200ms ease-in-out',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flex: '1 1 auto'
              }}
              onMouseEnter={e => {
                e.target.style.backgroundColor = index === 0 ? '#FFB6C1' : '#87CEEB';
                e.target.style.transform = 'translateY(-1px)';
              }}
              onMouseLeave={e => {
                e.target.style.backgroundColor = index === 0 ? '#FFD1DC' : '#B0E0E6';
                e.target.style.transform = 'translateY(0)';
              }}
            >
              {option.text}
            </button>
          ))}
        </div>
      </div>
    );
  };
  
  
  const MessageContent = ({ message }) => {
    return (
      <div>
      <ReactMarkdown>{message.content}</ReactMarkdown>
      </div>
    );
  };
  
  const ChatMessages = ({ messages, quickResponses, onQuickResponseClick }) => {
    return (
      <div className="flex flex-col gap-3">
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              marginBottom: '12px',
              padding: '12px 16px',
              borderRadius: '8px',
              maxWidth: '80%',
              backgroundColor: message.type === 'user' ? '#e6f7ff' : '#f5f5f5',
              marginLeft: message.type === 'user' ? 'auto' : '0',
              color: '#000000',
              whiteSpace: 'pre-line',
              fontSize: '14px',
              lineHeight: 1.5
            }}
          >
            <MessageContent message={message} />
          </div>
        ))}
        
        {quickResponses && quickResponses.length > 0 && (
          <div style={{ 
            display: 'flex', 
            flexWrap: 'wrap', 
            gap: '8px',
            marginTop: '12px' 
          }}>
            {quickResponses.map((response, index) => (
              <button
                key={index}
                onClick={() => onQuickResponseClick(response)}
                style={{
                  backgroundColor: '#f5f5f5',
                  border: '1px solid #e5e7eb',
                  borderRadius: '16px',
                  padding: '6px 12px',
                  fontSize: '14px',
                  color: '#374151',
                  cursor: 'pointer',
                  transition: 'all 0.2s',
                  ':hover': {
                    backgroundColor: '#e5e7eb'
                  }
                }}
              >
                {response}
              </button>
            ))}
          </div>
        )}
      </div>
    );
  };
  
  const handleInitialMessage = async () => {
    try {
      setIsTyping(true);
      
      await addBotMessage(`Hi ${state.name || 'there'} 👋 Welcome to me-search!`, 1000);
      await addBotMessage("I'm your AI assistant, here to help create a personalized checklist for baby preparation. Would you like to learn more about how I can help, or shall we get started?", 1000);
      
      const initialResponses = getQuickResponses(CONVERSATION_STATES.INITIAL);
      setQuickResponses(initialResponses);
      
      transitionTo(CONVERSATION_STATES.INITIAL);
      
      // Set the flag after initial welcome is shown
      setInitialWelcomeShown(true);
    } catch (error) {
      console.error('Error in initial message:', error);
    } finally {
      setIsTyping(false);
    }
  };
  
  const getNextState = (currentValue, currentState) => {
    switch (currentState) {
      case CONVERSATION_STATES.INITIAL:
        if (currentValue === 'have_questions') {
          return CONVERSATION_STATES.QUESTIONS;
        } else if (currentValue === 'learn' || currentValue === 'more' || currentValue === 'what_is_this') {
          return CONVERSATION_STATES.VALUE_EXPLANATION;
        } else if (currentValue === 'start') {
          return CONVERSATION_STATES.USER_TYPE;
        }
        return currentState;
        
      // Add handling for QUESTIONS state
      case CONVERSATION_STATES.QUESTIONS:
        if (currentValue === 'start') {
          return CONVERSATION_STATES.USER_TYPE;
        }
        // Stay in questions state for other actions
        return currentState;
  
      case CONVERSATION_STATES.VALUE_EXPLANATION:
        if (currentValue === 'start') {
          return CONVERSATION_STATES.USER_TYPE;
        }
        return currentState;
  
      case CONVERSATION_STATES.USER_TYPE:
        if (currentValue === 'expecting' || currentValue === 'helping') {
          return currentValue === 'helping' ? 
            CONVERSATION_STATES.COMPLETED : 
            CONVERSATION_STATES.PREGNANCY_STATUS;
        }
        return currentState;
  
      case CONVERSATION_STATES.PREGNANCY_STATUS:
        if (currentValue === 'born' || currentValue === 'notBorn') {
          return CONVERSATION_STATES.FIRST_BABY;
        }
        return currentState;
  
      case CONVERSATION_STATES.FIRST_BABY:
        if (currentValue === 'firstBaby' || currentValue === 'notFirstBaby') {
          return CONVERSATION_STATES.COMPLETED;
        }
        return currentState;
  
      default:
        return currentState;
    }
  };

  const handleOptionClick = async (value, originalText) => {
    // Create a unique message ID
    const messageId = Date.now();
    
    // Add to processed set to prevent duplication
    setProcessedMessageIds(prev => new Set([...prev, messageId]));
    
    // Clear shortcuts immediately to prevent multiple clicks
    setShowShortcuts(false);
    setIsExpanding(false);
    setQuickResponses([]);
    
    // Add analytics tracking for button clicks
    try {
      console.log(`Button clicked: ${originalText} (value: ${value})`);
      // You could add proper analytics tracking here
    } catch (error) {
      console.error("Analytics error:", error);
      // Non-critical error, continue execution
    }
  
    // Add the actual button text to chat with the unique ID
    addMessage({
      id: messageId,
      type: 'user',
      content: originalText || value
    });
  
    // Declare mappedValue at this outer scope so it's available throughout the function
    // including the finally block
    let mappedValue = value;
    
    try {
      setIsTyping(true);
      
      // If a predefined button was clicked, we can use the value directly
      // Otherwise, classify the intent based on the current state
      if (!value || value === originalText) {
        // Use the appropriate intent classifier based on current state
        switch (currentState) {
          case CONVERSATION_STATES.INITIAL:
          case CONVERSATION_STATES.VALUE_EXPLANATION:
            mappedValue = await getActionIntent(originalText);
            break;
          case CONVERSATION_STATES.USER_TYPE:
            mappedValue = await getUserTypeIntent(originalText);
            break;
          case CONVERSATION_STATES.PREGNANCY_STATUS:
            mappedValue = await getBabyStatusIntent(originalText);
            break;
          case CONVERSATION_STATES.FIRST_BABY:
            mappedValue = await getFirstBabyIntent(originalText);
            break;
          // Add other cases as needed
        }
      }
  
      // *** KEY FIX: Update state BEFORE handling specific cases ***
      // This ensures that any state transitions within specific handlers 
      // will still have their shortcuts properly updated
      updateConversationStateAfterResponse(mappedValue);
      
      // Handle QUESTIONS state responses
      if (currentState === CONVERSATION_STATES.QUESTIONS) {
        await handleQuestionsStateResponse(mappedValue, originalText);
        return;
      }
  
      // Handle privacy and trust questions from any state
      if (mappedValue === 'privacy_question') {
        await handlePrivacyQuestion(originalText);
        return;
      }
      
      if (mappedValue === 'trust_question') {
        await handleTrustQuestion(originalText);
        return;
      }
  
      // Handle "I have questions" option
      if (mappedValue === 'have_questions') {
        transitionTo(CONVERSATION_STATES.QUESTIONS);
        
        // *** ADDITIONAL FIX: Explicitly update shortcuts for this path ***
        const questionResponses = getQuickResponses(CONVERSATION_STATES.QUESTIONS);
        setQuickResponses(questionResponses);
        
        const response = await sendMessage(originalText, {
          systemPrompt: generateSystemPrompt + '\n' + `
            Stage: Questions mode
            Goal: Address user's initial questions before proceeding
            Context: User wants to learn more before committing
            
            Focus points:
            - Answer questions about the app's purpose
            - Explain how it helps with baby preparation
            - Address common concerns
            
            Rules:
            1. Be concise but informative
            2. Maximum 3 sentences
            3. Use warm, helpful tone
            
            End with: Would you like to know more about a specific topic, or are you ready to start?`,
          temperature: 0.7,
          previousMessages: messages.map(msg => ({
            role: msg.type === 'user' ? 'user' : 'assistant',
            content: msg.content
          }))
        });
        await addBotMessage(response);
        return;
      }
  
      // Handle app explanation questions
      if (mappedValue === 'what_is_this' || mappedValue === 'app_purpose') {
        await handleAppExplanation(originalText);
        return;
      }
  
      // Handle feature explanation flow
      if (mappedValue === 'more' || mappedValue === 'learn') {
        await handleFeatureExplanation(originalText);
        return;
      }
  
      // Process user type responses
      if (mappedValue === 'expecting' || mappedValue === 'helping') {
        await handleUserTypeResponse(originalText, mappedValue);
        return;
      }
  
      // Process pregnancy/baby status responses
      if (['born', 'notBorn', 'firstBaby', 'notFirstBaby'].includes(mappedValue)) {
        await handleStatusResponses(mappedValue, originalText);
        return;
      }
  
      // Handle start personalizing flow
      if (mappedValue === 'start') {
        await handleStartPersonalizing(originalText);
        return;
      }
  
      // Handle all other responses with generic approach
      await handleGenericResponse(originalText);
  
    } catch (error) {
      console.error('Error in handleOptionClick:', error);
      
      // Different error messages based on error type
      let errorMessage = "I apologize, but I'm having trouble responding right now. Could you please try again?";
      
      if (error.name === 'NetworkError' || error.message?.includes('network')) {
        errorMessage = "It seems we're having connection issues. Please check your internet connection and try again.";
      } else if (error.message?.includes('timeout')) {
        errorMessage = "The request took too long to process. Please try again in a moment.";
      } else if (error.message?.includes('authentication') || error.message?.includes('auth')) {
        errorMessage = "There was an authentication issue. Please refresh the page and try again.";
      }
      
      await addBotMessage(errorMessage);
    } finally {
      setIsTyping(false);
      
    }
  };
  
  // Helper function to map button text to standardized values
  const mapOptionValue = (value, originalText) => {
    if (!originalText) return value;
    
    const textToValueMap = {
      // Initial & Questions state
      "I have questions": 'have_questions',
      "Sure, get started": 'start',
      "Who/what are you?": 'who_are_you',
      "What does this app do?": 'app_purpose',
      "What happens to my data?": 'privacy_question',
      "Nevermind, let's get started": 'start',
      
      // Value explanation state
      "Tell me more": 'more',
      "Can I trust you?": 'trust_question',
      "Ok, get started": 'start',
      
      // User type state
      "I'm expecting": 'expecting',
      "My family": 'expecting',  // Add this mapping
      "I'm not expecting": 'helping',
      "Helping someone": 'helping',
      "A friend": 'helping',     // Add this mapping
      
      // Pregnancy status state
      "Yes": 'born',
      "No": 'notBorn',
      "Baby is born": 'born',
      "Still expecting": 'notBorn',
      
      // First baby state
      "This is my first baby": 'firstBaby',
      "I have other children": 'notFirstBaby',
      "First baby": 'firstBaby',
      "Have children": 'notFirstBaby'
    };
    
    return textToValueMap[originalText] || value;
  };
  

  const classifyIntent = async (userMessage, intentType, options = []) => {
    // Make sure options is an array of valid choices
    const validOptions = Array.isArray(options) && options.length > 0 
      ? options.join('" or "') 
      : 'appropriate category';
    
    let systemPrompt = `
      You are a precise intent classifier for a baby preparation app.
      
      Given the user message below, classify it as exactly ONE of the following types: "${validOptions}".
      
      Analyze the message thoroughly to understand the user's true intent, not just keywords.
      
      Respond with ONLY the single matching classification word, nothing else.
      
      User intent type being classified: ${intentType}
    `;
    
    // Add specific classification guidelines based on intent type
    if (intentType === 'confirmation response') {
      systemPrompt += `
        Classification guidelines:
        - "confirmation": User is confirming they understand, agree, or that the explanation makes sense. Look for words like "yes", "makes sense", "understand", "got it", "clear", etc.
        - "not_confirmation": User is not clearly confirming understanding or is asking another question.
      `;
    } else if (intentType === 'questioned assumption') {
      systemPrompt += `
        Classification guidelines:
        - "questioning_spreadsheets": User is specifically questioning why you mentioned spreadsheets or asking what spreadsheets you're referring to.
        - "questioning_information_collection": User is asking why you need certain information or questioning the purpose of data collection.
        - "questioning_purpose": User is asking about the general purpose of the app or conversation.
        - "no_questioning": User is not questioning any assumptions.
      `;
    } else if (intentType === 'confirmation question') {
      systemPrompt += `
        Classification guidelines:
        - "asks_confirmation": The message asks if something makes sense, is clear, or is helpful. Look for phrases like "does that make sense?", "is that clear?", "does this help?", etc.
        - "no_confirmation_question": The message does not ask for confirmation of understanding.
      `;
    }
  
    const response = await sendMessage(userMessage, {
      systemPrompt: systemPrompt,
      temperature: 0.1 // Low temperature for consistent results
    });
    
    return response.trim().toLowerCase();
  };

  // User type classification (expecting vs helping)
const getUserTypeIntent = async (message) => {
  return classifyIntent(message, 'user role', ['expecting', 'helping']);
};

// Baby status classification (born vs expecting)
const getBabyStatusIntent = async (message) => {
  return classifyIntent(message, 'baby status', ['born', 'expecting']);
};

// First baby classification (first baby vs has children)
const getFirstBabyIntent = async (message) => {
  return classifyIntent(message, 'parenting experience', ['firstBaby', 'notFirstBaby']);
};

// General action intent (learn more vs start)
const getActionIntent = async (message) => {
  return classifyIntent(message, 'user action', ['learn', 'start']);
};

  // Helper for handling Questions state options
  const handleQuestionsStateResponse = async (mappedValue, originalText) => {
    let contextPrompt = '';
    
    switch (mappedValue) {
      case 'who_are_you':
        contextPrompt = `
          Stage: Explaining assistant identity
          Goal: Introduce the AI assistant's purpose
          Context: User wants to know who/what the assistant is
          
          Focus points:
          - Explain you're an AI assistant for baby preparation
          - Highlight your purpose of guiding through the checklist creation
          - Mention you're designed to provide personalized recommendations
          - Emphasize your supportive, non-judgmental approach
          
          Rules:
          1. Be warm and personable
          2. Maximum 3 sentences
          3. End with a question about what else they'd like to know`;
        break;
        
      case 'app_purpose':
        contextPrompt = `
          Stage: Explaining app purpose
          Goal: Clarify what the app does
          Context: User wants to understand the app's functionality
          
          Focus points:
          - Explain the personalized checklist creation
          - Highlight the product recommendation features
          - Mention sharing capabilities
          - Emphasize the organization and tracking benefits
          
          Rules:
          1. Be clear and specific
          2. Maximum 3 sentences
          3. End with a question about what specific aspect interests them`;
        break;
        
      default:
        contextPrompt = `
          Stage: General question answering
          Goal: Provide helpful information
          Context: User has a specific question
          
          Focus points:
          - Address the question directly
          - Provide factual information
          - Be supportive and encouraging
          
          Rules:
          1. Be concise but thorough
          2. Maximum 3 sentences
          3. End with an offer to answer more questions or proceed`;
    }
    
    const response = await sendMessage(originalText, {
      systemPrompt: generateSystemPrompt + '\n' + contextPrompt,
      temperature: 0.7,
      previousMessages: messages.map(msg => ({
        role: msg.type === 'user' ? 'user' : 'assistant',
        content: msg.content
      }))
    });
    
    await addBotMessage(response);
    
    // After answering a question, stay in QUESTIONS state unless explicitly moved
    if (mappedValue === 'start') {
      transitionTo(CONVERSATION_STATES.USER_TYPE);
    }
  };
  
  // Helper for handling privacy questions
  const handlePrivacyQuestion = async (originalText) => {
    const response = await sendMessage(originalText, {
      systemPrompt: generateSystemPrompt + '\n' + `
        Stage: Privacy Concern
        Goal: Address user's data privacy concerns
        Context: User wants to know about data handling
        
        Focus points:
        - Explain privacy-first approach
        - Clarify that all information stays private
        - Emphasize user control over sharing
        - Mention that we're a small team not focused on monetization
        
        Rules:
        1. Be transparent and reassuring
        2. Maximum 3 sentences
        3. Use warm, trustworthy tone
        
        End with: Would you like to learn more about our features or start creating your checklist?`,
      temperature: 0.7,
      previousMessages: messages.map(msg => ({
        role: msg.type === 'user' ? 'user' : 'assistant',
        content: msg.content
      }))
    });
    
    await addBotMessage(response);
    
    // Add privacy concern to context state
    setContextState(prev => ({
      ...prev,
      expressedConcerns: [...new Set([...prev.expressedConcerns, 'privacy'])],
      privacyPreferenceLevel: 'concerned'
    }));
  };
  
  // Helper for handling trust questions
  const handleTrustQuestion = async (originalText) => {
    const response = await sendMessage(originalText, {
      systemPrompt: generateSystemPrompt + '\n' + `
        Stage: Trust Concern
        Goal: Build user's trust in the app
        Context: User wants reassurance
        
        Focus points:
        - Explain our approach and values
        - Mention our commitment to users' wellbeing
        - Emphasize data control and transparency
        - Mention our focus on helping parents
        
        Rules:
        1. Be honest and reassuring
        2. Maximum 3 sentences
        3. Use warm, authentic tone
        
        End with: Would you like to start creating your personalized checklist now?`,
      temperature: 0.7,
      previousMessages: messages.map(msg => ({
        role: msg.type === 'user' ? 'user' : 'assistant',
        content: msg.content
      }))
    });
    
    await addBotMessage(response);
    
    // Add trust concern to context state
    setContextState(prev => ({
      ...prev,
      expressedConcerns: [...new Set([...prev.expressedConcerns, 'trust'])],
    }));
  };
  
  // Helper for handling app explanation
  const handleAppExplanation = async (originalText) => {
    const contextPrompt = `
      Stage: Initial App Explanation
      Goal: Explain what this app is about
      Context: User wants to understand the purpose of the app
      
      Focus points:
      - This is an AI-powered baby preparation assistant
      - The app helps create personalized checklists for baby items
      - Users can organize their preparation by timeframe and priorities
      - Recommendations can be shared with friends and family
      - Privacy is respected throughout the experience
      
      Rules:
      1. Focus on explaining the core purpose and value
      2. Maximum 2-3 sentences
      3. Use welcoming, enthusiastic tone
      4. Include one relevant emoji
      
      End with: Would you like to learn about specific features or start personalizing your checklist?`;
      
    const response = await sendMessage(originalText, {
      systemPrompt: generateSystemPrompt + '\n' + contextPrompt,
      temperature: 0.7,
      previousMessages: messages.map(msg => ({
        role: msg.type === 'user' ? 'user' : 'assistant',
        content: msg.content
      }))
    });
    
    await addBotMessage(response);
    transitionTo(CONVERSATION_STATES.VALUE_EXPLANATION);
    
    // Add topic to context state
    setContextState(prev => ({
      ...prev,
      topicsDiscussed: new Set([...prev.topicsDiscussed, 'app_purpose']),
      currentInterest: 'app_purpose'
    }));
  };
  
  // Helper for handling feature explanations
  const handleFeatureExplanation = async (originalText) => {
    transitionTo(CONVERSATION_STATES.VALUE_EXPLANATION);
    
    const features = [
      {
        id: 'organization',
        contextPrompt: `
          Stage: Organization Features
          Goal: Explain checklist organization and tracking
          Context: First feature explanation
          
          Focus points:
          - Category organization by product type
          - Visual progress tracking
          - Easy to mark items complete
          - Everything in one organized place
          
          Rules:
          1. Focus on reducing overwhelm through organization
          2. Maximum 2-3 sentences
          3. Use encouraging tone
          4. Include one relevant emoji
          
          End with: Would you like to hear about product selection?`
      },
      {
        id: 'products',
        contextPrompt: `
          Stage: Product Features
          Goal: Explain product selection and notes
          Context: User understands organization
          
          Focus points:
          - Select products for each category
          - Add personal notes and decisions
          - Skip items you don't need
          - Keep track of your choices
          
          Rules:
          1. Focus on making confident decisions
          2. Maximum 2-3 sentences
          3. Use helpful tone
          4. Include one relevant emoji
          
          End with: Would you like to learn about sharing with friends?`
      },
      {
        id: 'social',
        contextPrompt: `
          Stage: Social Features
          Goal: Explain sharing and community features
          Context: User understands basic features
          
          Focus points:
          - Share your checklist when ready
          - See what worked for other parents
          - Full privacy control
          - Learn from community experience
          
          Rules:
          1. Focus on community benefits while emphasizing privacy
          2. Maximum 2-3 sentences
          3. Use friendly tone
          4. Include one relevant emoji
          
          End with: Would you like to start personalizing your checklist?`
      }
    ];
    
    const unexplainedFeatures = features.filter(f => !explainedFeatures.has(f.id));
    
    if (unexplainedFeatures.length === 0) {
      transitionTo(CONVERSATION_STATES.USER_TYPE);
      const responses = [
        "I've explained all our main features! Would you like to start personalizing your checklist?",
        "Great! I can see you're interested in learning more. The best way to explore now would be to let me personalize everything for you. Shall we get started? 😊",
        "I can tell you're thorough in your research! Now that you know our main features, let's make them work for your specific needs. Ready to begin? 💫"
      ];
      const responseIndex = Math.min(repetitionCount, responses.length - 1);
      setRepetitionCount(prev => prev + 1);
      await addBotMessage(responses[responseIndex]);
      return;
    }
    
    const nextFeature = unexplainedFeatures[0];
    setExplainedFeatures(prev => new Set([...prev, nextFeature.id]));
    
    // Send the message to ChatGPT with the appropriate context
    const response = await sendMessage(originalText, {
      systemPrompt: generateSystemPrompt + '\n' + nextFeature.contextPrompt,
      temperature: 0.7,
      previousMessages: messages.map(msg => ({
        role: msg.type === 'user' ? 'user' : 'assistant',
        content: msg.content
      }))
    });
    
    await addBotMessage(response);
    
    // Add feature to topics discussed
    setContextState(prev => ({
      ...prev,
      topicsDiscussed: new Set([...prev.topicsDiscussed, nextFeature.id]),
      currentInterest: nextFeature.id
    }));
  };

  
  // Helper for handling status responses (pregnancy, first baby)
  const handleStatusResponses = async (mappedValue, originalText) => {
    try {
      // Classify message if needed
      let intent = mappedValue;
      
      if (!['born', 'notBorn', 'firstBaby', 'notFirstBaby'].includes(mappedValue)) {
        if (currentState === CONVERSATION_STATES.PREGNANCY_STATUS) {
          intent = await getBabyStatusIntent(originalText);
        } else if (currentState === CONVERSATION_STATES.FIRST_BABY) {
          intent = await getFirstBabyIntent(originalText);
        }
      }
      
      // Determine which handler to use based on the currentState and classified intent
      if (currentState === CONVERSATION_STATES.PREGNANCY_STATUS || 
          intent === 'born' || intent === 'notBorn') {
        const pregnancyStatusResponse = await handlePregnancyStatus(originalText);
        await addBotMessage(pregnancyStatusResponse);
        transitionTo(CONVERSATION_STATES.DATE_COLLECTION);
      } else if (currentState === CONVERSATION_STATES.FIRST_BABY || 
                intent === 'firstBaby' || intent === 'notFirstBaby') {
        const firstBabyResponseText = await handleFirstBabyStatus(originalText);
        await addBotMessage(firstBabyResponseText);
        transitionTo(CONVERSATION_STATES.COMPLETED);
      } else {
        // Handle unexpected state
        const response = "I'm not sure what information you're trying to provide. Could you clarify or try a different option?";
        await addBotMessage(response);
      }
    } catch (error) {
      console.error('Error in status handling:', error);
      throw error; // Let the main error handler deal with it
    }
  };
  
  // Helper for starting personalization
  const handleStartPersonalizing = async (originalText) => {
    setOnboardingState('user_type');
    transitionTo(CONVERSATION_STATES.USER_TYPE);
    
    // Add this line to prevent repeated greetings
    setInitialWelcomeShown(true);
    
    const response = await sendMessage(originalText, {
      systemPrompt: generateSystemPrompt + '\n' + `
        Stage: Beginning personalization
        Goal: Start gathering user information
        Context: User ready to begin
        
        Rules:
        1. DO NOT greet the user again or say welcome again - they have already been welcomed
        2. Get straight to the point about role determination
        3. If explaining options, do not list them and then immediately ask about them again
        4. Keep the question clear: "Are you preparing for your own baby, or helping someone else?"
        5. Use warm, encouraging tone
        6. Maximum 2 sentences total
        
        End with clear question about their role.`,
      temperature: 0.5,
      previousMessages: messages.map(msg => ({
        role: msg.type === 'user' ? 'user' : 'assistant',
        content: msg.content
      }))
    });
    
    await addBotMessage(response);
  };
  
  // Helper for generic responses
  const handleGenericResponse = async (originalText) => {
    // Try to classify the intent based on current state
    let classifiedIntent = null;
    
    try {
      switch (currentState) {
        case CONVERSATION_STATES.INITIAL:
        case CONVERSATION_STATES.VALUE_EXPLANATION:
          classifiedIntent = await getActionIntent(originalText);
          break;
        case CONVERSATION_STATES.USER_TYPE:
          classifiedIntent = await getUserTypeIntent(originalText);
          break;
        case CONVERSATION_STATES.PREGNANCY_STATUS:
          classifiedIntent = await getBabyStatusIntent(originalText);
          break;
        case CONVERSATION_STATES.FIRST_BABY:
          classifiedIntent = await getFirstBabyIntent(originalText);
          break;
      }
    } catch (error) {
      console.error('Error classifying intent:', error);
    }
    
    // Add intent information to the context if available
    const intentContext = classifiedIntent ? 
      `\nDetected intent: ${classifiedIntent}` : '';
    
    const response = await sendMessage(originalText, {
      systemPrompt: generateSystemPrompt + '\n' + `
        Stage: General interaction
        Goal: Guide user to next step
        Context: Maintaining conversation flow${intentContext}
        
        Rules:
        1. Keep response relevant to current state
        2. Maximum 2-3 sentences
        3. End with clear next step
        4. Use warm, helpful tone`,
      temperature: 0.7,
      previousMessages: messages.map(msg => ({
        role: msg.type === 'user' ? 'user' : 'assistant',
        content: msg.content
      }))
    });
    
    await addBotMessage(response);
    
    // If we classified an intent, update the state based on it
    if (classifiedIntent) {
      const nextState = getNextState(classifiedIntent, currentState);
      if (nextState !== currentState) {
        transitionTo(nextState);
      }
    }
  };
  
  // Helper to update conversation state after any response
  const updateConversationStateAfterResponse = (mappedValue) => {
    // Determine next state based on the value and current state
    const newState = getNextState(mappedValue, currentState);
    
    console.log(`Updating conversation state after response: 
      - Current state: ${currentState}
      - Mapped value: ${mappedValue}
      - New state: ${newState}`);
    
    if (newState !== currentState) {
      try {
        transitionTo(newState);
        const newResponses = getQuickResponses(newState);
        console.log(`Setting quick responses for new state ${newState}:`, newResponses);
        setQuickResponses(newResponses);
      } catch (error) {
        console.error('Error updating conversation state:', error);
        // Fallback to safe options if there's an error
        setQuickResponses(["Continue"]);
      }
    }
  };
  
  
  const updateContextAwareness = (message, response) => {
    // Keywords for different concerns
    const concernKeywords = {
      privacy: ['private', 'secure', 'confidential', 'share', 'data'],
      cost: ['expensive', 'price', 'cost', 'money', 'afford'],
      timeline: ['when', 'timeline', 'schedule', 'due', 'date'],
      overwhelmed: ['overwhelm', 'too much', 'lot to do', 'stress', 'anxiety']
    };

    // Keywords for topics
    const topicKeywords = {
      features: ['feature', 'work', 'do'],
      privacy: ['private', 'secure', 'data'],
      company: ['team', 'company', 'who are you', 'about you'],
      products: ['product', 'recommend', 'suggest']
    };

    // Extract concerns
    const concerns = Object.entries(concernKeywords)
      .filter(([_, keywords]) => 
        keywords.some(keyword => message.toLowerCase().includes(keyword)))
      .map(([concern]) => concern);

    // Extract topics
    const topics = Object.entries(topicKeywords)
      .filter(([_, keywords]) => 
        keywords.some(keyword => message.toLowerCase().includes(keyword)))
      .map(([topic]) => topic);

    // Update state using setContextState
    setContextState(prev => ({
      ...prev,
      expressedConcerns: [...new Set([...prev.expressedConcerns, ...concerns])],
      topicsDiscussed: new Set([...prev.topicsDiscussed, ...topics]),
      currentInterest: topics[0] || prev.currentInterest
    }));

    return { concerns, topics };
  };

  const determineNextStep = (currentValue, contextState) => {
    const { currentState, userInfo } = contextState;
  
    const contextPrompt = `
      Stage: ${currentState}
      Goal: Guide user through feature explanation and personalization
      Current value: ${currentValue}
      User info: ${JSON.stringify(userInfo)}
      
      Focus on these real features:
      1. Checklist organization and progress tracking
      2. Product selection and notes
      3. Social sharing and viewing others' recommendations
      
      Never mention:
      - Timeline organization
      - Time-based recommendations
      - Automatic scheduling
      
      Keep responses focused on:
      - Organization and tracking
      - Making informed product decisions
      - Learning from community experiences`;
  
    return {
      systemPrompt: contextPrompt,
      nextState: getNextState(currentValue, currentState),
      quickResponses: getQuickResponses(getNextState(currentValue, currentState))
    };
  };
  

  const generateContextualOptions = (currentState, concerns, topicsDiscussed) => {
    // Base options that always make sense
    const baseOptions = [
      { text: "Tell me more", value: "more" },
      { text: "Get started", value: "start" }
    ];
  
    // Add context-specific options
    if (concerns.includes('privacy')) {
      baseOptions.unshift({ 
        text: "How is my data protected?", 
        value: "privacy" 
      });
    }
  
    if (concerns.includes('overwhelmed')) {
      baseOptions.unshift({ 
        text: "Break it down for me", 
        value: "simplify" 
      });
    }
  
    // Add topic-specific options
    if (!topicsDiscussed.has('company')) {
      baseOptions.push({ 
        text: "Who are you?", 
        value: "company" 
      });
    }
  
    // Limit to 3 most relevant options
    return baseOptions.slice(0, 3);
  };
  
  const ChoiceButtons = ({ options, onSelect }) => (
    <div style={{ 
      width: '100%', 
      marginTop: '8px', 
      display: 'flex',
      gap: '12px',
      justifyContent: 'center'
    }}>
      {options.map((option, index) => (
        <button
          key={index}
          onClick={() => onSelect(option.value)}
          style={{
            backgroundColor: index === 0 ? '#FFD1DC' : '#B0E0E6',
            border: 'none',
            color: '#333333',
            width: '120px',
            height: '36px',
            borderRadius: '18px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            cursor: 'pointer'
          }}
        >
          {option.text}
        </button>
      ))}
    </div>
  );
  

  /**
   * Saves user information to the backend
   * @param {Object} values - Values to save (e.g., { firstName: 'John' })
   */
  const saveUserInfo = async (updates) => {
    console.log('Attempting to save:', updates);
    try {
      // Format the data in the shape the backend expects
      const attributes = {};
      
      // Map our frontend keys to backend attribute names
      if (updates.userType) {
        attributes.user_type = updates.userType;
      }
      if (updates.pregnancyStatus) {
        attributes.pregnancy_status = updates.pregnancyStatus;
      }
      if (updates.babysBirthday) {
        attributes.birth_date = updates.babysBirthday.toISOString();
      }
      if (updates.dueDate) {
        attributes.due_date = updates.dueDate.toISOString();
      }
      if (updates.isFirstBaby !== undefined) {
        attributes.is_first_baby = updates.isFirstBaby.toString();
      }
      if (updates.childrenCount !== undefined) {
        attributes.children_count = updates.childrenCount.toString();
      }
  
      console.log('Sending to backend:', { attributes });
  
      // Use the createMultipleUserAttributes action
      const response = await dispatch(createMultipleUserAttributes(userData, attributes));
      console.log('Backend response:', response);
  
      // Verify based on the response rather than store state
      const savedFields = [];
      for (const [key, value] of Object.entries(attributes)) {
        const displayKey = key
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
        savedFields.push(`${displayKey}: ${value}`);
      }
  
      addMessage({
        type: 'bot',
        content: `📝 Saved successfully:\n${savedFields.join('\n')}`
      });
  
      return true;
  
    } catch (error) {
      console.error('Save operation failed:', error);
      addMessage({
        type: 'bot',
        content: `❌ Save failed: ${error.message || 'Unknown error'}. Please try again.`
      });
      return false;
    }
  };

  /**
   * Updates the user's name when it's provided in conversation
   * @param {string} name - User's first name
   */
  const handleNameProvided = (name) => {
    const values = {
      firstName: name,
      lastName: userData?.last_name || '', // Preserve existing last name if any
      time_zone: userData?.time_zone || 'UTC' // Include required time_zone field
    };
    saveUserInfo(values);
    setUserInfo(prev => ({
      ...prev,
      name: name
    }));
  };
 
  const handlePersonalizationStart = () => {
    const needName = !userInfo.name;
    const questionCount = needName ? 'three' : 'two';
  
    setOnboardingState('collecting_due_date');
    setIsTyping(false);
  
    return [
      "Great! Let's create a checklist that's perfect for your needs.",
      `I have just ${questionCount} quick questions that will help me create a perfectly tailored checklist for you:
  
  1. Your due date - so I can organize everything according to your timeline
  2. Whether this is your first baby - to adjust recommendations based on your experience${needName ? '\n3. Your name - to make our conversation more personal' : ''}`,
      "Are you ready to start with your due date? 😊"
    ];
  };

    /**
   * Updates category statuses based on user type and responses
   * Handles automatic category marking and note UI visibility
   * 
   * @param {string} statusType - Type of update ('helper' or 'experienced_parent')
   * @returns {Promise<boolean>} Success status of the update
   */
  const updateCategoryStatus = async (statusType) => {
    try {
      console.log('Updating categories for:', statusType);
      
      if (statusType === 'helper') {
        // For helpers, all categories are always enabled - no need to update permissions
        // Just update the user type in Redux
        dispatch(setUserType('helper'));
        return true;
      }
      
      return true;
    } catch (error) {
      console.error('Error updating categories:', error);
      return false;
    }
  };
  
    //Uses our custom hook to manage conversation flow transitions.
    const { 
      currentState, 
      conversationContext, 
      transitionTo, 
      setConversationContext,
      goBack, 
      stateHistory 
    } = useConversationFlow(
      setOnboardingState, 
      setQuickResponses, 
      getQuickResponses
    );

    useEffect(() => {
      // Update quick responses whenever currentState or onboardingState changes
      if (currentState) {
        const fetchResponses = async () => {
          try {
            const responses = await getQuickResponses(currentState);
            setQuickResponses(responses);
          } catch (error) {
            console.error('Error fetching quick responses:', error);
            // Fall back to empty or default responses
            setQuickResponses([]);
          }
        };
        
        fetchResponses();
      }
    }, [currentState, onboardingState, getQuickResponses]);   
  

  /**
   * Handles responses determining user type (expecting vs helping)
   * Updates categories and provides appropriate next steps
   * 
   * @param {string} message - User's message
   * @returns {string} Bot's response message
   */

  const handleUserTypeResponse = async (message, mappedValue) => {
    try {
      // Use the provided mappedValue parameter instead of re-classifying
      // If mappedValue is not provided, then classify the intent
      const userIntent = mappedValue || await getUserTypeIntent(message);
      const isHelping = userIntent === 'helping';
      const userType = isHelping ? 'helping' : 'expecting';
      
      // Save user type
      await dispatch(createMultipleUserAttributes(userData, {
        user_type: userType
      }));
      
      // Get response from ChatGPT based on classified intent
      const contextPrompt = `
        Stage: User type determination
        User type: ${userType}
        Goal: Acknowledge user type and move directly to next appropriate question
        
        Rules:
        1. For expecting users, briefly acknowledge, then IMMEDIATELY ask: "Has your baby been born yet?"
        2. For helping users, briefly acknowledge, then transition to completion
        3. Keep response warm but direct - maximum 2 sentences before asking the next question
        4. Use at most one emoji
        
        DO NOT deviate from this flow with additional questions or options.
      `;

      const response = await sendMessage(message, {
        systemPrompt: generateSystemPrompt + '\n' + contextPrompt,
        temperature: 0.5,
        previousMessages: messages.map(msg => ({
          role: msg.type === 'user' ? 'user' : 'assistant',
          content: msg.content
        }))
      });

      // Add the bot message
      await addBotMessage(response);
      
      // Transition to the next state based on classified intent
      const nextState = isHelping ? CONVERSATION_STATES.COMPLETED : CONVERSATION_STATES.PREGNANCY_STATUS;
      transitionTo(nextState);
      
      // Update quick responses
      const quickResponses = getQuickResponses(nextState);
      setQuickResponses(quickResponses);

    } catch (error) {
      console.error('Error:', error);
      await addBotMessage("I encountered an error. Could we try that again?");
    }
  };
  

  /**
   * Processes user response about pregnancy/birth status
   * Updates state and determines next question based on status
   * 
   * @param {string} message - User's message
   * @returns {string} Bot's response message
   */
  const handlePregnancyStatus = async (message) => {
    // Use intent classification instead of checkYesNo
    const babyStatusIntent = await getBabyStatusIntent(message);
    const isBorn = babyStatusIntent === 'born';
    const pregnancyStatus = isBorn ? 'born' : 'expecting';
    
    // Save pregnancy status
    const saved = await saveUserInfo({ pregnancyStatus });
    if (!saved) {
      return "I had trouble saving your information. Could you please try again?";
    }
  
    setUserInfo(prev => ({ ...prev, pregnancyStatus }));
    
    // IMPORTANT: Explicitly set both the conversation state AND onboarding state
    transitionTo(CONVERSATION_STATES.DATE_COLLECTION);
    setOnboardingState('collecting_due_date');
    
    // Clear any existing quick responses
    setQuickResponses([]);
    
    if (isBorn) {
      return [
        "Congratulations on your new arrival! To personalize your checklist properly,",
        "What's your baby's birth date? (You can use any format, like 'January 15, 2024')"
      ];
    } else {
      return [
        "Exciting times ahead! To help you prepare at the right pace,",
        "When is your due date? (You can give me an approximate date if you're not sure)"
      ];
    }
  };
  

    
  /**
   * Processes and validates baby-related dates (birth date or due date)
   * Updates user info and transitions to next state
   * 
   * @param {string} message - User's date input
   * @returns {string} Bot's response message
   */
  const handleBabyDateResponse = async (message) => {
    try {
      const date = new Date(message);
      if (isNaN(date.getTime())) {
        return "I couldn't quite understand that date. Could you try again? For example: 'March 15, 2024'";
      }

      const updates = userInfo.pregnancyStatus === 'born' 
        ? { babysBirthday: date }
        : { dueDate: date };

      // Save date information
      const saved = await saveUserInfo(updates);
      if (!saved) {
        return "I had trouble saving your information. Could you please try again?";
      }

      setOnboardingState('first_baby');
      return [
        "Perfect! This helps me organize everything in the right order.",
        "One more question to personalize your experience - is this your first baby?"
      ];
    } catch (error) {
      console.error('Error processing date:', error);
      return "I had trouble processing that date. Could you try again?";
    }
  };

  
  
    /**
     * Handles first baby status response
     * Updates categories based on parent experience
     * 
     * @param {string} message - User's message
     * @returns {string} Bot's response message
     */
    /**
   * Updates backend and local state with first baby status
   * Maps isFirstBaby to userAttributes.first_baby for API consistency
   */
    const handleFirstBabyStatus = async (message) => {
      // Use intent classification instead of checkYesNo
      const firstBabyIntent = await getFirstBabyIntent(message);
      const isFirst = firstBabyIntent === 'firstBaby';
      
      const updates = {
        isFirstBaby: isFirst,
        childrenCount: isFirst ? 0 : 1
      };
    
      // Save first baby status and children count
      const saved = await saveUserInfo(updates);
      if (!saved) {
        return "I had trouble saving your information. Could you please try again?";
      }
    
      setUserInfo(prev => ({ ...prev, ...updates }));
    
      if (!isFirst) {
        await updateCategoryStatus('experienced_parent');
        return [
          "Great! I'll take into account your experience as a parent.",
          "I've updated your checklist assuming you have early-stage items. You can start exploring the app now!"
        ];
      }
      
      return [
        "How exciting! I'll make sure to provide extra guidance for first-time parents.",
        "Perfect! Everything is set up for you. You can start exploring the app now!"
      ];
    };
  
   

  const addBotMessage = (content, delay = 1000) => {
    setIsTyping(true);
    return new Promise(resolve => {
      setTimeout(() => {
        setMessages(prev => [...prev, {
          type: 'bot',
          content: formatMessage(content)
        }]);
        setIsTyping(false);
        resolve();
      }, delay);
    });
  };

  const buildContextPrompt = (basePrompt, currentState, state, userResponse) => {
    let contextPrompt = basePrompt;
    
    // Add user context if we have it
    if (state && state.userType !== USER_TYPES.UNKNOWN) {
      contextPrompt += `\nUser role: ${state.userType} ${state.isFirstBaby === true ? '(first-time)' : state.isFirstBaby === false ? '(experienced)' : ''}`;
      if (state.babyDate) {
        contextPrompt += `\nTimeline: ${state.pregnancyStatus === 'born' ? `born ${state.babyDate}` : `due ${state.babyDate}`}`;
      }
    }
  
    // Add state-specific context
    switch (currentState) {
      case CONVERSATION_STATES.INITIAL:
        contextPrompt += `
  Stage: Initial welcome
  Goal: Welcome user and determine their interest level
  Context: User is brand new to the app
  Previous messages: ${messages.length}
  
  Rules:
  1. Introduce yourself warmly but briefly
  2. Explain you're an AI assistant for baby preparation
  3. Offer clear options: learn more or start personalizing
  4. Keep response to 2-3 sentences
  5. End with a clear question`;
        break;
  
      case CONVERSATION_STATES.VALUE_EXPLANATION:
        contextPrompt += `
  Stage: Initial value proposition
  Goal: Introduce yourself. Explain the benefits of the app and how you will help them achieve these benefits.
  Context: User is new and doesn't fully understand AI, chatbots, the benefits of checklists, or why they would use this app.
  
  Rules:
  1. Provide NEW information not mentioned before
  2. Focus on concrete benefits:
     - Personalized checklists based on lifestyle
     - Timeline organization
     - Expert product recommendations
     - Easy sharing with family/friends
  3. Keep response to 2-3 sentences maximum
  4. End with a clear next step
  5. Don't repeat what's already been said`;
        break;
  
      case CONVERSATION_STATES.USER_TYPE:
        contextPrompt += `
  Stage: Beginning personalization
  Goal: Start gathering user information
  Context: User ready to begin
  Previous responses: ${userResponse || ""}
  
  Rules:
  1. Move directly to role determination
  2. Keep question simple and clear
  3. Accept only expecting/helping responses
  4. Maintain warm, friendly tone
  5. Provide context for why this information matters
  6. Focus on next immediate step only`;
        break;
  
      case CONVERSATION_STATES.PREGNANCY_STATUS:
        contextPrompt += `
  Stage: Pregnancy status determination
  Goal: Determine if baby is born or expected
  Context: User identified as expecting parent
  Previous responses: ${userResponse || ""}
  
  Rules:
  1. Ask clearly about birth status
  2. Accept only clear yes/no responses
  3. Show excitement regardless of answer
  4. Explain why this information helps personalization
  5. Prepare for date collection
  6. Keep response warm and engaging`;
        break;
  
      case CONVERSATION_STATES.DATE_COLLECTION:
        contextPrompt += `
  Stage: Date collection
  Goal: Get accurate timeline information
  Context: Know pregnancy status (${state && state.pregnancyStatus ? state.pregnancyStatus : "unknown"})
  Previous responses: ${userResponse || ""}
  
  Rules:
  1. Ask for ${state && state.pregnancyStatus === 'born' ? 'birth date' : 'due date'}
  2. Accept approximate dates
  3. Guide date format if needed
  4. Explain this helps organize recommendations
  5. Validate date reasonableness
  6. Keep tone supportive and understanding`;
        break;
  
      case CONVERSATION_STATES.FIRST_BABY:
        contextPrompt += `
  Stage: Experience level determination
  Goal: Understand parent experience level
  Context: Have birth/due date information
  Previous responses: ${userResponse || ""}
  
  Rules:
  1. Ask clearly if this is their first baby
  2. Accept only clear yes/no responses
  3. Be supportive of either answer
  4. Explain how this personalizes their experience
  5. Prepare for appropriate next steps based on response
  6. Maintain encouraging tone`;
        break;
  
      case CONVERSATION_STATES.COMPLETED:
        contextPrompt += `
  Stage: Completion and transition
  Goal: Successfully conclude onboarding
  Context: Have all necessary information
  Previous responses: ${userResponse || ""}
  
  Rules:
  1. Confirm all information collected
  2. Express excitement about helping
  3. Provide clear next steps
  4. Maintain warm, supportive tone
  5. Offer to answer any questions
  6. Keep response concise but friendly`;
        break;
  
      default:
        contextPrompt += `
  Stage: General conversation
  Goal: Maintain helpful dialogue
  Context: Continuing conversation
  Previous responses: ${userResponse || ""}
  
  Rules:
  1. Keep responses relevant to baby preparation
  2. Maintain friendly, helpful tone
  3. Guide back to onboarding if needed
  4. Provide clear next steps
  5. Keep responses concise
  6. End with a question or call to action`;
    }
  
    // Add consistent closing rules
    contextPrompt += `
  
  Global Rules:
  1. Never exceed 3 sentences per response
  2. Always end with a clear question or next step
  3. Maintain warm, supportive tone throughout
  4. Focus on confidence-building and reducing anxiety
  5. Acknowledge and validate user concerns
  6. Use emojis sparingly and naturally`;
  
    return contextPrompt;
  };

  /**
   * Main message handler for user inputs
   * Processes user message and generates appropriate response
   * Handles state transitions and error cases
   */
  const handleSendMessage = async () => {
    if (!userInput.trim() || isTyping) return;
    const input = userInput.trim();
    setUserInput('');
  
    // Create a unique ID for this message to prevent duplication
    const messageId = Date.now();
    
    // Add user message with the unique ID
    addMessage({
      id: messageId,
      type: 'user',
      content: input
    });
  
    setIsTyping(true);
  
    try {
      // Handle initial greeting sequence
      if (messages.length === 0) {
        await handleInitialMessage();
        return;
      }
  
      // Only process if we haven't seen this message ID before
      if (!processedMessageIds.has(messageId)) {
        // Add to processed set
        setProcessedMessageIds(prev => new Set([...prev, messageId]));
        
        const lowerInput = input.toLowerCase().trim();
        
        // Special command handling
        if (lowerInput === 'start' || lowerInput === 'get started' || lowerInput === 'begin') {
          await handleOptionClick('start', input);
          return; 
        }
        
        // Process message based on current state
        await processStateBasedMessage(input, currentState, conversationContext);
      }
    } catch (error) {
      console.error('Error in handleSendMessage:', error);
      await addBotMessage("I apologize, but I'm having trouble processing your response. Could you try again?");
    } finally {
      setIsTyping(false);
    }
  };

  /**
 * Handles response after user confirms understanding
 * Provides clear next steps without repeating information
 */
const handleConfirmedUnderstanding = async () => {
  const contextPrompt = `
    Stage: After user confirmed understanding
    Goal: Provide clear next steps without repeating information
    Context: User has just confirmed they understand your explanation
    
    Rules:
    1. DO NOT repeat the information you just provided
    2. DO NOT say "I'm glad that makes sense" or similar
    3. Instead, IMMEDIATELY provide clear next options
    4. Begin with "Great!" or "Perfect!" then offer a choice
    5. For example: "Great! Would you like to learn more about X, or shall we move on to Y?"
    6. Keep your response to 1-2 sentences
  `;
  
  const response = await sendMessage("User confirmed understanding", {
    systemPrompt: generateSystemPrompt + '\n' + contextPrompt,
    temperature: 0.7,
    previousMessages: messages.map(msg => ({
      role: msg.type === 'user' ? 'user' : 'assistant',
      content: msg.content
    }))
  });
  
  await addBotMessage(response);
  
  // Potentially update state based on current flow stage
  updateConversationStateAfterConfirmation();
};

/**
 * Handles when user questions an assumption
 * @param {string} assumption - The assumption being questioned
 * @param {string} userMessage - The user's original message
 */
const handleQuestionedAssumption = async (assumption, userMessage) => {
  let contextPrompt = `
    Stage: Addressing questioned assumption
    Goal: Acknowledge and clarify without doubling down
    Context: User questioned an assumption about ${assumption}
    
    Rules:
    1. Acknowledge their perspective: "You're right to question that."
    2. Clarify without doubling down
    3. Explain the actual intent behind your statement
    4. End by asking if your clarification helps: "Does that clarify what I meant?"
    5. Keep response brief and conversational (1-3 sentences)
  `;
  
  // Add specific guidance based on the type of assumption
  if (assumption === 'spreadsheets') {
    contextPrompt += `
      Specific guidance:
      - Explain you mentioned spreadsheets as an example of how some parents track baby items
      - Acknowledge not everyone uses spreadsheets
      - Focus on the app's benefits without assumptions
    `;
  } else if (assumption === 'information_collection') {
    contextPrompt += `
      Specific guidance:
      - Explain the specific value of the information requested (e.g., due date helps timeline organization)
      - Emphasize privacy and that information stays private
      - Clarify that personalization improves the experience
    `;
  }
  
  const response = await sendMessage(userMessage, {
    systemPrompt: generateSystemPrompt + '\n' + contextPrompt,
    temperature: 0.7,
    previousMessages: messages.map(msg => ({
      role: msg.type === 'user' ? 'user' : 'assistant',
      content: msg.content
    }))
  });
  
  await addBotMessage(response);
  
  // Since we're asking for confirmation, set awaiting confirmation state
  setAwaitingConfirmation(true);
  };

  /**
   * Updates conversation state after user confirms understanding
   */
  const updateConversationStateAfterConfirmation = () => {
    // Look at current state and determine next appropriate state
    const currentMapping = {
      [CONVERSATION_STATES.INITIAL]: CONVERSATION_STATES.VALUE_EXPLANATION,
      [CONVERSATION_STATES.VALUE_EXPLANATION]: CONVERSATION_STATES.USER_TYPE,
      [CONVERSATION_STATES.USER_TYPE]: CONVERSATION_STATES.PREGNANCY_STATUS,
      // Add other mappings as needed
    };
    
    const nextState = currentMapping[currentState];
    if (nextState && nextState !== currentState) {
      transitionTo(nextState);
      const newResponses = getQuickResponses(nextState);
      setQuickResponses(newResponses);
    }
  };

  const processStateBasedMessage = async (message, state, context) => {
    try {
      setIsTyping(true);
      
      // Check if this is a confirmation response (when we're awaiting one)
      if (awaitingConfirmation) {
        const isConfirmation = await isConfirmationResponse(message);
        if (isConfirmation) {
          setHasConfirmedUnderstanding(true);
          setAwaitingConfirmation(false);
          await handleConfirmedUnderstanding();
          return;
        }
      }
      
      // Detect concerns and questioned assumptions
      const { concerns, questionedAssumption } = await detectUserConcerns(message);
      
      // Handle questioned assumptions
      if (questionedAssumption) {
        setLastQuestionedAssumption(questionedAssumption);
        await handleQuestionedAssumption(questionedAssumption, message);
        return;
      }
      
      // Update context with any concerns
      if (concerns.length > 0) {
        concerns.forEach(concern => {
          setContextState(prev => ({
            ...prev,
            expressedConcerns: [...new Set([...prev.expressedConcerns, concern])]
          }));
        });
      }
      
      // Classify the user's intent based on current state
      let intent = null;
      
      switch (state) {
        case CONVERSATION_STATES.INITIAL:
        case CONVERSATION_STATES.VALUE_EXPLANATION:
          intent = await getActionIntent(message);
          break;
        case CONVERSATION_STATES.USER_TYPE:
          intent = await getUserTypeIntent(message);
          break;
        case CONVERSATION_STATES.PREGNANCY_STATUS:
          intent = await getBabyStatusIntent(message);
          break;
        case CONVERSATION_STATES.FIRST_BABY:
          intent = await getFirstBabyIntent(message);
          break;
        case CONVERSATION_STATES.DATE_COLLECTION:
          // Keep direct date handling for this state
          // We'll extract dates in a separate step
          break;
        // Add other cases as needed
      }
      
      // Update context awareness
      updateContextAwareness(message);
      
      // Update conversation context with the classified intent
      const contextUpdates = {};
      
      if (intent) {
        if (['expecting', 'helping'].includes(intent)) {
          contextUpdates.userType = intent;
          dispatchConversation({ type: ACTION_TYPES.SET_USER_TYPE, payload: intent });
        } else if (['born', 'expecting'].includes(intent)) {
          contextUpdates.pregnancyStatus = intent;
          dispatchConversation({ type: ACTION_TYPES.SET_PREGNANCY_STATUS, payload: intent });
        } else if (['firstBaby', 'notFirstBaby'].includes(intent)) {
          contextUpdates.isFirstBaby = (intent === 'firstBaby');
          dispatchConversation({ type: ACTION_TYPES.SET_FIRST_BABY, payload: (intent === 'firstBaby') });
        }
      }
      
      // Special handling for date collection state
      if (state === CONVERSATION_STATES.DATE_COLLECTION) {
        try {
          let date = new Date(message);
          if (!isNaN(date.getTime())) {
            const dateKey = context?.pregnancyStatus === 'born' ? 'babysBirthday' : 'dueDate';
            contextUpdates[dateKey] = date;
            
            // Also update backend
            await saveUserInfo({
              [dateKey]: date
            });
            
            // Move to next state after successful date extraction
            transitionTo(CONVERSATION_STATES.FIRST_BABY);
          }
        } catch (error) {
          console.error('Date parsing error:', error);
        }
      }
      
      // Update conversation context if there are changes
      if (Object.keys(contextUpdates).length > 0) {
        setConversationContext(prev => ({...prev, ...contextUpdates}));
      }
      
      // Build appropriate context prompt
      const contextPrompt = buildContextPrompt(generateSystemPrompt || '', state, context, message);
      
      // Add confirmation state to the context prompt
      const enhancedPrompt = contextPrompt + `
        
        Confirmation context:
        - Awaiting confirmation: ${awaitingConfirmation}
        - User has confirmed understanding: ${hasConfirmedUnderstanding}
        - Last questioned assumption: ${lastQuestionedAssumption || 'none'}
      `;
      
      // Get response from AI
      const response = await sendMessage(message, {
        systemPrompt: enhancedPrompt,
        temperature: 0.5,
        previousMessages: (messages || []).map(msg => ({
          role: msg.type === 'user' ? 'user' : 'assistant',
          content: msg.content
        }))
      });
      
      // Quick check if response contains confirmation question (using regex)
      // This is faster than a full API call for classification
      const likelyContainsConfirmation = quickCheckForConfirmation(response);
      
      if (likelyContainsConfirmation) {
        // Set awaiting confirmation state based on quick check
        setAwaitingConfirmation(true);
      }
      
      // Extract and process user info from the response
      if (response) {
        try {
          const extractedInfo = await extractUserInfo(response, message);
          if (extractedInfo) {
            // Update state based on extracted info
            let additionalUpdates = {};
            
            if (extractedInfo.userType && !contextUpdates.userType) {
              dispatchConversation({ type: ACTION_TYPES.SET_USER_TYPE, payload: extractedInfo.userType });
              additionalUpdates.userType = extractedInfo.userType;
            }
            
            if (extractedInfo.pregnancyStatus && !contextUpdates.pregnancyStatus) {
              dispatchConversation({ type: ACTION_TYPES.SET_PREGNANCY_STATUS, payload: extractedInfo.pregnancyStatus });
              additionalUpdates.pregnancyStatus = extractedInfo.pregnancyStatus;
            }
            
            if (extractedInfo.babyDate && !contextUpdates.babyDate) {
              dispatchConversation({ type: ACTION_TYPES.SET_BABY_DATE, payload: extractedInfo.babyDate });
              additionalUpdates.babyDate = extractedInfo.babyDate;
            }
            
            if (extractedInfo.isFirstBaby !== undefined && contextUpdates.isFirstBaby === undefined) {
              dispatchConversation({ type: ACTION_TYPES.SET_FIRST_BABY, payload: extractedInfo.isFirstBaby });
              additionalUpdates.isFirstBaby = extractedInfo.isFirstBaby;
            }
            
            // Update conversation context with additional info
            if (Object.keys(additionalUpdates).length > 0) {
              setConversationContext(prev => ({...prev, ...additionalUpdates}));
            }
          }
        } catch (error) {
          console.error('Error extracting user info:', error);
        }
        
        // Add bot response
        await addBotMessage(response);
        
        // Determine next conversation state based on intent or custom logic
        const nextState = intent ? getNextState(intent, state) : 
                         getNextState(message, state);
                         
        if (nextState && nextState !== state) {
          transitionTo(nextState);
          const newResponses = getQuickResponses(nextState);
          setQuickResponses(newResponses);
        }
        
        // Scroll to make new content visible
        scrollToBottom();
      }
    } catch (error) {
      console.error('Error processing message:', error);
      await addBotMessage("I apologize, but I'm having trouble responding right now. Could you please try again?");
    } finally {
      setIsTyping(false);
    }
  };

/**
 * Determines how many shortcut options should be generated for each state
 * @param {string} state - Current conversation state
 * @returns {number} - Number of options to generate
 */
const getOptionsCountForState = (state) => {
  switch (state) {
    case 'INITIAL':
    case 'VALUE_EXPLANATION':
    case 'USER_TYPE':
    case 'PREGNANCY_STATUS':
    case 'FIRST_BABY':
      return 2; // These states typically need binary choices
    case 'QUESTIONS':
      return 3; // More options for question states
    case 'COMPLETED':
      return 1; // Just one option at completion
    default:
      return 2; // Default to 2 options
  }
};

/**
 * Provides fallback shortcut options when dynamic generation fails
 * @param {string} state - Current conversation state
 * @returns {Array} - Array of default shortcut options
 */
const getDefaultShortcuts = (state) => {
  switch (state) {
    case 'INITIAL':
      return [
        { text: "Learn more", value: "learn" },
        { text: "Get started", value: "start" }
      ];
    case 'VALUE_EXPLANATION':
      return [
        { text: "Tell me more", value: "more" },
        { text: "Start now", value: "start" }
      ];
    case 'USER_TYPE':
      return [
        { text: "I'm expecting", value: "expecting" },
        { text: "Helping someone", value: "helping" }
      ];
    case 'PREGNANCY_STATUS':
      return [
        { text: "Baby is born", value: "born" },
        { text: "Still expecting", value: "notBorn" }
      ];
    case 'FIRST_BABY':
      return [
        { text: "First baby", value: "firstBaby" },
        { text: "Have children", value: "notFirstBaby" }
      ];
    case 'QUESTIONS':
      return [
        { text: "About the app", value: "app_purpose" },
        { text: "About privacy", value: "privacy_question" },
        { text: "Start personalizing", value: "start" }
      ];
    case 'COMPLETED':
      return [
        { text: "Explore the app", value: "start" }
      ];
    default:
      return [
        { text: "Continue", value: "more" },
        { text: "Get started", value: "start" }
      ];
  }
};

  const quickCheckForConfirmation = (response) => {
    const confirmationPatterns = [
      /does that (make sense|help|answer your question)/i,
      /is that (helpful|clear|what you wanted to know)/i,
      /does this (help|explanation make sense)/i,
      /did that (answer|address|clarify)/i,
      /make sense\?/i,
      /helpful\?/i,
      /understand\?/i
    ];
    
    return confirmationPatterns.some(pattern => pattern.test(response));
  };

  const processMessageForState = async (message, state) => {
    const lowerMessage = message.toLowerCase();
  
    switch (state) {
      case CONVERSATION_STATES.INITIAL:
        if (lowerMessage.includes('learn') || lowerMessage.includes('more')) {
          transitionTo(CONVERSATION_STATES.VALUE_EXPLANATION);
          return "Our app creates personalized checklists based on your specific needs. We consider factors like your location, lifestyle, and preferences to recommend the right products. Would you like to start personalizing your checklist?";
        } else if (lowerMessage.includes('start') || lowerMessage.includes('yes')) {
          transitionTo(CONVERSATION_STATES.USER_TYPE);
          return "Great! First, I need to know: are you here for your family, or are you helping a friend?";
        }
        return "Would you like to learn more about the app, or shall we start personalizing your checklist?";
  
      case CONVERSATION_STATES.VALUE_EXPLANATION:
        transitionTo(CONVERSATION_STATES.USER_TYPE);
        return "Wonderful! To get started, are you expecting a baby or helping someone who is?";
  
      case CONVERSATION_STATES.USER_TYPE:
        if (lowerMessage.includes('help')) {
          dispatchConversation({ 
            type: ACTION_TYPES.SET_USER_TYPE, 
            payload: USER_TYPES.HELPING 
          });
          transitionTo(CONVERSATION_STATES.COMPLETED);
          return "Perfect! I'll configure the app for sharing your experience and recommendations. Would you like to start exploring the app now?";
        } else if (lowerMessage.includes('expect')) {
          dispatchConversation({ 
            type: ACTION_TYPES.SET_USER_TYPE, 
            payload: USER_TYPES.EXPECTING 
          });
          transitionTo(CONVERSATION_STATES.PREGNANCY_STATUS);
          return "Excellent! To personalize your checklist, is your baby already born?";
        }
        return "I need to know if you're expecting or helping someone who is. Which one describes you?";
  
      case CONVERSATION_STATES.PREGNANCY_STATUS:
        const isBorn = lowerMessage.includes('yes') || lowerMessage.includes('born');
        dispatchConversation({
          type: ACTION_TYPES.SET_PREGNANCY_STATUS,
          payload: isBorn ? 'born' : 'expecting'
        });
        transitionTo(CONVERSATION_STATES.DATE_COLLECTION);
        return isBorn 
          ? "Congratulations! When was your baby born? (You can use any format, like 'January 15, 2024')"
          : "When is your due date? (You can give me an approximate date if you're not sure)";
  
      // Add other states as needed...
  
      default:
        return "I'm not sure how to respond to that. Could you please try again?";
    }
  };

  const validateInput = (input) => {
    const trimmedInput = input.trim().toLowerCase();
    
    switch (currentState) {
      case CONVERSATION_STATES.USER_TYPE:
        return trimmedInput.includes('expect') || trimmedInput.includes('help');
        
      case CONVERSATION_STATES.PREGNANCY_STATUS:
        return /\b(yes|no|born|expecting|pregnant)\b/.test(trimmedInput);
        
      case CONVERSATION_STATES.DATE_COLLECTION:
        return isValidDate(trimmedInput);
        
      default:
        return true;
    }
  };
  
  const isValidDate = (input) => {
    try {
      const date = new Date(input);
      const now = new Date();
      return !isNaN(date.getTime()) && 
             date > new Date(now.getFullYear() - 1, now.getMonth()) &&
             date < new Date(now.getFullYear() + 1, now.getMonth());
    } catch {
      return false;
    }
  };

  const validateResponseForState = (response, state) => {
    const lowerResponse = response.toLowerCase();
    
    switch (state) {
      case CONVERSATION_STATES.VALUE_EXPLANATION:
        return lowerResponse.includes('what') || 
               lowerResponse.includes('which') ||
               lowerResponse.includes('would you like');
        
      case CONVERSATION_STATES.USER_TYPE:
        return lowerResponse.includes('expecting') || 
               lowerResponse.includes('helping');
        
      case CONVERSATION_STATES.PREGNANCY_STATUS:
        return lowerResponse.includes('born') || 
               lowerResponse.includes('birth') ||
               lowerResponse.includes('due');
        
      case CONVERSATION_STATES.DATE_COLLECTION:
        return lowerResponse.includes('date') || 
               lowerResponse.includes('when');
  
      case CONVERSATION_STATES.FIRST_BABY:
        return lowerResponse.includes('first') ||
               lowerResponse.includes('experience') ||
               lowerResponse.includes('other children');
  
      case CONVERSATION_STATES.COMPLETED:
        return lowerResponse.includes('great') ||
               lowerResponse.includes('perfect') ||
               lowerResponse.includes('wonderful');
        
      default:
        return true;
    }
  };
  

  const determineNextStage = (currentStage, response, userMessage) => {
    const { isYes, isNo } = checkYesNo(userMessage);
    const lowerResponse = response.toLowerCase();
    
    switch (currentStage) {
      case CONVERSATION_STATES.INITIAL:
        if (userMessage.toLowerCase().includes('learn')) {
          return CONVERSATION_STATES.VALUE_EXPLANATION;
        }
        if (userMessage.toLowerCase().includes('start')) {
          return CONVERSATION_STATES.USER_TYPE;
        }
        break;
      
      case CONVERSATION_STATES.VALUE_EXPLANATION:
        if (isYes || userMessage.toLowerCase().includes('start')) {
          return CONVERSATION_STATES.USER_TYPE;
        }
        break;
      
      case CONVERSATION_STATES.USER_TYPE:
        if (lowerResponse.includes('wonderful') || 
            lowerResponse.includes('great') ||
            userMessage.toLowerCase().includes('expect') ||
            userMessage.toLowerCase().includes('help')) {
          return CONVERSATION_STATES.PREGNANCY_STATUS;
        }
        break;
      
      case CONVERSATION_STATES.PREGNANCY_STATUS:
        if (lowerResponse.includes('congratulations') ||
            lowerResponse.includes('exciting') ||
            isYes || isNo) {
          return CONVERSATION_STATES.DATE_COLLECTION;
        }
        break;
      
      case CONVERSATION_STATES.DATE_COLLECTION:
        if (isValidDate(userMessage) || lowerResponse.includes('perfect')) {
          return CONVERSATION_STATES.FIRST_BABY;
        }
        break;
  
      case CONVERSATION_STATES.FIRST_BABY:
        if (isYes || isNo || 
            userMessage.toLowerCase().includes('first') ||
            userMessage.toLowerCase().includes('have children')) {
          return CONVERSATION_STATES.COMPLETED;
        }
        break;
  
      case CONVERSATION_STATES.COMPLETED:
        return CONVERSATION_STATES.COMPLETED; // Stay in completed state
    }
    
    return currentStage;
  };  

  const extractUserInfo = async (response, userMessage) => {
    const info = {};
    
    try {
      // Extract user type intent if in appropriate state
      if (currentState === CONVERSATION_STATES.USER_TYPE || currentState === CONVERSATION_STATES.INITIAL) {
        const userTypeIntent = await getUserTypeIntent(userMessage);
        if (userTypeIntent) {
          info.userType = userTypeIntent;
        }
      }
      
      // Extract baby status if in appropriate state
      if (currentState === CONVERSATION_STATES.PREGNANCY_STATUS) {
        const babyStatusIntent = await getBabyStatusIntent(userMessage);
        if (babyStatusIntent) {
          info.pregnancyStatus = babyStatusIntent;
        }
      }
      
      // Extract first baby status if in appropriate state
      if (currentState === CONVERSATION_STATES.FIRST_BABY) {
        const firstBabyIntent = await getFirstBabyIntent(userMessage);
        if (firstBabyIntent) {
          info.isFirstBaby = (firstBabyIntent === 'firstBaby');
        }
      }
      
      // Extract date information (still using direct date parsing)
      if (currentState === CONVERSATION_STATES.DATE_COLLECTION) {
        try {
          let date = new Date(userMessage);
          if (!isNaN(date.getTime())) {
            const pregnancyStatus = conversationContext?.pregnancyStatus || 'expecting';
            if (pregnancyStatus === 'born') {
              info.babysBirthday = date;
            } else {
              info.dueDate = date;
            }
          }
        } catch (error) {
          // Not a valid date, ignore
        }
      }
      
      return Object.keys(info).length > 0 ? info : null;
      
    } catch (error) {
      console.error('Error extracting user info:', error);
      return null;
    }
  };
  
  const stageTitles = {
    welcome: "Welcome!",
    value_explanation: "What We Offer",
    personalizing: "Let's Personalize Your Experience",
    categories: "Choose Your Categories",
    sharing: "Share Your Journey"
  };

  useEffect(() => {
    if (isOpen && messages.length === 0) {
      // Ensure we start collapsed
      setShowShortcuts(false);
      setIsExpanding(false);
      handleInitialMessage();
    }
  }, [isOpen]);
  
  // Effect for handling shortcuts visibility
  useEffect(() => {
    // Clear shortcuts specifically when in DATE_COLLECTION state
    if (currentState === CONVERSATION_STATES.DATE_COLLECTION || 
        onboardingState === 'collecting_due_date' || 
        onboardingState === 'due_date' || 
        onboardingState === 'baby_birthday') {
      setShowShortcuts(false);
      setQuickResponses([]);
      return;
    }
    
    const shortcutStates = [
      'initial',
      'value_explanation',
      'user_type',
      'pregnancy_status',
      'first_baby',
      'categories'
    ];

    if (shortcutStates.includes(onboardingState) && !isTyping && messages.length > 0 && inputFocused) {
      setIsExpanding(true);
      setShowShortcuts(true);
    } else if (!inputFocused) {
      setShowShortcuts(false);
      setIsExpanding(false);
    }
  }, [currentState, onboardingState, isTyping, messages.length, inputFocused]);
  
  

  const getButtonStyle = (color) => ({
    backgroundColor: color,
    border: 'none',
    borderRadius: '18px',
    height: '36px',
    minWidth: '120px', // Minimum width
    padding: '0 16px', // Horizontal padding for text
    margin: '4px', // Add margin for flex wrapping
    transition: 'all 0.2s ease-in-out',
    cursor: 'pointer',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    whiteSpace: 'nowrap', // Prevents text from wrapping inside button
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    ':hover': {
      transform: 'translateY(-1px)',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)'
    }
  });

  
  useEffect(() => {
    console.log('Current state:', {
      currentState,
      onboardingState,
      showShortcuts,
      isTyping,
      messages: messages.length
    });
  }, [currentState, onboardingState, showShortcuts, isTyping, messages]);

  const handleButtonClick = (response) => {
    // Map the response to a value
    let value = 'more'; // default
    
    // Check for exact matches to determine value
    if (response === "What is this?") {
      value = 'what_is_this';
    } else if (response === "What do you do with my data?") {
      value = 'privacy_question';
    } else if (response.toLowerCase().includes('get started')) {
      value = 'start';
    } else if (response === "Tell me more") {
      value = 'more';
    } else if (response === "Can I trust you?") {
      value = 'trust_question';
    } else if (response === "I'm expecting") {
      value = 'expecting';
    } else if (response === "I'm not expecting") {
      value = 'helping';
    } else if (response === "Yes") {
      value = 'born';
    } else if (response === "No") {
      value = 'notBorn';
    } else if (response === "This is my first baby") {
      value = 'firstBaby';
    } else if (response === "I have other children") {
      value = 'notFirstBaby';
    }
    
    // Pass both the value and the original text
    handleOptionClick(value, response);
  };
  
  return (
  <Modal
    title={<div style={{ width: '100%', textAlign: 'center', fontWeight: 500 }}>Talk to your personal assistant</div>}
    open={isOpen}
    onCancel={onClose}
    footer={null}
    width={600}
    style={{ padding: 0, top: '50px' }}
  >
      {/* Wrap modal content in a fixed-height container */}
      <div style={{ height: '500px', display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
        {/* Header section with steps - remains unchanged */}
        <div style={{ backgroundColor: 'white', padding: '16px' }}>
          <div style={{ display: 'flex', gap: '10px' }}>
            {[
              { label: 'About us', icon: MessageCircle, step: 'welcome', colors: { icon: '#3b82f6', circle: '#dbeafe', bg: '#eff6ff' } },
              { label: 'About You', icon: Baby, step: 'about', colors: { icon: '#ec4899', circle: '#fce7f3', bg: '#fdf2f8' } },
              { label: 'Categories', icon: CheckCircle2, step: 'categories', colors: { icon: '#22c55e', circle: '#dcfce7', bg: '#f0fdf4' } },
              { label: 'Share', icon: Gift, step: 'sharing', colors: { icon: '#a855f7', circle: '#f3e8ff', bg: '#faf5ff' } }
            ].map((step, idx) => {
              const active = isStepActive(step.step);
              return (
                <div key={idx} style={{ flex: 1 }}>
                  <div style={{ 
                    width: '100%',
                    height: '80px',
                    borderRadius: '8px',
                    backgroundColor: active ? step.colors.bg : '#f3f4f6',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'all 0.2s ease-in-out'
                  }}>
                    <div style={{ 
                      width: '48px',
                      height: '48px',
                      borderRadius: '24px',
                      backgroundColor: active ? step.colors.circle : '#e5e7eb',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      transition: 'all 0.2s ease-in-out'
                    }}>
                      <step.icon style={{ 
                        width: '24px',
                        height: '24px',
                        color: active ? step.colors.icon : '#9ca3af',
                        transition: 'all 0.2s ease-in-out'
                      }} />
                    </div>
                    <span style={{ 
                      fontSize: '12px',
                      fontWeight: 500,
                      color: active ? '#475569' : '#9ca3af',
                      marginTop: '4px',
                      transition: 'all 0.2s ease-in-out'
                    }}>
                      {step.label}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
    
        {/* Messages container with flex growth instead of calculated height */}
        <div className="messages-container" style={{ 
          padding: '16px',
          flex: '1 1 auto',
          minHeight: '100px',
          overflowY: 'auto',
          position: 'relative',
          transition: 'all 300ms ease-in-out'
        }}>
          <div className="flex flex-col gap-3">
            {messages.map((message, index) => (
              <div
                key={index}
                style={{
                  marginBottom: '12px',
                  padding: '12px 16px',
                  borderRadius: '8px',
                  maxWidth: '80%',
                  backgroundColor: message.type === 'user' ? '#e6f7ff' : '#f5f5f5',
                  marginLeft: message.type === 'user' ? 'auto' : '0',
                  color: '#000000',
                  whiteSpace: 'pre-line',
                  fontSize: '14px',
                  lineHeight: 1.5
                }}
              >
                <div>{message.content}</div>
              </div>
            ))}
          </div>
          {isTyping && (
            <div style={{ padding: '12px' }}>
              <Spin size="small" />
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>
  
        {/* Bottom section with shortcuts and input */}
        <div style={{ 
          borderTop: '1px solid #f0f0f0',
          backgroundColor: '#ffffff', 
          position: 'relative',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {/* Shortcuts container with properly controlled height/visibility */}
          <div 
            ref={shortcutsRef} 
            className="shortcuts-container" 
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              maxHeight: showShortcuts ? `${shortcutsHeight}px` : '0px',
              opacity: showShortcuts ? 1 : 0,
              overflow: 'hidden',
              padding: showShortcuts ? '16px 0 8px' : '0',
              transition: 'all 300ms ease-in-out'
            }}
          >
            <div style={{ fontSize: '12px', color: '#666666', marginBottom: '8px' }}>
              Shortcuts:
            </div>
            <div style={{ 
              display: 'flex', 
              flexWrap: 'wrap', 
              gap: '8px', 
              justifyContent: 'center',
              width: '100%',
              maxWidth: '520px',
              margin: '0 auto',
              padding: '0 16px 8px'
            }}>
              {(() => {
                // Function to get button style with responsive width and animation
                const getResponsiveButtonStyle = (color) => ({
                  backgroundColor: color,
                  border: 'none',
                  color: '#333333',
                  height: '36px',                  
                  maxWidth: '220px',
                  padding: '0 16px',
                  margin: '0',
                  borderRadius: '18px',
                  boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                  cursor: 'pointer',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  flex: '0 0 auto',
                  opacity: buttonsVisible ? 1 : 0, // Control visibility with opacity
                  transform: buttonsVisible ? 'translateY(0)' : 'translateY(10px)', // Add subtle movement
                  transition: 'opacity 1000ms ease-in-out, transform 1000ms ease-in-out' // 1-second transition
                });

                
                // SINGLE RESPONSIBILITY: Just determine which buttons to show
                if (quickResponses && quickResponses.length > 0) {
                  console.log('Using quickResponses:', quickResponses);
                  return quickResponses.map((response, index) => (
                    <Button 
                      key={`qr-${index}`}
                      onClick={() => handleButtonClick(response)}
                      style={getResponsiveButtonStyle(pastelColors[index % pastelColors.length])}
                    >
                      {response}
                    </Button>
                  ));
                }

                // Fallbacks organized by state
                console.log('Falling back to onboardingState buttons:', onboardingState);
                if (onboardingState === 'initial') {
                  return ['Learn more', 'Get started'].map((text, idx) => (
                    <Button 
                      key={text}
                      onClick={() => handleOptionClick(idx === 0 ? 'learn' : 'start')}
                      style={getResponsiveButtonStyle(pastelColors[idx % pastelColors.length])}
                    >
                      {text}
                    </Button>
                  ));
                }

                if (onboardingState === 'value_explanation') {
                  return ['Tell me more', 'Start my checklist'].map((text, idx) => (
                    <Button 
                      key={text}
                      onClick={() => handleOptionClick(idx === 0 ? 'more' : 'start')}
                      style={getResponsiveButtonStyle(pastelColors[idx % pastelColors.length])}
                    >
                      {text}
                    </Button>
                  ));
                }

                if (onboardingState === 'user_type') {
                  return ['My family', 'Helping a friend'].map((text, idx) => (
                    <Button 
                      key={text}
                      onClick={() => handleOptionClick(idx === 0 ? 'expecting' : 'helping')}
                      style={getResponsiveButtonStyle(pastelColors[idx % pastelColors.length])}
                    >
                      {text}
                    </Button>
                  ));
                }

                if (onboardingState === 'pregnancy_status') {
                  return ['Baby is born', 'Still expecting'].map((text, idx) => (
                    <Button 
                      key={text}
                      onClick={() => handleOptionClick(idx === 0 ? 'born' : 'notBorn')}
                      style={getResponsiveButtonStyle(pastelColors[idx % pastelColors.length])}
                    >
                      {text}
                    </Button>
                  ));
                }

                if (onboardingState === 'first_baby') {
                  return ['First baby', 'Have children'].map((text, idx) => (
                    <Button 
                      key={text}
                      onClick={() => handleOptionClick(idx === 0 ? 'firstBaby' : 'notFirstBaby')}
                      style={getResponsiveButtonStyle(pastelColors[idx % pastelColors.length])}
                    >
                      {text}
                    </Button>
                  ));
                }
                
                // Default case
                return null;
              })()}
            </div>
          </div>
          
          {/* Input area - remains as a fixed-height section */}
          <div style={{ 
            padding: '16px',
            display: 'flex',
            gap: '8px',
            backgroundColor: '#ffffff'
          }}>
            <Input
              value={userInput}
              onChange={(e) => setUserInput(e.target.value)}
              onPressEnter={handleSendMessage}
              onFocus={() => setInputFocused(true)}
              onBlur={() => setInputFocused(false)}
              placeholder="Type your message..."
              disabled={isTyping}
              style={{
                borderRadius: '8px',
                padding: '8px 12px',
                fontSize: '14px'
              }}
            />
            <Button
              onClick={handleSendMessage}
              disabled={!userInput.trim() || isTyping}
              type="primary"
              icon={<SendOutlined />}
              style={{
                borderRadius: '8px',
                height: '40px',
                width: '40px',
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default WelcomeChatbot;