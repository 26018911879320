import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './ProfileScreen.module.css';

// Icons (simple SVG implementations)
const SettingsIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="12" cy="12" r="3"></circle>
    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
  </svg>
);

const EditIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
    <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
  </svg>
);

const CameraIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
    <circle cx="12" cy="13" r="4"></circle>
  </svg>
);

const HomeIcon = () => (
  <svg className={styles.navIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path>
    <polyline points="9 22 9 12 15 12 15 22"></polyline>
  </svg>
);

const CommunityIcon = () => (
  <svg className={styles.navIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M23 21v-2a4 4 0 00-3-3.87"></path>
    <path d="M16 3.13a4 4 0 010 7.75"></path>
  </svg>
);

const ProfileIcon = () => (
  <svg className={styles.navIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </svg>
);

const GiftIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polyline points="20 12 20 22 4 22 4 12"></polyline>
    <rect x="2" y="7" width="20" height="5"></rect>
    <line x1="12" y1="22" x2="12" y2="7"></line>
    <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z"></path>
    <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z"></path>
  </svg>
);

// Avatar component
const Avatar = ({ name, photoUrl }) => {
  const getInitials = (name) => {
    if (!name) return '?';
    return name.split(' ').map(word => word[0]).join('').toUpperCase();
  };

  return (
    <div className={styles.avatar}>
      {photoUrl ? (
        <img src={photoUrl} alt={name} className={styles.avatarImage} />
      ) : (
        <span className={styles.initials}>{getInitials(name)}</span>
      )}
    </div>
  );
};

const ProfileScreen = () => {
  const history = useHistory();
  const [activeTab, setActiveTab] = useState('myLists');
  
  // Mock data for user profile
  const userProfile = {
    id: "user-current",
    name: "Emily Johnson",
    email: "emily@example.com",
    photoUrl: null, // Use initial if null
    babyName: "Olivia",
    babyAge: 3, // in months
    babyDueDate: null, // Only if pregnant
    bio: "First-time mom figuring it all out as I go!",
    stats: {
      listsCreated: 4,
      itemsShared: 27,
      thanksReceived: 8,
      following: 5
    }
  };

  const myLists = [
    {
      id: "list-1",
      title: "My Hospital Bag",
      type: "hospital",
      createdAt: new Date('2023-01-15').getTime(),
      itemCount: 15,
      colorTheme: "blue"
    },
    {
      id: "list-2",
      title: "Baby Essentials",
      type: "nursery",
      createdAt: new Date('2023-02-20').getTime(),
      itemCount: 12,
      colorTheme: "pink"
    },
    {
      id: "list-3",
      title: "Feeding Supplies",
      type: "feeding",
      createdAt: new Date('2023-03-05').getTime(),
      itemCount: 8,
      colorTheme: "green"
    }
  ];

  const savedLists = [
    {
      id: "saved-1",
      title: "Jessica's Minimalist Newborn List",
      creator: {
        id: "user-2",
        name: "Jessica M",
        initials: "JM"
      },
      savedAt: new Date('2023-02-10').getTime(),
      itemCount: 20,
      colorTheme: "purple"
    },
    {
      id: "saved-2",
      title: "Emma's Twin Essentials",
      creator: {
        id: "user-4",
        name: "Emma P",
        initials: "EP"
      },
      savedAt: new Date('2023-03-01').getTime(),
      itemCount: 25,
      colorTheme: "pink"
    }
  ];

  // Sample perks for the new Perks tab
  const availablePerks = [
    {
      id: "perk-1",
      title: "Hello Bello Essentials Starter Box",
      description: "Free sample box of organic baby products",
      type: "sample",
      expiresAt: new Date('2023-04-10').getTime()
    },
    {
      id: "perk-2",
      title: "Tubby Todd Bath Essentials Mini Set",
      description: "Free samples for sensitive skin",
      type: "sample",
      expiresAt: new Date('2023-04-15').getTime()
    },
    {
      id: "perk-3",
      title: "SNOO Smart Sleeper Bassinet",
      description: "$200 off this smart bassinet",
      type: "discount",
      expiresAt: new Date('2023-05-01').getTime()
    },
    {
      id: "perk-4",
      title: "Spectra S1 Plus Breast Pump",
      description: "30% off this premium pump",
      type: "discount",
      expiresAt: new Date('2023-04-20').getTime()
    },
    {
      id: "perk-5",
      title: "Earth's Best Organic Baby Food Starter Box",
      description: "8 pouches of different fruit/veggie combinations",
      type: "sample",
      expiresAt: new Date('2023-04-25').getTime()
    }
  ];

  const claimedPerks = [
    {
      id: "claimed-1",
      title: "Honest Company Diaper Sample Box",
      claimedAt: new Date('2023-03-05').getTime(),
      status: "shipped"
    }
  ];

  // Navigate to list detail
  const handleListClick = (listId) => {
    history.push(`/list/${listId}`);
  };

  // Navigate to saved list
  const handleSavedListClick = (listId) => {
    history.push(`/shared-list/${listId}`);
  };

  // Navigate to settings
  const handleSettingsClick = () => {
    history.push('/settings');
  };

  // Handle edit profile
  const handleEditProfile = () => {
    console.log("Edit profile clicked");
    // In a real app, navigate to edit profile page
    // history.push('/edit-profile');
  };

  // Handle photo upload
  const handlePhotoUpload = () => {
    console.log("Photo upload clicked");
    // In a real app, open file picker
  };

  // Handle claiming a perk
  const handleClaimPerk = (perkId) => {
    console.log(`Claiming perk: ${perkId}`);
    // In a real app, this would call an API to claim the perk
    alert(`Perk claimed! Check your email for more details.`);
  };

  // Format age string based on months
  const formatBabyAge = (ageInMonths) => {
    if (ageInMonths < 1) {
      return "Newborn";
    } else if (ageInMonths === 1) {
      return "1 month old";
    } else if (ageInMonths < 24) {
      return `${ageInMonths} months old`;
    } else {
      const years = Math.floor(ageInMonths / 12);
      const remainingMonths = ageInMonths % 12;
      if (remainingMonths === 0) {
        return years === 1 ? "1 year old" : `${years} years old`;
      } else {
        return `${years} year${years > 1 ? 's' : ''}, ${remainingMonths} month${remainingMonths > 1 ? 's' : ''} old`;
      }
    }
  };

  // Format date
  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('en-US', { 
      month: 'short', 
      day: 'numeric', 
      year: 'numeric' 
    });
  };

  // Get color class based on list theme
  const getColorClass = (colorTheme) => {
    switch (colorTheme) {
      case 'purple': return styles.purpleTheme;
      case 'blue': return styles.blueTheme;
      case 'pink': return styles.pinkTheme;
      case 'green': return styles.greenTheme;
      case 'orange': return styles.orangeTheme;
      default: return styles.defaultTheme;
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.title}>Profile</h1>
        <button 
          className={styles.settingsButton} 
          onClick={handleSettingsClick}
          aria-label="Settings"
        >
          <SettingsIcon />
        </button>
      </header>

      <div className={styles.profileContainer}>
        <div className={styles.profileHeader}>
          <div className={styles.profileAvatarContainer}>
            <Avatar name={userProfile.name} photoUrl={userProfile.photoUrl} />
            <button 
              className={styles.photoUploadButton}
              onClick={handlePhotoUpload}
              aria-label="Upload photo"
            >
              <CameraIcon />
            </button>
          </div>
          
          <div className={styles.profileInfo}>
            <h2 className={styles.profileName}>{userProfile.name}</h2>
            <p className={styles.profileBio}>{userProfile.bio}</p>
            <button 
              className={styles.editProfileButton}
              onClick={handleEditProfile}
            >
              <EditIcon />
              <span>Edit Profile</span>
            </button>
          </div>
        </div>

        <div className={styles.babyInfoCard}>
          <h3 className={styles.babyInfoTitle}>
            {userProfile.babyName ? `Baby ${userProfile.babyName}` : 'Baby'}
          </h3>
          <p className={styles.babyInfoAge}>
            {userProfile.babyDueDate 
              ? `Due ${formatDate(userProfile.babyDueDate)}` 
              : formatBabyAge(userProfile.babyAge)
            }
          </p>
        </div>

        <div className={styles.statsContainer}>
          <div className={styles.statCard}>
            <span className={styles.statValue}>{userProfile.stats.listsCreated}</span>
            <span className={styles.statLabel}>Lists Created</span>
          </div>
          <div className={styles.statCard}>
            <span className={styles.statValue}>{userProfile.stats.itemsShared}</span>
            <span className={styles.statLabel}>Items Shared</span>
          </div>
          <div className={styles.statCard}>
            <span className={styles.statValue}>{userProfile.stats.thanksReceived}</span>
            <span className={styles.statLabel}>Thanks</span>
          </div>
          <div className={styles.statCard}>
            <span className={styles.statValue}>{userProfile.stats.following}</span>
            <span className={styles.statLabel}>Following</span>
          </div>
        </div>

        <div className={styles.tabsContainer}>
          <button
            className={`${styles.tabButton} ${activeTab === 'myLists' ? styles.activeTab : ''}`}
            onClick={() => setActiveTab('myLists')}
          >
            My Lists
          </button>
          <button
            className={`${styles.tabButton} ${activeTab === 'savedLists' ? styles.activeTab : ''}`}
            onClick={() => setActiveTab('savedLists')}
          >
            Saved Lists
          </button>
          <button
            className={`${styles.tabButton} ${activeTab === 'perks' ? styles.activeTab : ''}`}
            onClick={() => setActiveTab('perks')}
          >
            My Perks
          </button>
          <button
            className={`${styles.tabButton} ${activeTab === 'following' ? styles.activeTab : ''}`}
            onClick={() => setActiveTab('following')}
          >
            Following
          </button>
        </div>

        <div className={styles.tabContent}>
          {activeTab === 'myLists' && (
            <div className={styles.listsGrid}>
              {myLists.length > 0 ? (
                myLists.map(list => (
                  <div 
                    key={list.id} 
                    className={`${styles.listCard} ${getColorClass(list.colorTheme)}`}
                    onClick={() => handleListClick(list.id)}
                  >
                    <h3 className={styles.listTitle}>{list.title}</h3>
                    <div className={styles.listDetails}>
                      <span className={styles.listType}>{list.type}</span>
                      <span className={styles.listItemCount}>{list.itemCount} items</span>
                    </div>
                    <div className={styles.listDate}>
                      Created {formatDate(list.createdAt)}
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.emptyState}>
                  <p>You haven't created any lists yet.</p>
                  <button
                    className={styles.createListButton}
                    onClick={() => history.push('/create')}
                  >
                    Create Your First List
                  </button>
                </div>
              )}
            </div>
          )}

          {activeTab === 'savedLists' && (
            <div className={styles.listsGrid}>
              {savedLists.length > 0 ? (
                savedLists.map(list => (
                  <div 
                    key={list.id} 
                    className={`${styles.listCard} ${getColorClass(list.colorTheme)}`}
                    onClick={() => handleSavedListClick(list.id)}
                  >
                    <h3 className={styles.listTitle}>{list.title}</h3>
                    <div className={styles.creatorInfo}>
                      <Avatar name={list.creator.name} />
                      <span className={styles.creatorName}>{list.creator.name}</span>
                    </div>
                    <div className={styles.listDetails}>
                      <span className={styles.listItemCount}>{list.itemCount} items</span>
                    </div>
                    <div className={styles.listDate}>
                      Saved {formatDate(list.savedAt)}
                    </div>
                  </div>
                ))
              ) : (
                <div className={styles.emptyState}>
                  <p>You haven't saved any lists yet.</p>
                  <button
                    className={styles.exploreButton}
                    onClick={() => history.push('/community')}
                  >
                    Explore Community Lists
                  </button>
                </div>
              )}
            </div>
          )}

          {activeTab === 'perks' && (
            <div className={styles.perksSection}>
              <div className={styles.perksHeader}>
                <h3>Available Perks</h3>
                <span className={styles.perkCount}>{availablePerks.length} perks based on your profile</span>
              </div>
              <div className={styles.perksList}>
                {availablePerks.map(perk => (
                  <div key={perk.id} className={styles.perkItem}>
                    <div className={styles.perkInfo}>
                      <div className={styles.perkTitle}>
                        {perk.type === 'sample' && (
                          <span className={styles.perkBadge}>FREE</span>
                        )}
                        {perk.type === 'discount' && (
                          <span className={styles.perkBadge}>DISCOUNT</span>
                        )}
                        <h4>{perk.title}</h4>
                      </div>
                      <p>{perk.description}</p>
                      <div className={styles.perkExpiry}>
                        Expires {formatDate(perk.expiresAt)}
                      </div>
                    </div>
                    <button 
                      className={styles.claimButton}
                      onClick={() => handleClaimPerk(perk.id)}
                    >
                      Claim
                    </button>
                  </div>
                ))}
              </div>

              <div className={styles.perksHeader}>
                <h3>Claimed Perks</h3>
              </div>
              <div className={styles.perksList}>
                {claimedPerks.length > 0 ? (
                  claimedPerks.map(perk => (
                    <div key={perk.id} className={styles.perkItem}>
                      <div className={styles.perkInfo}>
                        <h4>{perk.title}</h4>
                        <p>Claimed on {formatDate(perk.claimedAt)}</p>
                        <div className={styles.perkStatus}>
                          Status: <span className={styles.statusText}>{perk.status}</span>
                        </div>
                      </div>
                      <div className={styles.claimedBadge}>Claimed</div>
                    </div>
                  ))
                ) : (
                  <div className={styles.emptyPerksState}>
                    <p>You haven't claimed any perks yet.</p>
                    <p>Check out your available perks above!</p>
                  </div>
                )}
              </div>
            </div>
          )}

          {activeTab === 'following' && (
            <div className={styles.followingContainer}>
              {userProfile.stats.following > 0 ? (
                <p>Following content would appear here</p>
              ) : (
                <div className={styles.emptyState}>
                  <p>You're not following anyone yet.</p>
                  <button
                    className={styles.exploreButton}
                    onClick={() => history.push('/community')}
                  >
                    Find Parents to Follow
                  </button>
                </div>
              )}
            </div>
          )}
        </div>
      </div>

      {/* Bottom Navigation */}
      <nav className={styles.bottomNav}>
        <div 
          className={styles.navItem}
          onClick={() => history.push('/')}
        >
          <HomeIcon />
          <span>Home</span>
        </div>
        <div 
          className={styles.navItem}
          onClick={() => history.push('/community')}
        >
          <CommunityIcon />
          <span>Community</span>
        </div>
        <div className={`${styles.navItem} ${styles.active}`}>
          <ProfileIcon />
          <span>Profile</span>
        </div>
      </nav>
    </div>
  );
};

export default ProfileScreen;