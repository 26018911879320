import * as types from './actionTypes';
import initialState from './initialState';

const friends = (state = initialState, action) => {
  switch (action.type) {
    case types.READ_ALL_FRIENDS_SUCCESS:
      return {
        ...state,
        users: action.users
      };

    case types.READ_ALL_FRIENDS_ERROR:
      return {
        ...state,
        readFriendsError: action.readFriendsError
      };

    case types.INVITE_FRIEND_SUCCESS:
      return {
        ...state,
        inviteFriendSuccess: action.inviteFriendSuccess
      };

    case types.INVITE_FRIEND_ERROR:
      return {
        ...state,
        inviteFriendError: action.inviteFriendError
      };

    case types.READ_RECEIVED_FRIEND_REQUESTS_SUCCESS:
      console.log('Reducer handling received requests:', action.requestsReceived);
      return {
        ...state,
        requestsReceived: action.requestsReceived || []
      };

    case types.READ_RECEIVED_FRIEND_REQUESTS_ERROR:
      return {
        ...state,
        readReceivedFriendRequestsError: action.readReceivedFriendRequestsError
      };

    case types.READ_SENT_FRIEND_REQUESTS_SUCCESS:
      console.log('Reducer handling sent requests:', action.requestsSent);
      return {
        ...state,
        requestsSent: action.requestsSent || []
      };

    case types.READ_SENT_FRIEND_REQUESTS_ERROR:
      return {
        ...state,
        readSentFriendRequestsError: action.readSentFriendRequestsError
      };

    case types.ADD_FRIEND_ERROR:
      return {
        ...state,
        addFriendError: action.errMessage
      };

    case types.ADD_FRIEND_SUCCESS:
      return {
        ...state,
        addFriendSuccess: action.bool
      };

    case types.ADD_FRIEND_IN_PROGRESS:
      return {
        ...state,
        addFriendInProgress: action.bool
      };

    case types.REMOVE_FRIEND_ERROR:
      return {
        ...state,
        removeFriendError: action.errMessage
      };

    case types.REMOVE_FRIEND_SUCCESS:
      return {
        ...state,
        removeFriendSuccess: action.bool
      };

    case types.REMOVE_FRIEND_IN_PROGRESS:
      return {
        ...state,
        removeFriendInProgress: action.bool
      };

    case types.REJECT_EMAIL_IN_PROCESS:
      return {
        ...state,
        rejectInviteInProcess: action.rejectInviteInProcess
      };

    case types.REJECT_EMAIL_SUCCESS:
      return {
        ...state,
        friendEmails: state.friendEmails.filter(invite => invite.uuid !== action.invite.uuid)
      };

    case types.REJECT_EMAIL_ERROR:
      return {
        ...state,
        rejectInviteError: action.rejectInviteError
      };

    case types.SET_SELECTED_FRIENDS:
      return {
        ...state,
        selectedFriends: action.selectedFriends
      };

    case types.FETCH_FRIENDS_NOTES_SUCCESS:
      return {
        ...state,
        friendsNotes: action.notes,
        friendsNotesError: null
      };

    case types.FETCH_FRIENDS_NOTES_ERROR:
      return {
        ...state,
        friendsNotesError: action.error
      };
    case types.FETCH_FRIEND_LISTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case types.FETCH_FRIEND_LISTS_SUCCESS:
      return {
        ...state,
        loading: false,
        friendLists: action.payload,
      };
    case types.FETCH_FRIEND_LISTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case types.SELECT_FRIEND_LIST:
      return {
        ...state,
        selectedFriendUuid: action.payload,
      };
    default:
      return state;
  }
};

export default friends;
