import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './ListCreationScreen.module.css';

const templates = [
  { id: "pregnancy", name: "Pregnancy", color: "#f0e6fa" },
  { id: "hospital", name: "Hospital Bag", color: "#e6f5fa" },
  { id: "nursery", name: "Nursery", color: "#fae6f0" },
  { id: "momcare", name: "Mom Care", color: "#e6f5fa" },
  { id: "feeding", name: "Feeding", color: "#f5fae6" },
  { id: "clothing", name: "Clothing", color: "#faf0e6" }
];

const ListCreationScreen = () => {
  const history = useHistory();
  const [listName, setListName] = useState('');
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [nameError, setNameError] = useState('');

  const handleNameChange = (e) => {
    setListName(e.target.value);
    if (e.target.value.trim()) {
      setNameError('');
    }
  };

  const handleTemplateSelect = (templateId) => {
    setSelectedTemplate(templateId);
  };

  const handleCancel = () => {
    history.goBack();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Simple validation
    if (!listName.trim()) {
      setNameError('Please enter a list name');
      return;
    }
    
    if (!selectedTemplate) {
      alert('Please select a template');
      return;
    }

    // Form data to be sent to API (just logging for now)
    const formData = {
      name: listName,
      template: selectedTemplate,
      createdAt: new Date().toISOString()
    };
    
    console.log('Creating new list:', formData);
    
    // In a real app, you would send this data to your API
    // For now, we'll just navigate back to the home screen
    history.push('/');
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button className={styles.backButton} onClick={handleCancel}>
          &larr; Back
        </button>
        <h1 className={styles.title}>Create New List</h1>
      </div>

      <form className={styles.form} onSubmit={handleSubmit}>
        <div className={styles.formGroup}>
          <label htmlFor="listName" className={styles.label}>
            List Name
          </label>
          <input
            id="listName"
            type="text"
            className={styles.input}
            placeholder="Enter a name for your list"
            value={listName}
            onChange={handleNameChange}
          />
          {nameError && <p className={styles.errorText}>{nameError}</p>}
        </div>

        <div className={styles.formGroup}>
          <label className={styles.label}>Select a Template</label>
          <div className={styles.templateGrid}>
            {templates.map((template) => (
              <div
                key={template.id}
                className={`${styles.templateCard} ${
                  selectedTemplate === template.id ? styles.selectedTemplate : ''
                }`}
                style={{ backgroundColor: template.color }}
                onClick={() => handleTemplateSelect(template.id)}
              >
                <div className={styles.templateName}>{template.name}</div>
                {selectedTemplate === template.id && (
                  <div className={styles.checkmark}>✓</div>
                )}
              </div>
            ))}
          </div>
        </div>

        <div className={styles.buttonContainer}>
          <button type="button" className={styles.cancelButton} onClick={handleCancel}>
            Cancel
          </button>
          <button type="submit" className={styles.createButton}>
            Create List
          </button>
        </div>
      </form>
    </div>
  );
};

export default ListCreationScreen;