import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './ListBuildingScreen.module.css';

// Icons (you can replace these with Lucide icons in a real implementation)
const SearchIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
);

const PlusIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <line x1="12" y1="5" x2="12" y2="19"></line>
    <line x1="5" y1="12" x2="19" y2="12"></line>
  </svg>
);

const FilterIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
  </svg>
);

const ShareIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="18" cy="5" r="3"></circle>
    <circle cx="6" cy="12" r="3"></circle>
    <circle cx="18" cy="19" r="3"></circle>
    <line x1="8.59" y1="13.51" x2="15.42" y2="17.49"></line>
    <line x1="15.41" y1="6.51" x2="8.59" y2="10.49"></line>
  </svg>
);

const ChecklistIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M9 11l3 3L22 4"></path>
    <path d="M21 12v7a2 2 0 01-2 2H5a2 2 0 01-2-2V5a2 2 0 012-2h11"></path>
  </svg>
);

const ChevronDownIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polyline points="6 9 12 15 18 9"></polyline>
  </svg>
);

const ChevronUpIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polyline points="18 15 12 9 6 15"></polyline>
  </svg>
);

const TrialIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
  </svg>
);

const ListBuildingScreen = () => {
  const { listId } = useParams();
  const history = useHistory();
  
  // Sample data for demonstration
  const sampleList = {
    id: "list-2",
    title: "Hospital Bag",
    description: "Everything you need for delivery day",
    type: "hospital",
    colorTheme: "blue",
    categories: ["Essentials", "Comfort", "Tech", "Clothing"],
    progress: 0.45, // 45% complete
    items: [
      {
        id: "item-1",
        name: "Extra-long phone charger",
        brand: "Amazon Basics Cable",
        category: "Tech",
        priority: "must-have",
        isAcquired: true,
        trialAvailable: false
      },
      {
        id: "item-2",
        name: "Water bottle w/ straw",
        brand: "Ello Glass Tumbler",
        category: "Essentials",
        priority: "must-have",
        isAcquired: false,
        trialAvailable: true,
        trialDetails: "14-day risk-free trial through BabyGear rental service."
      },
      {
        id: "item-3",
        name: "Shower shoes",
        brand: "Birkenstock Arizona Eva Sandals",
        category: "Clothing",
        priority: "must-have",
        isAcquired: false,
        trialAvailable: false
      },
      {
        id: "item-4",
        name: "Grippy socks",
        brand: "Sticky Grippers",
        category: "Clothing", 
        priority: "nice-to-have",
        isAcquired: true,
        trialAvailable: false
      },
      {
        id: "item-5",
        name: "Electrolytes",
        brand: "Coconut Water Powder",
        category: "Essentials",
        priority: "must-have", 
        isAcquired: true,
        trialAvailable: false
      },
      {
        id: "item-6",
        name: "White noise machine",
        brand: "Hatch Rest",
        category: "Comfort",
        priority: "nice-to-have",
        isAcquired: false,
        trialAvailable: true,
        trialDetails: "30-day trial through manufacturer website"
      },
      {
        id: "item-7",
        name: "Eye mask",
        brand: "Manta Sleep Mask",
        category: "Comfort",
        priority: "nice-to-have",
        isAcquired: false,
        trialAvailable: false
      },
      {
        id: "item-8",
        name: "Bluetooth speaker",
        brand: "JBL Clip 4",
        category: "Tech",
        priority: "nice-to-have",
        isAcquired: false,
        trialAvailable: true,
        trialDetails: "30-day money-back guarantee"
      }
    ]
  };

  // State variables
  const [list, setList] = useState(sampleList);
  const [searchTerm, setSearchTerm] = useState('');
  const [collapsedCategories, setCollapsedCategories] = useState({});
  const [isListTitleEditing, setIsListTitleEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(list.title);
  const [filteredItems, setFilteredItems] = useState(list.items);
  const [progress, setProgress] = useState(0);
  const [isSwipingItem, setIsSwipingItem] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [activeFilters, setActiveFilters] = useState({
    priority: null,
    isAcquired: null,
    trialAvailable: null
  });

  // Initialize items by category
  const [itemsByCategory, setItemsByCategory] = useState({});

  // Effect to organize items by category
  useEffect(() => {
    let items = list.items;
    
    // Filter items based on search term
    if (searchTerm.trim() !== '') {
      items = items.filter(item => 
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.brand.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    
    // Apply filters
    if (activeFilters.priority) {
      items = items.filter(item => item.priority === activeFilters.priority);
    }
    
    if (activeFilters.isAcquired !== null) {
      items = items.filter(item => item.isAcquired === activeFilters.isAcquired);
    }
    
    if (activeFilters.trialAvailable !== null) {
      items = items.filter(item => item.trialAvailable === activeFilters.trialAvailable);
    }
    
    setFilteredItems(items);
    
    // Group by category
    const groupedItems = {};
    list.categories.forEach(category => {
      groupedItems[category] = items.filter(item => item.category === category);
    });
    
    setItemsByCategory(groupedItems);
    
    // Calculate progress
    const acquiredItems = list.items.filter(item => item.isAcquired).length;
    const totalItems = list.items.length;
    setProgress(totalItems > 0 ? acquiredItems / totalItems : 0);
  }, [list, searchTerm, activeFilters]);

  // Handler for toggling category collapse
  const toggleCategory = (category) => {
    setCollapsedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  // Handler for item checkbox
  const handleItemCheck = (itemId) => {
    setList(prevList => {
      const updatedItems = prevList.items.map(item => 
        item.id === itemId 
          ? { ...item, isAcquired: !item.isAcquired } 
          : item
      );
      return { ...prevList, items: updatedItems };
    });
  };

  // Handler for title edit
  const handleTitleEdit = () => {
    setIsListTitleEditing(true);
  };

  // Handler for saving title
  const handleTitleSave = () => {
    if (editedTitle.trim()) {
      setList(prevList => ({ ...prevList, title: editedTitle }));
    } else {
      setEditedTitle(list.title);
    }
    setIsListTitleEditing(false);
  };

  // Handler for deleting item
  const handleDeleteItem = (itemId) => {
    setList(prevList => ({
      ...prevList,
      items: prevList.items.filter(item => item.id !== itemId)
    }));
    setIsSwipingItem(null);
  };

  // Handler for archiving item
  const handleArchiveItem = (itemId) => {
    console.log('Archive item:', itemId);
    // In a real app, you would implement actual archive logic
    setIsSwipingItem(null);
  };

  // Handler for moving items up and down within a category
  const moveItem = (itemId, direction) => {
    const categoryItems = [...itemsByCategory[list.items.find(item => item.id === itemId).category]];
    const itemIndex = categoryItems.findIndex(item => item.id === itemId);
    
    if (
      (direction === 'up' && itemIndex === 0) || 
      (direction === 'down' && itemIndex === categoryItems.length - 1)
    ) {
      return; // Can't move further in this direction
    }
    
    const newIndex = direction === 'up' ? itemIndex - 1 : itemIndex + 1;
    const updatedItems = [...categoryItems];
    [updatedItems[itemIndex], updatedItems[newIndex]] = [updatedItems[newIndex], updatedItems[itemIndex]];
    
    const category = list.items.find(item => item.id === itemId).category;
    setItemsByCategory({
      ...itemsByCategory,
      [category]: updatedItems
    });
    
    // This is just for the mockup - in a real app, you would update the order in your database
  };

  // Handler for navigation to item detail
  const handleItemClick = (itemId) => {
    history.push(`/list/${listId}/item/${itemId}`);
  };

  // Handler for adding new item
  const handleAddItem = () => {
    history.push(`/list/${listId}/add-item`);
  };

  // Handler for sharing
  const handleShare = () => {
    history.push(`/list/${listId}/share`);
  };

  // Handler for navigating to checklist view
  const handleChecklistView = () => {
    history.push(`/list/${listId}/checklist`);
  };

  // Handler for filter button
  const toggleFilterModal = () => {
    setShowFilterModal(!showFilterModal);
  };

  // Handler for filter changes
  const handleFilterChange = (filterType, value) => {
    setActiveFilters(prev => ({
      ...prev,
      [filterType]: prev[filterType] === value ? null : value
    }));
  };

  // Handler for clearing filters
  const clearFilters = () => {
    setActiveFilters({
      priority: null,
      isAcquired: null,
      trialAvailable: null
    });
  };

  // Function to get theme color class
  const getColorClass = () => {
    switch (list.colorTheme) {
      case 'blue': return styles.blueTheme;
      case 'purple': return styles.purpleTheme;
      case 'pink': return styles.pinkTheme;
      case 'green': return styles.greenTheme;
      default: return styles.defaultTheme;
    }
  };

  return (
    <div className={`${styles.container} ${getColorClass()}`}>
      <header className={styles.header}>
        <button className={styles.backButton} onClick={() => history.push('/')}>
          &larr;
        </button>
        
        {isListTitleEditing ? (
          <div className={styles.titleEditContainer}>
            <input
              type="text"
              className={styles.titleInput}
              value={editedTitle}
              onChange={(e) => setEditedTitle(e.target.value)}
              autoFocus
              onBlur={handleTitleSave}
              onKeyPress={(e) => e.key === 'Enter' && handleTitleSave()}
            />
            <button className={styles.saveButton} onClick={handleTitleSave}>
              Save
            </button>
          </div>
        ) : (
          <h1 className={styles.title} onClick={handleTitleEdit}>
            {list.title}
          </h1>
        )}
        
        <div className={styles.headerButtons}>
          <button className={styles.checklistButton} onClick={handleChecklistView}>
            <ChecklistIcon />
          </button>
          <button className={styles.shareButton} onClick={handleShare}>
            <ShareIcon />
          </button>
        </div>
      </header>

      <div className={styles.progressBar}>
        <div 
          className={styles.progressFill} 
          style={{ width: `${progress * 100}%` }}
        ></div>
        <span className={styles.progressText}>
          {Math.round(progress * 100)}% Complete
        </span>
      </div>

      <div className={styles.searchContainer}>
        <SearchIcon />
        <input
          type="text"
          className={styles.searchInput}
          placeholder="Search items..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button 
          className={`${styles.filterButton} ${Object.values(activeFilters).some(filter => filter !== null) ? styles.activeFilter : ''}`} 
          onClick={toggleFilterModal}
        >
          <FilterIcon />
        </button>
      </div>

      {filteredItems.length === 0 ? (
        <div className={styles.emptyState}>
          <p>No items match your search or filters.</p>
          {(searchTerm || Object.values(activeFilters).some(filter => filter !== null)) && (
            <button className={styles.clearButton} onClick={() => {
              setSearchTerm('');
              clearFilters();
            }}>
              Clear Search & Filters
            </button>
          )}
        </div>
      ) : (
        <div className={styles.categoriesContainer}>
          {list.categories.map((category) => (
            itemsByCategory[category]?.length > 0 && (
              <div key={category} className={styles.categorySection}>
                <div 
                  className={styles.categoryHeader}
                  onClick={() => toggleCategory(category)}
                >
                  <h2 className={styles.categoryTitle}>
                    {category} ({itemsByCategory[category]?.length || 0})
                  </h2>
                  {collapsedCategories[category] ? <ChevronDownIcon /> : <ChevronUpIcon />}
                </div>
                
                {!collapsedCategories[category] && (
                  <div className={styles.itemsList}>
                    {itemsByCategory[category]?.map((item, index) => (
                      <div
                        key={item.id}
                        className={`${styles.itemCard} ${item.isAcquired ? styles.acquiredItem : ''}`}
                      >
                        <div className={styles.itemSwipeContainer}>
                          <div className={`${styles.itemSwipeActions} ${isSwipingItem === item.id ? styles.swiping : ''}`}>
                            <button 
                              className={styles.archiveButton}
                              onClick={() => handleArchiveItem(item.id)}
                            >
                              Archive
                            </button>
                            <button 
                              className={styles.deleteButton}
                              onClick={() => handleDeleteItem(item.id)}
                            >
                              Delete
                            </button>
                          </div>
                          
                          <div 
                            className={styles.itemContent}
                            onClick={() => handleItemClick(item.id)}
                            onTouchStart={() => setIsSwipingItem(item.id)}
                            onTouchEnd={() => setIsSwipingItem(null)}
                          >
                            <div className={styles.itemDetails}>
                              <h3 className={styles.itemName}>{item.name}</h3>
                              <p className={styles.itemBrand}>{item.brand}</p>
                              <div className={styles.itemBadges}>
                                {item.priority === 'must-have' && (
                                  <span className={styles.priorityBadge}>Must Have</span>
                                )}
                                {item.trialAvailable && (
                                  <span className={styles.trialBadge}>
                                    <TrialIcon /> Trial before you buy
                                  </span>
                                )}
                              </div>
                            </div>
                            
                            <div className={styles.itemActions}>
                              <div className={styles.itemMoveButtons}>
                                {index > 0 && (
                                  <button 
                                    className={styles.moveButton}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      moveItem(item.id, 'up');
                                    }}
                                  >
                                    ▲
                                  </button>
                                )}
                                {index < itemsByCategory[category].length - 1 && (
                                  <button 
                                    className={styles.moveButton}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      moveItem(item.id, 'down');
                                    }}
                                  >
                                    ▼
                                  </button>
                                )}
                              </div>
                              <label className={styles.checkboxContainer}>
                                <input
                                  type="checkbox"
                                  checked={item.isAcquired}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleItemCheck(item.id);
                                  }}
                                />
                                <span className={styles.checkmark}></span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )
          ))}
        </div>
      )}

      <button className={styles.addButton} onClick={handleAddItem}>
        <PlusIcon />
        <span>Add Item</span>
      </button>

      {showFilterModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.filterModal}>
            <div className={styles.modalHeader}>
              <h2>Filter Items</h2>
              <button 
                className={styles.closeButton}
                onClick={toggleFilterModal}
              >
                &times;
              </button>
            </div>
            
            <div className={styles.filterSection}>
              <h3>Priority</h3>
              <div className={styles.filterOptions}>
                <button 
                  className={`${styles.filterOption} ${activeFilters.priority === 'must-have' ? styles.active : ''}`}
                  onClick={() => handleFilterChange('priority', 'must-have')}
                >
                  Must Have
                </button>
                <button 
                  className={`${styles.filterOption} ${activeFilters.priority === 'nice-to-have' ? styles.active : ''}`}
                  onClick={() => handleFilterChange('priority', 'nice-to-have')}
                >
                  Nice to Have
                </button>
              </div>
            </div>
            
            <div className={styles.filterSection}>
              <h3>Status</h3>
              <div className={styles.filterOptions}>
                <button 
                  className={`${styles.filterOption} ${activeFilters.isAcquired === true ? styles.active : ''}`}
                  onClick={() => handleFilterChange('isAcquired', true)}
                >
                  Acquired
                </button>
                <button 
                  className={`${styles.filterOption} ${activeFilters.isAcquired === false ? styles.active : ''}`}
                  onClick={() => handleFilterChange('isAcquired', false)}
                >
                  Not Acquired
                </button>
              </div>
            </div>
            
            {/* Trial Filter - New Section */}
            <div className={styles.filterSection}>
              <h3>Trial Before Buying</h3>
              <div className={styles.filterOptions}>
                <button 
                  className={`${styles.filterOption} ${activeFilters.trialAvailable === true ? styles.active : ''}`}
                  onClick={() => handleFilterChange('trialAvailable', true)}
                >
                  Trial before you buy
                </button>
                <button 
                  className={`${styles.filterOption} ${activeFilters.trialAvailable === false ? styles.active : ''}`}
                  onClick={() => handleFilterChange('trialAvailable', false)}
                >
                  No Trial
                </button>
              </div>
            </div>
            
            <div className={styles.filterActions}>
              <button 
                className={styles.clearFiltersButton}
                onClick={clearFilters}
              >
                Clear All
              </button>
              <button 
                className={styles.applyFiltersButton}
                onClick={toggleFilterModal}
              >
                Apply Filters
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ListBuildingScreen;