import React, { useState, useEffect } from 'react';
import { Card, Button, Space, Typography, Divider, Steps, Alert, Tag } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const { Title, Text } = Typography;
const { Step } = Steps;

const ChatbotTestHarness = () => {
  const dispatch = useDispatch();
  const [currentTest, setCurrentTest] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [testResults, setTestResults] = useState([]);
  
  // Get relevant Redux states
  const userData = useSelector(state => state.auth?.user || {});
  const categoriesState = useSelector(state => state.categories || {});

  // Define test scenarios with expected Redux state after each step
  const testScenarios = {
    expectingFirst: {
      name: 'Expecting First Baby',
      steps: [
        {
          instruction: 'Type "Hi" in the chatbot',
          expectedResponse: 'Bot should welcome you',
          expectedState: {
            // Initial state should be empty/default
            userType: null,
            pregnancyStatus: null,
            isFirstBaby: null
          }
        },
        {
          instruction: 'Type "I\'m expecting"',
          expectedResponse: 'Bot should ask if baby is born yet',
          expectedState: {
            userType: 'expecting',
            // Redux should update with user type
            verify: (state) => state.auth?.user?.userType === 'expecting'
          }
        },
        {
          instruction: 'Type "Not born yet"',
          expectedResponse: 'Bot should ask for your due date',
          expectedState: {
            userType: 'expecting',
            pregnancyStatus: 'expecting',
            verify: (state) => state.auth?.user?.pregnancyStatus === 'expecting'
          }
        },
        {
          instruction: 'Type "August 15, 2024"',
          expectedResponse: 'Bot should ask if this is your first baby',
          expectedState: {
            userType: 'expecting',
            pregnancyStatus: 'expecting',
            due_date: '2024-08-15',
            verify: (state) => state.auth?.user?.due_date !== undefined
          }
        },
        {
          instruction: 'Type "Yes, first baby"',
          expectedResponse: 'Bot should acknowledge and offer guidance',
          expectedState: {
            userType: 'expecting',
            pregnancyStatus: 'expecting',
            due_date: '2024-08-15',
            isFirstBaby: true,
            verify: (state) => (
              state.auth?.user?.isFirstBaby === true &&
              state.categories?.checkedCategories === undefined
            )
          }
        }
      ]
    },
    experiencedParent: {
      name: 'Experienced Parent',
      steps: [
        {
          instruction: 'Type "Hello"',
          expectedResponse: 'Bot should welcome you',
          expectedState: {
            // Initial state check
            verify: (state) => true
          }
        },
        {
          instruction: 'Type "I\'m expecting my second baby"',
          expectedResponse: 'Bot should ask if baby is born yet',
          expectedState: {
            userType: 'expecting',
            isFirstBaby: false,
            verify: (state) => state.auth?.user?.userType === 'expecting'
          }
        },
        {
          instruction: 'Type "Due in September"',
          expectedResponse: 'Bot should confirm and ask about experience',
          expectedState: {
            userType: 'expecting',
            pregnancyStatus: 'expecting',
            due_date: expect => expect.stringMatching(/2024-09/),
            verify: (state) => (
              state.auth?.user?.due_date?.includes('2024-09') &&
              state.categories?.checkedCategories !== undefined
            )
          }
        }
      ]
    }
  };

  const verifyState = (expectedState) => {
    const results = [];
    
    // Check each expected state property
    Object.entries(expectedState).forEach(([key, value]) => {
      if (key === 'verify') {
        try {
          const verificationResult = value({ auth: { user: userData }, categories: categoriesState });
          results.push({
            check: 'State Verification',
            passed: verificationResult,
            details: verificationResult ? 'State verified' : 'Custom verification failed',
            stateSnapshot: {
              expected: 'Custom verification function',
              actual: JSON.stringify({ user: userData, categories: categoriesState }, null, 2)
            },
            errorCode: verificationResult ? null : 'CUSTOM_VERIFICATION_FAILED'
          });
        } catch (error) {
          results.push({
            check: 'State Verification',
            passed: false,
            details: `Verification function error: ${error.message}`,
            stateSnapshot: {
              expected: 'Custom verification function',
              actual: JSON.stringify({ user: userData, categories: categoriesState }, null, 2)
            },
            errorCode: 'VERIFICATION_FUNCTION_ERROR'
          });
        }
      } else if (userData[key] !== undefined) {
        const matches = value instanceof RegExp ? 
          value.test(userData[key]) : 
          userData[key] === value;
        
        results.push({
          check: `Field: ${key}`,
          passed: matches,
          details: matches ? 
            `Matches expected value: ${value}` : 
            `Value mismatch for ${key}`,
          stateSnapshot: {
            expected: value instanceof RegExp ? value.toString() : value,
            actual: userData[key]
          },
          errorCode: matches ? null : 'VALUE_MISMATCH'
        });
      } else {
        results.push({
          check: `Field: ${key}`,
          passed: false,
          details: `Field "${key}" missing in user data`,
          stateSnapshot: {
            expected: value,
            actual: 'undefined'
          },
          errorCode: 'FIELD_MISSING'
        });
      }
    });

    return results;
  };

  useEffect(() => {
    if (currentTest && currentStep >= 0) {
      const currentScenario = testScenarios[currentTest];
      const stepData = currentScenario.steps[currentStep];
      
      if (stepData.expectedState) {
        const verificationResults = verifyState(stepData.expectedState);
        setTestResults(verificationResults);
      }
    }
  }, [currentStep, userData, categoriesState]);

  const startTest = (scenarioName) => {
    setCurrentTest(scenarioName);
    setCurrentStep(0);
    setTestResults([]);
  };

  const nextStep = () => {
    if (currentStep < testScenarios[currentTest].steps.length - 1) {
      setCurrentStep(prev => prev + 1);
    }
  };

  const currentScenario = currentTest ? testScenarios[currentTest] : null;
  const currentStepData = currentScenario?.steps[currentStep];

  return (
    <div style={{ padding: '10px', borderBottom: '1px solid #f0f0f0' }}>
      <Space direction="vertical" size="small" style={{ width: '100%' }}>
        <div>
          <Space>
            <Button 
              size="small"
              type="primary"
              onClick={() => startTest('expectingFirst')}
            >
              Test: Expecting First Baby
            </Button>
            <Button 
              size="small"
              type="primary"
              onClick={() => startTest('experiencedParent')}
            >
              Test: Experienced Parent
            </Button>
          </Space>
        </div>

        {currentTest && (
          <>
            <Alert
              type="info"
              message="Current Test Step"
              description={
                <div>
                  <Text strong>Do this: </Text>
                  <Text>{currentStepData.instruction}</Text>
                  <br />
                  <Text strong>Expected: </Text>
                  <Text>{currentStepData.expectedResponse}</Text>
                </div>
              }
              style={{ marginBottom: '10px' }}
            />

            <div style={{ maxWidth: 600 }}>
              <Steps 
                current={currentStep}
                size="small"
                style={{ marginBottom: '10px' }}
              >
                {currentScenario.steps.map((step, index) => (
                  <Step 
                    key={index} 
                    title={`Step ${index + 1}`}
                  />
                ))}
              </Steps>
            </div>

            <Space>
              <Button 
                size="small"
                type="primary"
                onClick={nextStep}
                disabled={currentStep >= currentScenario.steps.length - 1}
              >
                Next Step
              </Button>
              <Button 
                size="small"
                onClick={() => {
                  setCurrentStep(0);
                  setCurrentTest(null);
                  setTestResults([]);
                }}
              >
                Reset Test
              </Button>
            </Space>

            {testResults.length > 0 && (
              <div style={{ marginTop: '10px' }}>
                <Text strong>Verification Results:</Text>
                <div style={{ marginTop: '5px' }}>
                  {testResults.map((result, index) => (
                    <div key={index} style={{ marginBottom: '10px' }}>
                      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '3px' }}>
                        <Tag color={result.passed ? 'success' : 'error'}>
                          {result.passed ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
                          <span style={{ marginLeft: '5px' }}>{result.check}</span>
                        </Tag>
                        {!result.passed && (
                          <Tag color="red" style={{ marginLeft: '5px' }}>
                            Error: {result.errorCode}
                          </Tag>
                        )}
                      </div>
                      <div style={{ marginLeft: '5px' }}>
                        <Text type={result.passed ? 'success' : 'danger'}>
                          {result.details}
                        </Text>
                        {!result.passed && result.stateSnapshot && (
                          <div style={{ 
                            backgroundColor: '#fff2f0', 
                            padding: '8px', 
                            borderRadius: '4px',
                            marginTop: '5px',
                            fontSize: '12px'
                          }}>
                            <div><Text strong>Expected:</Text> {result.stateSnapshot.expected}</div>
                            <div><Text strong>Actual:</Text> {result.stateSnapshot.actual}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div style={{ marginTop: '10px' }}>
              <Text strong>Current Redux State:</Text>
              <pre style={{ 
                backgroundColor: '#f5f5f5', 
                padding: '8px',
                borderRadius: '4px',
                fontSize: '12px',
                overflow: 'auto',
                maxHeight: '100px'
              }}>
                {JSON.stringify(userData, null, 2)}
              </pre>
            </div>
          </>
        )}
      </Space>
    </div>
  );
};

export default ChatbotTestHarness;