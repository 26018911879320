import axios from 'axios';
import * as types from './actionTypes';
import { isEmpty } from '../../../app/helperFunctions';

function readAllFriendsSuccess(users) {
  return {
    type: types.READ_ALL_FRIENDS_SUCCESS,
    users: users
  }
}

function readFriendsError(error) {
  return {
    type: types.READ_ALL_FRIENDS_ERROR,
    readFriendsError: error
  }
}

function inviteFriendSuccess(bool) {
  return {
    type: types.INVITE_FRIEND_SUCCESS,
    inviteFriendSuccess: bool
  }
}

function inviteFriendError(error) {
  return {
    type: types.INVITE_FRIEND_ERROR,
    inviteFriendError: error
  }
}

function readReceivedFriendRequestsSuccess(requestsReceived) {
  console.log('Creating success action for received requests:', requestsReceived);
  return {
    type: types.READ_RECEIVED_FRIEND_REQUESTS_SUCCESS,
    requestsReceived: requestsReceived
  };
}


function readReceivedFriendRequestsError(error) {
  return {
    type: types.READ_RECEIVED_FRIEND_REQUESTS_ERROR,
    readReceivedFriendRequestsError: error
  }
}

function readSentFriendRequestsSuccess(requestsSent) {
  console.log('Creating success action for sent requests:', requestsSent);
  return {
    type: types.READ_SENT_FRIEND_REQUESTS_SUCCESS,
    requestsSent: requestsSent
  };
}


function readSentFriendRequestsError(error) {
  return {
    type: types.READ_SENT_FRIEND_REQUESTS_ERROR,
    readSentFriendRequestsError: error
  }
}

function addFriendSuccess(bool) {
  return {
    type: types.ADD_FRIEND_SUCCESS,
    bool
  }
}

function addFriendInProgress(bool) {
  return {
    type: types.ADD_FRIEND_IN_PROGRESS,
    bool
  }
}

function addFriendError(errMessage) {
  return {
    type: types.ADD_FRIEND_ERROR,
    errMessage
  }
}

function removeFriendSuccess(bool) {
  return {
    type: types.REMOVE_FRIEND_SUCCESS,
    bool
  }
}

function removeFriendInProgress(bool) {
  return {
    type: types.REMOVE_FRIEND_IN_PROGRESS,
    bool
  }
}

function removeFriendError(errMessage) {
  return {
    type: types.REMOVE_FRIEND_ERROR,
    errMessage
  }
}

function rejectEmailSuccess(invite) {
  return {
    type: types.REJECT_EMAIL_SUCCESS,
    invite
  }
}

function rejectEmailError(error) {
  return {
    type: types.REJECT_EMAIL_ERROR,
    rejectEmailError: error
  }
}

function rejectEmailInProcess(bool) {
  return {
    type: types.REJECT_EMAIL_IN_PROCESS,
    rejectEmailInProcess: bool
  }
}

export function inviteFriends(email) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/friends/invite`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let data = {
      friends_email: email,
    }
    axios.post(url, data, config)
    .then((response) => {
      if (!isEmpty(response.data)) {
        dispatch(inviteFriendSuccess(response.data));
        // Refresh both sent and received requests
        dispatch(readSentFriendRequests());
        dispatch(readReceivedFriendRequests());
      } else {
        dispatch(inviteFriendError('No Response from API: ' + response.data));
      }
    })
    .catch((error) => {
      let errMessage = 'Error inviting friend(s). Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(inviteFriendError(errMessage));
    })
    .finally(() => {
      dispatch(inviteFriendError(null));
      dispatch(inviteFriendSuccess(null));
    })
  }
}

export function addNewFriend(email) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/friends`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    let data = {
      friends_email: email,
    }
    dispatch(addFriendInProgress(true));
    axios.post(url, data, config)
    .then((response) => {
      if (!isEmpty(response.data)) {
          dispatch(addFriendSuccess(true));
      } else {
        dispatch(addFriendError('No Response from API: ' + response.data));
      }
    })
    .catch((error) => {
      let errMessage = 'Error saving friends data. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(addFriendError(errMessage));
    })
    .finally(() => {
      dispatch(addFriendError(null));
      dispatch(addFriendInProgress(false));
      dispatch(addFriendSuccess(false));
      dispatch(readAllUsersFriends());
      dispatch(readReceivedFriendRequests());
    })
  }
}

export function removeFriend(uuid) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/friends/${uuid}`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    dispatch(removeFriendInProgress(true));
    axios.delete(url, config)
    .then((response) => {
      if (!isEmpty(response.data)) {
          dispatch(removeFriendSuccess(true));
      } else {
        dispatch(removeFriendError('No Response from API: ' + response.data));
      }
    })
    .catch((error) => {
      let errMessage = 'Error saving friends data. Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(removeFriendError(errMessage));
    })
    .finally(() => {
      dispatch(removeFriendError(null));
      dispatch(removeFriendInProgress(false));
      dispatch(removeFriendSuccess(false));
      dispatch(readAllUsersFriends());
    })
  }
}

export function rejectEmail(invite) {
  return (dispatch) => {
    dispatch(rejectEmailError(''));
    dispatch(rejectEmailInProcess(true));

    let url = `${process.env.REACT_APP_API_URL}/emails/${invite.uuid}`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};

    axios.delete(url, config)
    .then(response => {
      dispatch(rejectEmailSuccess(invite));
    })
    .catch((error) => {
      let errMessage = 'Error rejecting invitation';
      if (error.response  !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(rejectEmailError(errMessage));
    })
    .finally(() => {
      dispatch(rejectEmailError(''));
      dispatch(rejectEmailInProcess(false));
      dispatch(readReceivedFriendRequests());
      dispatch(readSentFriendRequests());
    });
  }
}


export function readAllUsersFriends() {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/friends`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    axios.get(url, config)
    .then((response) => {
      if (!isEmpty(response.data)) {
        dispatch(readAllFriendsSuccess(response.data));
      } else {
        dispatch(readAllFriendsSuccess([]));
      }
    })
    .catch((error) => {
      let errMessage = 'Error reading friend(s). Please try again later.';
      if (error.response !== undefined) {
        errMessage = error.response;
        if (typeof error === 'object') {
          if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
            if (typeof error.response.data === 'object') {
              if ('Error' in error.response.data) {
                errMessage = error.response.data['Error'];
              }
            } else {
              errMessage = error.response.data;
            }
          }
        }
      }
      dispatch(readFriendsError(errMessage));
    })
    .finally(() => {
      dispatch(readFriendsError(null));
    })
  }
}

export function readSentFriendRequests() {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/friends/requests/sent/${sessionStorage.getItem('email')}`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    
    axios.get(url, config)
    .then((response) => {
      console.log('Got sent requests data:', response.data); // Debug log
      const requests = Array.isArray(response.data) ? response.data : [];
      dispatch({
        type: types.READ_SENT_FRIEND_REQUESTS_SUCCESS,
        requestsSent: requests
      });
    })
    .catch((error) => {
      console.error('Error fetching sent requests:', error);
      dispatch(readSentFriendRequestsError(error.message));
    });
  }
}


export function readReceivedFriendRequests() {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/users/friends/requests/received/${sessionStorage.getItem('email')}`;
    let config = {headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')}};
    
    axios.get(url, config)
    .then((response) => {
      console.log('Got received requests data:', response.data); // Debug log
      const requests = Array.isArray(response.data) ? response.data : [];
      dispatch({
        type: types.READ_RECEIVED_FRIEND_REQUESTS_SUCCESS,
        requestsReceived: requests
      });
    })
    .catch((error) => {
      console.error('Error fetching received requests:', error);
      dispatch(readReceivedFriendRequestsError(error.message));
    });
  }
}


export function setSelectedFriends(selectedFriends) {
  return {
    type: types.SET_SELECTED_FRIENDS,
    selectedFriends
  };
}

export function updateSelectedFriends(friendUuids) {
  return (dispatch) => {
    dispatch(setSelectedFriends(friendUuids));
    dispatch(fetchNotesForSelectedFriends(friendUuids));
  };
}

export function fetchNotesForSelectedFriends(friendUuids) {
  return (dispatch) => {
    let url = `${process.env.REACT_APP_API_URL}/notes`;
    let config = {
      headers: {Authorization: 'Bearer '+sessionStorage.getItem('jwt')},
      params: { friendUuids: friendUuids.join(',') }
    };

    axios.get(url, config)
      .then(response => {
        dispatch({
          type: types.FETCH_FRIENDS_NOTES_SUCCESS,
          notes: response.data
        });
      })
      .catch(error => {
        dispatch({
          type: types.FETCH_FRIENDS_NOTES_ERROR,
          error: error.message
        });
      });
  };
}

export const fetchFriendListsRequest = () => ({ type: types.FETCH_FRIEND_LISTS_REQUEST });
export const fetchFriendListsSuccess = (friendLists) => ({ type: types.FETCH_FRIEND_LISTS_SUCCESS, payload: friendLists });
export const fetchFriendListsFailure = (error) => ({ type: types.FETCH_FRIEND_LISTS_FAILURE, payload: error });

export const fetchFriendLists = () => {
  return async (dispatch) => {
    dispatch(fetchFriendListsRequest());
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/friends`, {
        headers: { Authorization: 'Bearer ' + sessionStorage.getItem('jwt') }
      });
      dispatch(fetchFriendListsSuccess(response.data));
    } catch (error) {
      dispatch(fetchFriendListsFailure(error.message));
    }
  };
};

export const selectFriendList = (friendUuid) => ({
  type: types.SELECT_FRIEND_LIST,
  payload: friendUuid
});
