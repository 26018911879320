import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styles from './AddEditItemScreen.module.css';

const AddEditItemScreen = () => {
  const { listId, itemId } = useParams();
  const history = useHistory();
  const isEditMode = !!itemId;

  // Define categories and options
  const categories = [
    "Essentials", "Comfort", "Tech", "Clothing", "Feeding"
  ];
  
  const ageRanges = [
    { id: "n-plus", label: "N+" },
    { id: "0-3m", label: "0-3m" },
    { id: "3-6m", label: "3-6m" },
    { id: "6m-plus", label: "6m+" }
  ];
  
  const priceRanges = [
    { id: "low", label: "$" },
    { id: "medium", label: "$$" },
    { id: "high", label: "$$$" }
  ];

  // Form state
  const [formData, setFormData] = useState({
    name: '',
    brand: '',
    category: '',
    priority: 'nice-to-have',
    ageRange: '',
    priceRange: '',
    purchaseLink: '',
    notes: '',
    trialAvailable: false, // Added trial availability field
    trialDetails: '', // Added field for trial details
  });

  // Validation state
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});

  // If in edit mode, fetch item data
  useEffect(() => {
    if (isEditMode) {
      // In a real app, you would fetch this from an API
      // For now, we'll simulate with mock data
      const mockItemData = {
        id: itemId,
        name: "Baby carrier",
        brand: "Ergobaby Omni 360",
        category: "Essentials",
        priority: "must-have",
        ageRange: "n-plus",
        priceRange: "high",
        purchaseLink: "https://example.com/carrier",
        notes: "Great for walks and keeping hands free. Used it almost every day for the first 6 months.",
        trialAvailable: true,
        trialDetails: "Available for 30-day trial through manufacturer website."
      };

      setFormData(mockItemData);
    }
  }, [isEditMode, itemId]);

  // Handle field changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Handle checkbox inputs separately
    const newValue = type === 'checkbox' ? checked : value;
    
    setFormData({
      ...formData,
      [name]: newValue
    });

    // Mark field as touched
    if (!touched[name]) {
      setTouched({
        ...touched,
        [name]: true
      });
    }
  };

  // Handle radio/toggle selection
  const handleSelectionChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value
    });

    // Mark field as touched
    if (!touched[field]) {
      setTouched({
        ...touched,
        [field]: true
      });
    }
  };

  // Validate form
  const validateForm = () => {
    const newErrors = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Item name is required';
    }
    
    if (!formData.category) {
      newErrors.category = 'Please select a category';
    }
    
    if (!formData.ageRange) {
      newErrors.ageRange = 'Please select an age range';
    }
    
    if (!formData.priceRange) {
      newErrors.priceRange = 'Please select a price range';
    }

    // Optional validation for URLs
    if (formData.purchaseLink && !formData.purchaseLink.startsWith('http')) {
      newErrors.purchaseLink = 'Please enter a valid URL starting with http:// or https://';
    }

    // If trial is available, require details
    if (formData.trialAvailable && !formData.trialDetails.trim()) {
      newErrors.trialDetails = 'Please provide trial details';
    }

    setErrors(newErrors);
    
    // Return true if no errors
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    
    // Mark all fields as touched for validation
    const allTouched = {};
    Object.keys(formData).forEach(key => {
      allTouched[key] = true;
    });
    setTouched(allTouched);
    
    if (validateForm()) {
      // In a real app, you would submit to an API
      console.log('Submitting form data:', formData);
      
      // Navigate back to list detail
      history.push(`/list/${listId}`);
    } else {
      console.log('Form has errors');
    }
  };

  // Handle cancel
  const handleCancel = () => {
    history.push(`/list/${listId}`);
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <button 
          className={styles.backButton} 
          onClick={handleCancel}
          aria-label="Go back"
        >
          &larr;
        </button>
        <h1 className={styles.title}>
          {isEditMode ? 'Edit Item' : 'Add New Item'}
        </h1>
      </header>

      <form className={styles.form} onSubmit={handleSubmit}>
        {/* Item Name */}
        <div className={styles.formGroup}>
          <label htmlFor="name" className={styles.label}>
            Item Name <span className={styles.required}>*</span>
          </label>
          <input
            id="name"
            name="name"
            type="text"
            className={`${styles.input} ${touched.name && errors.name ? styles.inputError : ''}`}
            placeholder="Enter item name"
            value={formData.name}
            onChange={handleChange}
          />
          {touched.name && errors.name && (
            <p className={styles.errorText}>{errors.name}</p>
          )}
        </div>

        {/* Brand */}
        <div className={styles.formGroup}>
          <label htmlFor="brand" className={styles.label}>
            Brand / Model
          </label>
          <input
            id="brand"
            name="brand"
            type="text"
            className={styles.input}
            placeholder="Enter brand or model"
            value={formData.brand}
            onChange={handleChange}
          />
        </div>

        {/* Category */}
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Category <span className={styles.required}>*</span>
          </label>
          <div className={styles.selectContainer}>
            <select
              name="category"
              className={`${styles.select} ${touched.category && errors.category ? styles.inputError : ''}`}
              value={formData.category}
              onChange={handleChange}
            >
              <option value="">Select a category</option>
              {categories.map(category => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          {touched.category && errors.category && (
            <p className={styles.errorText}>{errors.category}</p>
          )}
        </div>

        {/* Priority */}
        <div className={styles.formGroup}>
          <label className={styles.label}>Priority</label>
          <div className={styles.toggleContainer}>
            <button
              type="button"
              className={`${styles.toggleButton} ${formData.priority === 'must-have' ? styles.toggleActive : ''}`}
              onClick={() => handleSelectionChange('priority', 'must-have')}
            >
              Must Have
            </button>
            <button
              type="button"
              className={`${styles.toggleButton} ${formData.priority === 'nice-to-have' ? styles.toggleActive : ''}`}
              onClick={() => handleSelectionChange('priority', 'nice-to-have')}
            >
              Nice to Have
            </button>
          </div>
        </div>

        {/* Age Range */}
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Age Range <span className={styles.required}>*</span>
          </label>
          <div className={styles.radioGroup}>
            {ageRanges.map(range => (
              <label key={range.id} className={styles.radioLabel}>
                <input
                  type="radio"
                  name="ageRange"
                  value={range.id}
                  checked={formData.ageRange === range.id}
                  onChange={() => handleSelectionChange('ageRange', range.id)}
                  className={styles.radioInput}
                />
                <span className={styles.radioText}>{range.label}</span>
              </label>
            ))}
          </div>
          {touched.ageRange && errors.ageRange && (
            <p className={styles.errorText}>{errors.ageRange}</p>
          )}
        </div>

        {/* Price Range */}
        <div className={styles.formGroup}>
          <label className={styles.label}>
            Price Range <span className={styles.required}>*</span>
          </label>
          <div className={styles.radioGroup}>
            {priceRanges.map(range => (
              <label key={range.id} className={styles.radioLabel}>
                <input
                  type="radio"
                  name="priceRange"
                  value={range.id}
                  checked={formData.priceRange === range.id}
                  onChange={() => handleSelectionChange('priceRange', range.id)}
                  className={styles.radioInput}
                />
                <span className={styles.radioText}>{range.label}</span>
              </label>
            ))}
          </div>
          {touched.priceRange && errors.priceRange && (
            <p className={styles.errorText}>{errors.priceRange}</p>
          )}
        </div>

        {/* Trial Before Buying */}
        <div className={styles.formGroup}>
          <div className={styles.checkboxContainer}>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                name="trialAvailable"
                checked={formData.trialAvailable}
                onChange={handleChange}
                className={styles.checkbox}
              />
              <span className={styles.checkboxText}>Trial before you buy Before Buying</span>
            </label>
          </div>
          
          {formData.trialAvailable && (
            <div className={styles.subFormGroup}>
              <label htmlFor="trialDetails" className={styles.label}>
                Trial Details <span className={styles.required}>*</span>
              </label>
              <textarea
                id="trialDetails"
                name="trialDetails"
                className={`${styles.textarea} ${touched.trialDetails && errors.trialDetails ? styles.inputError : ''}`}
                placeholder="Provide details about the trial program (duration, where to find it, etc.)"
                value={formData.trialDetails}
                onChange={handleChange}
                rows={3}
              />
              {touched.trialDetails && errors.trialDetails && (
                <p className={styles.errorText}>{errors.trialDetails}</p>
              )}
            </div>
          )}
        </div>

        {/* Purchase Link */}
        <div className={styles.formGroup}>
          <label htmlFor="purchaseLink" className={styles.label}>
            Purchase Link
          </label>
          <input
            id="purchaseLink"
            name="purchaseLink"
            type="url"
            className={`${styles.input} ${touched.purchaseLink && errors.purchaseLink ? styles.inputError : ''}`}
            placeholder="https://example.com/product"
            value={formData.purchaseLink}
            onChange={handleChange}
          />
          {touched.purchaseLink && errors.purchaseLink && (
            <p className={styles.errorText}>{errors.purchaseLink}</p>
          )}
        </div>

        {/* Notes */}
        <div className={styles.formGroup}>
          <label htmlFor="notes" className={styles.label}>
            Notes
          </label>
          <textarea
            id="notes"
            name="notes"
            className={styles.textarea}
            placeholder="Add your personal experience or recommendations"
            value={formData.notes}
            onChange={handleChange}
            rows={4}
          />
        </div>

        {/* Form Actions */}
        <div className={styles.actionButtons}>
          <button 
            type="button" 
            className={styles.cancelButton}
            onClick={handleCancel}
          >
            Cancel
          </button>
          <button 
            type="submit" 
            className={styles.saveButton}
          >
            {isEditMode ? 'Update Item' : 'Add Item'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddEditItemScreen;