import axios from 'axios';

import { clearEmailsData } from '../emails/actions';
import { setSuperuser } from '../users/actions';

import * as types from './actionTypes';
import { loadSavedCategoryStates } from '../categories/actions';

import { clearCategoryStates } from '../categories/actions';

import { LOAD_SAVED_CATEGORY_STATES } from '../categories/actionTypes';  // Import from categories


function registerInProcess(bool) {
  return {
    type: types.REGISTER_IN_PROCESS,
    registerInProcess: bool
  }
}

function registerError(error) {
  return {
    type: types.REGISTER_ERROR,
    registerError: error
  }
}

function registerSuccess() {
  return {
    type: types.REGISTER_SUCCESS
  }
}

function resetPasswordInProcess(bool) {
  return {
    type: types.RESET_PASSWORD_IN_PROCESS,
    resetPasswordInProcess: bool
  }
}

function resetPasswordError(error) {
  return {
    type: types.RESET_PASSWORD_ERROR,
    resetPasswordError: error
  }
}

function resetPasswordSuccess() {
  return {
    type: types.RESET_PASSWORD_SUCCESS
  }
}

export function loginInProcess(bool) {
  return {
    type: types.LOGIN_IN_PROCESS,
    loginInProcess: bool
  }
}

export function loginError(error) {
  return {
    type: types.LOGIN_ERROR,
    loginError: error
  }
}

export function loginSuccess(user) {
  console.log("userData on login (redux/authentication/actions.js line 69):", user);
  return {
    type: types.LOGIN_SUCCESS,
    user
  }
}

function sendRegistrationInProcess(bool) {
  return {
    type: types.SEND_REGISTRATION_IN_PROCESS,
    sendRegistrationInProcess: bool
  }
}

function sendRegistrationSuccess(bool) {
  return {
    type: types.SEND_REGISTRATION_SUCCESS,
    sendRegistrationSuccess: bool
  }
}

function sendRegistrationError(error) {
  return {
    type: types.SEND_REGISTRATION_ERROR,
    sendRegistrationError: error
  }
}

function sendWaitlistInProcess(bool) {
  return {
    type: types.SEND_WAITLIST_IN_PROCESS,
    sendWaitlistInProcess: bool
  }
}

function sendWaitlistSuccess(bool) {
  return {
    type: types.SEND_WAITLIST_SUCCESS,
    sendWaitlistSuccess: bool
  }
}

function sendWaitlistError(error) {
  return {
    type: types.SEND_WAITLIST_ERROR,
    sendWaitlistError: error
  }
}

function getEmailInProcess(bool) {
  return {
    type: types.GET_EMAIL_IN_PROCESS,
    getEmailInProcess: bool
  }
}

function getEmailSuccess(email) {
  return {
    type: types.GET_EMAIL_SUCCESS,
    email
  }
}

function getEmailError(error) {
  return {
    type: types.GET_EMAIL_ERROR,
    getEmailError: error
  }
}

function sendPasswordResetRequestInProcess(bool) {
  return {
    type: types.SEND_PASSWORD_RESET_REQUEST_IN_PROCESS,
    sendPasswordResetRequestInProcess: bool
  }
}

function sendPasswordResetRequestSuccess(bool) {
  return {
    type: types.SEND_PASSWORD_RESET_REQUEST_SUCCESS,
    sendPasswordResetRequestSuccess: bool
  }
}

function sendPasswordResetRequestError(error) {
  return {
    type: types.SEND_PASSWORD_RESET_REQUEST_ERROR,
    sendPasswordResetRequestError: error
  }
}

function getPasswordResetRequestInProcess(bool) {
  return {
    type: types.GET_PASSWORD_RESET_REQUEST_IN_PROCESS,
    getPasswordResetRequestInProcess: bool
  }
}

function getPasswordResetRequestSuccess(request) {
  return {
    type: types.GET_PASSWORD_RESET_REQUEST_SUCCESS,
    request
  }
}

function getPasswordResetRequestError(error) {
  return {
    type: types.GET_PASSWORD_RESET_REQUEST_ERROR,
    getPasswordResetRequestError: error
  }
}

export function loginMissing() {
  return {
    type: types.LOGIN_MISSING,
    user: null
  }
}

export function logoutSuccess() {
  return {
    type: types.LOGOUT_SUCCESS,
    user: null
  }
}

export function resetRegisterSuccess() {
  return {
    type: types.RESET_REGISTER_SUCCESS
  }
}

export function resetResetPasswordSuccess() {
  return {
    type: types.RESET_RESET_PASSWORD_SUCCESS
  }
}

export function checkLogin() {
  return async (dispatch) => {
    console.group('🔍 checkLogin');
    const jwt = sessionStorage.getItem('jwt');

    if (jwt) {
      try {
        const url = `${process.env.REACT_APP_API_URL}/users`;
        const response = await axios.get(url, {
          headers: {Authorization: 'Bearer ' + jwt}
        });

        console.log('Session check successful');

        const user = {
          ...response.data,
          jwt: jwt
        };

        // Only dispatch these if we don't already have a refresh token running
        if (window.jwtTokenRefresh === null) {
          dispatch(loginSuccess(user));
          dispatch(loadSavedCategoryStates());
          // Start new token refresh cycle without recursion
          const email = sessionStorage.getItem('email');
          const password = sessionStorage.getItem('password');
          if (email && password) {
            window.jwtTokenRefresh = setInterval(() => {
              dispatch(login(email, password));
            }, 10 * 60 * 1000);
          }
        }
      } catch (error) {
        console.error('Session check failed:', error);
        sessionStorage.removeItem('jwt');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('password');
        if (window.jwtTokenRefresh !== null) {
          clearInterval(window.jwtTokenRefresh);
          window.jwtTokenRefresh = null;
        }
        dispatch(loginMissing());
      }
    } else {
      console.log('No JWT found in session storage');
      dispatch(loginMissing());
    }
    console.groupEnd();
  };
}

export function register(email, firstName, lastName, password, confirmPassword, token, type) {
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch(registerError(''));
      dispatch(registerInProcess(true));
      const url = `${process.env.REACT_APP_API_URL}/users`;
      const data = {
        auth_token: token,
        email: email,
        first_name: firstName,
        last_name: lastName,
        type: type,
        password: password,
        confirm_password: confirmPassword
      };

      axios.post(url, data)
        .then((response) => {
          dispatch(registerSuccess());
          resolve(response);
        })
        .catch((error) => {
          let errMessage = 'Error registering. Please try again later';
          if (error.response !== undefined) {
            errMessage = error.response;
            if (typeof error === 'object') {
              if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
                if (typeof error.response.data === 'object') {
                  if ('Error' in error.response.data) {
                    errMessage = error.response.data['Error'];
                  }
                } else {
                  errMessage = error.response.data;
                }
              }
            }
          }
          dispatch(registerError(errMessage));
          reject(error);
        })
        .finally(() => {
          dispatch(registerInProcess(false));
        });
    });
  }
}

export function resetPassword(new_password, confirmPassword, token) {
  return (dispatch) => {
    dispatch(resetPasswordError(''));
    dispatch(resetPasswordInProcess(true));

    const url = `${process.env.REACT_APP_API_URL}/reset-password/${token}`;
    const data = {new_password};

    axios.patch(url, data)
      .then((response) => {
        dispatch(resetPasswordSuccess());
      })
      .catch((error) => {
        let errMessage = 'Error resetting your password. Please try again later';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(resetPasswordError(errMessage));
      })
      .finally(() => {
        dispatch(resetPasswordError(''));
        dispatch(resetPasswordInProcess(false));
      });
  }
}

window.jwtTokenRefresh = null;

export function login(email, password) {
  return async (dispatch) => {
    dispatch(loginInProcess(true));
    dispatch(loginError(''));

    const cleanEmail = email.trim().toLowerCase();
    const cleanPassword = password.trim();

    if (!cleanEmail) {
      dispatch(loginInProcess(false));
      dispatch(loginError('Please enter an email'));
      return;
    }
    else if (!cleanPassword) {
      dispatch(loginInProcess(false));
      dispatch(loginError('Please enter a password'));
      return;
    }

    const loginUrl = `${process.env.REACT_APP_API_URL}/authenticate`;  // Define URL here

    console.group('🔐 login');
    console.log('Attempting login for:', cleanEmail);

    try {
      const response = await axios.post(loginUrl, {email: cleanEmail, password: cleanPassword});

      if (response.data && response.data.jwt) {
        console.log('Login successful, loading saved states');
        const user = {
          ...response.data.user,
          jwt: response.data.jwt
        };

        // Store auth data
        sessionStorage.setItem('jwt', response.data.jwt);
        sessionStorage.setItem('email', cleanEmail);
        sessionStorage.setItem('password', cleanPassword);
        sessionStorage.setItem('userUuid', response.data.user.uuid);

        // Load saved category states from localStorage
        const savedStates = JSON.parse(localStorage.getItem(
          `categoryStates_${response.data.user.uuid}`
        ) || '{}');

        console.log('Loaded saved states:', savedStates);

        // Dispatch saved states immediately
        dispatch({
          type: LOAD_SAVED_CATEGORY_STATES,
          payload: {
            checkedCategories: savedStates.checkedCategories || {},
            userSelectedCategories: savedStates.userSelectedCategories || [],
            selectedProducts: savedStates.selectedProducts || {}
          }
        });

        dispatch(setSuperuser(cleanEmail === 'james@frankel.tv'));
        dispatch(loginSuccess(user));

        // Set up token refresh
        if (window.jwtTokenRefresh === null) {
          window.jwtTokenRefresh = setInterval(() => {
            const currentJwt = sessionStorage.getItem('jwt');
            if (currentJwt) {
              dispatch(login(cleanEmail, cleanPassword))
                .catch(refreshError => {
                  console.error('Token refresh failed:', refreshError);
                  // Handle failed refresh - maybe logout user if refresh fails
                  dispatch(logout());
                });
            }
          }, 10 * 60 * 1000); // 10 minutes
        }
      } else {
        console.error('Invalid server response:', response);
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Login error:', error);
      let errMessage = 'Error logging in';
      if (error.response?.data?.Error) {
        errMessage = error.response.data.Error;
      } else if (error.message) {
        errMessage = error.message;
      }
      dispatch(loginError(errMessage));
    } finally {
      dispatch(loginInProcess(false));
      console.groupEnd();
    }
  };
}

export function logout(user) {
  return (dispatch, getState) => {
    console.group('🚪 logout');

    // Before clearing, save current state to localStorage
    const currentState = getState().categories;
    const userUuid = sessionStorage.getItem('userUuid');

    console.log('Current state before logout:', currentState);
    console.log('UserUUID:', userUuid);

    if (userUuid) {
      const stateToSave = {
        checkedCategories: currentState.checkedCategories,
        userSelectedCategories: currentState.userSelectedCategories,
        selectedProducts: currentState.selectedProducts
      };

      console.log('Saving state to localStorage:', stateToSave);
      localStorage.setItem(`categoryStates_${userUuid}`, JSON.stringify(stateToSave));
      console.log('Verification - saved state:', localStorage.getItem(`categoryStates_${userUuid}`));
    }

    // Clear session storage
    sessionStorage.removeItem('jwt');
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('password');
    sessionStorage.removeItem('userUuid');

    if (window.jwtTokenRefresh !== null) {
      window.clearInterval(window.jwtTokenRefresh);
      window.jwtTokenRefresh = null;
    }

    console.log('Session storage cleared');
    console.groupEnd();

    dispatch(logoutSuccess());
  };
}


export function clearAssociatedData() {
  return (dispatch) => {
    dispatch(clearEmailsData());
    dispatch(clearCategoryStates());
  }
}

export function sendRegistrationEmail(email) {
  return (dispatch) => {
    dispatch(sendRegistrationError(''));
    dispatch(sendRegistrationInProcess(true));

    const invitationEmail = email.trim();
    let url = `${process.env.REACT_APP_API_URL}/emails/register`;
    let data = {email: invitationEmail};

    axios.post(url, data)
      .then(resp => {
        dispatch(sendRegistrationSuccess(true));
      })
      .catch(error => {
        let errMessage = 'Error sending invitation. Please try again later.';
        if (error.response && error.response.data) {
          if (typeof error.response.data === 'object' && error.response.data.Error) {
            errMessage = error.response.data.Error;
          } else if (typeof error.response.data === 'string') {
            errMessage = error.response.data;
          }

          // Set specific error codes for the switch statement in RegisterModal
          if (errMessage.includes('already exists')) {
            errMessage = 'EMAIL_EXISTS';
          } else if (errMessage.includes('invalid email')) {
            errMessage = 'INVALID_EMAIL';
          }
        }
        dispatch(sendRegistrationError(errMessage));
      })
      .finally(() => {
        // Only clear processing state, not error or success
        dispatch(sendRegistrationInProcess(false));
      });
  }
}

export function sendWaitlistEmail(email) {
  return (dispatch) => {
    dispatch(sendWaitlistError(''));
    dispatch(sendWaitlistInProcess(true));

    const invitationEmail = email.trim();
    let url = `${process.env.REACT_APP_API_URL}/emails/register`;
    let data = {email: invitationEmail};

    axios.post(url, data)
      .then(resp => {
        dispatch(sendWaitlistSuccess(true));
      })
      .catch(error => {
        let errMessage = 'Error sending invitation. Please try again later.';
        if (error.response && error.response.data) {
          if (typeof error.response.data === 'object' && error.response.data.Error) {
            errMessage = error.response.data.Error;
          } else if (typeof error.response.data === 'string') {
            errMessage = error.response.data;
          }

          // Set specific error codes for the switch statement in RegisterModal
          if (errMessage.includes('already exists')) {
            errMessage = 'EMAIL_EXISTS';
          } else if (errMessage.includes('invalid email')) {
            errMessage = 'INVALID_EMAIL';
          }
        }
        dispatch(sendWaitlistError(errMessage));
      })
      .finally(() => {
        // Only clear processing state, not error or success
        dispatch(sendWaitlistInProcess(false));
      });
  }
}

export function getEmail(token) {
  return (dispatch) => {
    dispatch(getEmailError(''));
    dispatch(getEmailInProcess(true));

    let url = `${process.env.REACT_APP_API_URL}/emails/${token}`;
    axios.get(url)
      .then(resp => {
        dispatch(getEmailSuccess(resp.data));
      })
      .catch(error => {
        let errMessage = 'Error getting invitation. Please try again later.';
        if (error.response !== undefined) {
          errMessage = error.response;
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(getEmailError(errMessage));
      })
      .finally(() => {
        dispatch(getEmailError(''));
        dispatch(getEmailInProcess(false));
      });
  }
}

export function sendPasswordResetRequestEmail(email) {
  return (dispatch) => {
    dispatch(sendPasswordResetRequestError(''));
    dispatch(sendPasswordResetRequestInProcess(true));

    const passwordResetRequestEmail = email.trim()
    let url = `${process.env.REACT_APP_API_URL}/forgot-password`;
    let data = {email: passwordResetRequestEmail};

    axios.post(url, data)
      .then(resp => {
        dispatch(sendPasswordResetRequestSuccess(true));
        dispatch(sendPasswordResetRequestSuccess(false));
      })
      .catch(error => {
        let errMessage = 'Sorry, we can\'t find that email.';
        if (typeof error.response !== 'undefined') {
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(sendPasswordResetRequestError(errMessage));
      })
      .finally(() => {
        dispatch(sendPasswordResetRequestError(''));
        dispatch(sendPasswordResetRequestInProcess(false));
      })
  }
}

export function getPasswordResetRequest(token) {
  return (dispatch) => {
    dispatch(getPasswordResetRequestError(''));
    dispatch(getPasswordResetRequestInProcess(true));

    let url = `${process.env.REACT_APP_API_URL}/emails/${token}`;
    axios.get(url)
      .then(response => {
        dispatch(getPasswordResetRequestSuccess(response.data));
      })
      .catch(error => {
        let errMessage = 'Error getting Valid Password Reset Request.';
        if (typeof error.response !== 'undefined') {
          if (typeof error === 'object') {
            if (error.hasOwnProperty('response') && error.response.hasOwnProperty('data')) {
              if (typeof error.response.data === 'object') {
                if ('Error' in error.response.data) {
                  errMessage = error.response.data['Error'];
                }
              } else {
                errMessage = error.response.data;
              }
            }
          }
        }
        dispatch(getPasswordResetRequestError(errMessage));
      })
      .finally(() => {
        dispatch(getPasswordResetRequestError(''));
        dispatch(getPasswordResetRequestInProcess(false));
      })
  }
}
