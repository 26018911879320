import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './CommunityScreen.module.css';

// Icons (you can replace these with Lucide icons in a real implementation)
const SearchIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <circle cx="11" cy="11" r="8"></circle>
    <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
  </svg>
);

const FilterIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"></polygon>
  </svg>
);

const BookmarkIcon = ({ filled }) => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill={filled ? "currentColor" : "none"} stroke="currentColor" strokeWidth="2">
    <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path>
  </svg>
);

const TrialIcon = () => (
  <svg className={styles.icon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
  </svg>
);

const HomeIcon = () => (
  <svg className={styles.navIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M3 9l9-7 9 7v11a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path>
    <polyline points="9 22 9 12 15 12 15 22"></polyline>
  </svg>
);

const CommunityIcon = () => (
  <svg className={styles.navIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2"></path>
    <circle cx="9" cy="7" r="4"></circle>
    <path d="M23 21v-2a4 4 0 00-3-3.87"></path>
    <path d="M16 3.13a4 4 0 010 7.75"></path>
  </svg>
);

const ProfileIcon = () => (
  <svg className={styles.navIcon} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
    <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
    <circle cx="12" cy="7" r="4"></circle>
  </svg>
);

// Avatar component
const Avatar = ({ initials }) => {
  return (
    <div className={styles.avatar}>
      <span className={styles.initials}>{initials}</span>
    </div>
  );
};

const CommunityScreen = () => {
  const history = useHistory();
  
  // Mock data for lists
  const popularLists = [
    {
      id: "community-1",
      title: "Jessica's Minimalist Newborn List",
      description: "20 essential items only",
      creator: {
        id: "user-2",
        name: "Jessica M",
        initials: "JM"
      },
      savedCount: 347,
      colorTheme: "purple",
      trialItemsCount: 8,
      hasTrialItems: true
    },
    {
      id: "community-2",
      title: "Ashley's Budget Baby Registry",
      description: "All under $40 per item",
      creator: {
        id: "user-3",
        name: "Ashley M",
        initials: "AM"
      },
      savedCount: 289,
      colorTheme: "blue",
      trialItemsCount: 0,
      hasTrialItems: false
    },
    {
      id: "community-3",
      title: "Emma's Twin Essentials",
      description: "Double trouble preparation",
      creator: {
        id: "user-4",
        name: "Emma M",
        initials: "EM"
      },
      savedCount: 156,
      colorTheme: "pink",
      trialItemsCount: 12,
      hasTrialItems: true
    },
    {
      id: "community-4",
      title: "Lisa's Breastfeeding Must-Haves",
      description: "Nursing essentials",
      creator: {
        id: "user-5",
        name: "Lisa B",
        initials: "LB"
      },
      savedCount: 431,
      colorTheme: "green",
      trialItemsCount: 5,
      hasTrialItems: true
    }
  ];

  // Filter options
  const ageRanges = [
    { id: "all", label: "All Ages" },
    { id: "pregnancy", label: "Pregnancy" },
    { id: "newborn", label: "Newborn" },
    { id: "0-3m", label: "0-3m" },
    { id: "3-6m", label: "3-6m" },
    { id: "6m-plus", label: "6m+" }
  ];

  const categories = [
    { id: "all", label: "All Categories" },
    { id: "essentials", label: "Essentials" },
    { id: "feeding", label: "Feeding" },
    { id: "nursery", label: "Nursery" },
    { id: "clothing", label: "Clothing" },
    { id: "hospital", label: "Hospital" }
  ];

  const trialOptions = [
    { id: "all", label: "Any Lists" },
    { id: "has-trials", label: "With Trials" }
  ];

  // State for active tab
  const [activeTab, setActiveTab] = useState('popular');
  
  // State for search
  const [searchTerm, setSearchTerm] = useState('');
  
  // State for filters
  const [activeAgeFilter, setActiveAgeFilter] = useState('all');
  const [activeCategoryFilter, setActiveCategoryFilter] = useState('all');
  const [activeTrialFilter, setActiveTrialFilter] = useState('all');
  
  // State for saved lists
  const [savedLists, setSavedLists] = useState({});

  // Handle tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle age filter change
  const handleAgeFilterChange = (filterId) => {
    setActiveAgeFilter(filterId);
  };

  // Handle category filter change
  const handleCategoryFilterChange = (filterId) => {
    setActiveCategoryFilter(filterId);
  };

  // Handle trial filter change
  const handleTrialFilterChange = (filterId) => {
    setActiveTrialFilter(filterId);
  };

  // Handle list click - navigate to shared list view
  const handleListClick = (listId) => {
    history.push(`/shared-list/${listId}`);
  };

  // Handle save list button click
  const handleSaveList = (e, listId) => {
    e.stopPropagation(); // Prevent navigation when clicking the save button
    
    setSavedLists(prev => ({
      ...prev,
      [listId]: !prev[listId]
    }));
    
    console.log("Saving list:", listId);
  };

  // Get color class based on theme
  const getColorClass = (colorTheme) => {
    switch (colorTheme) {
      case 'purple': return styles.purpleTheme;
      case 'blue': return styles.blueTheme;
      case 'pink': return styles.pinkTheme;
      case 'green': return styles.greenTheme;
      default: return styles.defaultTheme;
    }
  };

  // Filter lists based on search term and active filters
  const getFilteredLists = () => {
    return popularLists.filter(list => {
      // Skip if it doesn't match the search term
      if (searchTerm && !list.title.toLowerCase().includes(searchTerm.toLowerCase()) &&
          !list.description.toLowerCase().includes(searchTerm.toLowerCase())) {
        return false;
      }
      
      // Skip if the trials filter doesn't match
      if (activeTrialFilter === 'has-trials' && !list.hasTrialItems) {
        return false;
      }
      
      // In a real app, you would also filter by age and category
      // For this demo, we'll just check the above conditions
      return true;
    });
  };

  return (
    <div className={styles.container}>
      <header className={styles.header}>
        <h1 className={styles.title}>Community</h1>
      </header>

      <div className={styles.tabsContainer}>
        <button
          className={`${styles.tabButton} ${activeTab === 'popular' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('popular')}
        >
          Popular Lists
        </button>
        <button
          className={`${styles.tabButton} ${activeTab === 'following' ? styles.activeTab : ''}`}
          onClick={() => handleTabChange('following')}
        >
          Following
        </button>
      </div>

      <div className={styles.searchContainer}>
        <div className={styles.searchInputContainer}>
          <SearchIcon />
          <input
            type="text"
            className={styles.searchInput}
            placeholder="Search lists..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
      </div>

      <div className={styles.filtersContainer}>
        <div className={styles.filterScrollContainer}>
          <div className={styles.filterSection}>
            {ageRanges.map(filter => (
              <button
                key={filter.id}
                className={`${styles.filterButton} ${activeAgeFilter === filter.id ? styles.activeFilter : ''}`}
                onClick={() => handleAgeFilterChange(filter.id)}
              >
                {filter.label}
              </button>
            ))}
          </div>
          
          <div className={styles.filterSection}>
            {categories.map(filter => (
              <button
                key={filter.id}
                className={`${styles.filterButton} ${activeCategoryFilter === filter.id ? styles.activeFilter : ''}`}
                onClick={() => handleCategoryFilterChange(filter.id)}
              >
                {filter.label}
              </button>
            ))}
          </div>
          
          <div className={styles.filterSection}>
            {trialOptions.map(filter => (
              <button
                key={filter.id}
                className={`${styles.filterButton} ${activeTrialFilter === filter.id ? styles.activeFilter : ''}`}
                onClick={() => handleTrialFilterChange(filter.id)}
              >
                {filter.label}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className={styles.content}>
        {activeTab === 'popular' ? (
          <div className={styles.listsContainer}>
            {getFilteredLists().length > 0 ? (
              getFilteredLists().map(list => (
                <div 
                  key={list.id} 
                  className={`${styles.listCard} ${getColorClass(list.colorTheme)}`}
                  onClick={() => handleListClick(list.id)}
                >
                  <div className={styles.cardHeader}>
                    <div className={styles.creatorInfo}>
                      <Avatar initials={list.creator.initials} />
                      <span className={styles.creatorName}>{list.creator.name}</span>
                    </div>
                    <button 
                      className={styles.saveButton}
                      onClick={(e) => handleSaveList(e, list.id)}
                      aria-label={savedLists[list.id] ? "Unsave list" : "Save list"}
                    >
                      <BookmarkIcon filled={savedLists[list.id]} />
                    </button>
                  </div>
                  <h2 className={styles.listTitle}>{list.title}</h2>
                  <p className={styles.listDescription}>{list.description}</p>
                  <div className={styles.listStats}>
                    <span className={styles.savedCount}>
                      {list.savedCount} moms saved this list
                    </span>
                    
                    {list.hasTrialItems && (
                      <span className={styles.trialBadge}>
                        <TrialIcon />
                        {list.trialItemsCount} items with trials
                      </span>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.emptyState}>
                <p>No lists match your search or filters.</p>
                {searchTerm && (
                  <button 
                    className={styles.clearButton}
                    onClick={() => setSearchTerm('')}
                  >
                    Clear Search
                  </button>
                )}
                {activeTrialFilter !== 'all' && (
                  <button 
                    className={styles.clearButton}
                    onClick={() => setActiveTrialFilter('all')}
                  >
                    Clear Trial Filter
                  </button>
                )}
              </div>
            )}
          </div>
        ) : (
          <div className={styles.followingEmptyState}>
            <h3>You're not following anyone yet</h3>
            <p>
              Find and follow other parents to see their recommended lists here.
            </p>
            <button
              className={styles.exploreButton}
              onClick={() => handleTabChange('popular')}
            >
              Explore Popular Lists
            </button>
          </div>
        )}
      </div>

      {/* Bottom Navigation */}
      <nav className={styles.bottomNav}>
        <div 
          className={styles.navItem}
          onClick={() => history.push('/')}
        >
          <HomeIcon />
          <span>Home</span>
        </div>
        <div 
          className={`${styles.navItem} ${styles.active}`}
        >
          <CommunityIcon />
          <span>Community</span>
        </div>
        <div 
          className={styles.navItem}
          onClick={() => history.push('/profile')}
        >
          <ProfileIcon />
          <span>Profile</span>
        </div>
      </nav>
    </div>
  );
};

export default CommunityScreen;